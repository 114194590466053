<secured>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 i18n> Meu Colegiado</h5>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <!-- <div class="row">
                <div class="col-12">
                    Não há nenhum questionário disponível para informar parecer.
                </div>
            </div> -->
        <div class="row">
          <div class="col-md-12">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12" style='text-align: center'>
                  <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'"
                    [tituloSessao]="'Membros'" [cols]="7" [rows]="paginacaoConfig.totalElements"
                    [totalDePaginas]="paginacaoConfig.totalPages" (eventoBotao)="cliqueNoBotaoListener($event)">
                    <tr tableheader>
                      <th>Foto de perfil</th>
                      <th>Nome</th>
                      <th>Celula</th>
                      <th>Função</th>
                      <th>Nível Formativo</th>
                      <th>Data Entrada Célula</th>
                      <th>Data Saída Célula</th>
                    </tr>
                    <tr tablebody *ngFor="let formador of formadoresDaMissao">
                      <td>
                        <div class="widget-user-image">
                          <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                            [src]="formador.thumbnail == null ? 'assets/images/ppl.png' : formador.thumbnail"
                            alt="User Avatar">
                        </div>
                      </td>
                      <td>{{formador.pessoaNome}}</td>
                      <td>{{formador.celulaNome}}</td>
                      <td>{{formador.funcao}}</td>
                      <td>{{formador.nivelFormativoNome}}</td>
                      <td>{{formador.celulaDataEntrada | date : 'dd/MM/yyyy'}}</td>
                      <td>
                        <ng-container *ngIf="formador.celulaDataSaida == null">
                          Membro Atual
                        </ng-container>
                        <ng-container *ngIf="formador.celulaDataSaida != null">
                          {{formador.celulaDataSaida | date : 'dd/MM/yyyy'}}
                        </ng-container>
                      </td>
                    </tr>
                  </table-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</secured>