import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
export class AdicaoEquipeFormacaoComponent {
    constructor(imagemService, missionarioMissaoAtualService, historicoMissionarioMissaoService, elementoGrupoDominioService, apostoladoService, route, router, swtAlert2Service) {
        this.imagemService = imagemService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.historicoMissionarioMissaoService = historicoMissionarioMissaoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.apostoladoService = apostoladoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.eventEmitter = new EventEmitter();
        this.detalhesMissionariosDaCasaComunitaria = new Array();
        this.cadastroEquipeFormacaoFormulario = {
            pessoaId: null,
            casaComunitariaId: null,
            apostoladoId: null,
            dataEntrada: null
        };
    }
    ngOnInit() {
        this.initializer();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.casaComunitaria.id != undefined) {
                yield this.carregarMissionariosCasaComunitaria();
                yield this.carregarEquipeFormacaoCasaComunitaria();
                yield this.carregarTipoApostolado();
                yield this.carregarApostolados();
            }
        });
    }
    /*** MEMBROS ***/
    adicionarMembroEquipeFormacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.cadastroEquipeFormacaoFormulario.casaComunitariaId = this.casaComunitaria.id;
                if (this.cadastroEquipeFormacaoFormulario.dataEntrada != null && this.cadastroEquipeFormacaoFormulario.pessoaId != null) {
                    let response = yield this.missionarioMissaoAtualService.inserirApostoladoEquipeFormacao(this.cadastroEquipeFormacaoFormulario);
                    this.cadastroEquipeFormacaoFormulario = {
                        pessoaId: null,
                        casaComunitariaId: null,
                        apostoladoId: null,
                        dataEntrada: null
                    };
                    this.carregarEquipeFormacaoCasaComunitaria();
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    if (this.cadastroEquipeFormacaoFormulario.pessoaId == null) {
                        this.swtAlert2Service.errorAlert("O campo missionário é obrigatório.");
                    }
                    else if (this.cadastroEquipeFormacaoFormulario.dataEntrada == null) {
                        this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    // async atualizarMembro(membro) : Promise<any> {
    //     try
    //     {
    //         let response = await this.historicoMissionarioMissaoApostoladoService.update(membro.id,membro);
    //         this.swtAlert2Service.successAlert(response.message);
    //         //this.carregarEquipeFormacao();
    //     }
    //     catch(err)
    //     {
    //         this.swtAlert2Service.errorAlert(err.error.errors);
    //     }
    // }
    // async removerMembro(id:number) : Promise<any> {
    //     if(confirm("Deseja remover este membro da casa comunitária?"))
    //     {
    //         try
    //         {
    //             let response = await this.historicoMissionarioMissaoApostoladoService.delete(id);
    //             this.swtAlert2Service.successAlert(response.message);
    //             //this.carregarEquipeFormacao();                  
    //         }
    //         catch(err)
    //         {
    //             this.swtAlert2Service.errorAlert(err.error.errors);
    //         }
    //     }        
    // }
    carregarMissionariosCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.listarDetalhesDeMissionariosNaMissaoPorCasaComunitaria(this.casaComunitaria.id);
                this.detalhesMissionariosDaCasaComunitaria = response.sort((m1, m2) => m1.pessoaNome.localeCompare(m2.pessoaNome));
                this.totalDePaginas = response.quantity;
                for (let i = 0; i < this.detalhesMissionariosDaCasaComunitaria.length; i++) {
                    if (this.detalhesMissionariosDaCasaComunitaria[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.detalhesMissionariosDaCasaComunitaria[i].imagemId);
                        this.imagemService.createImage(blogImage, this.detalhesMissionariosDaCasaComunitaria[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarEquipeFormacaoCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.findEquipeFormacaoPorCasaComunitaria(this.casaComunitaria.id);
                this.listaEquipeFormacao = response.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                for (let i = 0; i < this.listaEquipeFormacao.length; i++) {
                    if (this.listaEquipeFormacao[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.listaEquipeFormacao[i].imagemId);
                        this.imagemService.createImage(blogImage, this.listaEquipeFormacao[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarTipoApostolado() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    grupoNome: "TIPO_APOSTOLADO"
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.listaTipoApostolado = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostolados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let servicoAutoridadeCasaComunitaria = this.listaTipoApostolado.filter(t => t.valor.localeCompare("SA") == 0);
                if (servicoAutoridadeCasaComunitaria.length > 0) {
                    let response = yield this.apostoladoService.porTipoId(servicoAutoridadeCasaComunitaria[0].id);
                    this.listaApostolados = response.entity.filter(ap => ap.id != 21 && ap.id != 23);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
