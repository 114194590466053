import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GenericService } from "src/app/core/services/generic.service";
import { Mensagem } from "src/app/model/mensagem.model";
import { environment } from "src/environments/environment";

@Injectable()
export class MensagemService extends GenericService<Mensagem> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("mensagem");
  }

  porPessoaId(id: number): Promise<any> {
    return this.http
      .get<any>(`${environment.moduloFormacao.buscarCaminhoFormativo}${id}`, {
        headers: this.getHeaders(),
      })
      .toPromise();
  }
  //Corrigir rota
  /*buscarMensagemRL(request: any) {
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}mensagemrl`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }
    //Corrigir rota
    buscarMensagemAF(request: any) {
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}mensagemaf`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }*/

  cadastrarMensagemRL(request: any) {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}mensagemrl`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  cadastrarMensagemAF(request: any) {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}mensagemaf`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  cadastrarMensagem(request: any) {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}mensagem`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizarMensagemRL(request: any) {
    return this.http
      .put<any>(
        `${environment.moduloFormacao.urlModuloFormacao}mensagemrl/${request.id}`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizarMensagemAF(request: any) {
    return this.http
      .put<any>(
        `${environment.moduloFormacao.urlModuloFormacao}mensagemaf`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizarMensagem(request: any) {
    return this.http
      .put<any>(
        `${environment.moduloFormacao.urlModuloFormacao}mensagem`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  porQuestionarioFormandoId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}mensagem/porquestionarioformando/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  mensagemRLPorOrigemId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}mensagemrl/origem/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }
}
