<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="bemvindotext">
                    <h1 class="mb-2">Seja Bem Vindo ao Sistema Missionário - Shalom!</h1>

                    <p>Aqui você poderá acessar os seus dados vocacionais.</p>
                    <p>Basta você acessar o menu a sua esquerda! 😁</p>
                </div>
            </div>
        </section>
    </div>
</secured>