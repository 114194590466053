import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/service/login.service';


@Component({
    selector: 'header-comp',
    templateUrl: './header.component.html'
})
export class HeaderComponent {

    constructor(
        public loginService: LoginService
    ) {}

    logout() : void {
        this.loginService.fazerLogout();
    }
}