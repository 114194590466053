import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
export class ParecerComponent {
    constructor(imagemService, pessoaService, casaComunitariaService, missionarioMissaoAtualService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, route, router, swtAlert2Service) {
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.casaComunitariaService = casaComunitariaService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCasaComunitaria = new Array();
        this.d2sCasaComunitaria = new Array();
        this.busca = {
            casaComunitariaId: null,
            formaVidaId: null,
            pageNumber: 0,
            quantityOfElements: 40
        };
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado();
            yield this.carregarCasaComunitaria();
            if (this.casaComunitaria.id != undefined) {
                yield this.carregarMembrosCasaComunitaria();
            }
        });
    }
    carregarMembrosCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.busca.casaComunitariaId = this.casaComunitaria.id;
                // this.busca.formaVidaId = 17; // Comunidade de Vida
                let response = yield this.pessoaService.buscarMembrosCasaComunitaria(this.busca);
                this.membrosCasaComunitaria = response.entity;
                this.totalDePaginas = response.quantity;
                for (let i = 0; i < this.membrosCasaComunitaria.length; i++) {
                    if (this.membrosCasaComunitaria[i].nivelFormativoNome == 'D2' || this.membrosCasaComunitaria[i].nivelFormativoNome == 'D2 Ext.') {
                        if (this.membrosCasaComunitaria[i].imagemId != null) {
                            let blogImage = yield this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId);
                            this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                        }
                        let questionarioFormando = yield this.carregarQuestionario(this.membrosCasaComunitaria[i].pessoaId);
                        if (questionarioFormando != null) {
                            this.membrosCasaComunitaria[i].statusQuestionario = questionarioFormando.status;
                            this.membrosCasaComunitaria[i].idQuestionario = questionarioFormando.id;
                            let parecerResponsavelAtual = yield this.carregarParecer(questionarioFormando.id);
                            if (parecerResponsavelAtual != null) {
                                this.membrosCasaComunitaria[i].statusParecer = parecerResponsavelAtual.status;
                                this.membrosCasaComunitaria[i].idParecer = parecerResponsavelAtual.id;
                            }
                            else {
                                this.membrosCasaComunitaria[i].statusParecer = 'Não iniciado';
                                this.membrosCasaComunitaria[i].idParecer = null;
                            }
                        }
                        if (questionarioFormando == null) {
                            this.membrosCasaComunitaria[i].statusQuestionario = 'Não iniciado';
                            this.membrosCasaComunitaria[i].idQuestionario = null;
                        }
                        this.d2sCasaComunitaria.push(this.membrosCasaComunitaria[i]);
                        console.log(this.d2sCasaComunitaria);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado();
                this.dadosUsuarioLogado = response;
                if (this.casaComunitariaId == undefined || this.casaComunitariaId == 0) {
                    this.casaComunitariaId = this.dadosUsuarioLogado.casaComunitariaId;
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.casaComunitariaId != undefined) {
                    let response = yield this.casaComunitariaService.find(this.casaComunitariaId);
                    this.casaComunitaria = response.entity;
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarQuestionario(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorPessoaId(pessoaId);
                if (response != undefined && response != null) {
                    return response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarParecer(idQuestionario) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.parecerResponsavelService.buscarPorQuestionarioFormandoId(idQuestionario);
                return response;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioD2(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.questionarioFormando.anoFormativoId = 2;
                this.questionarioFormando.questionarioId = 1;
                this.questionarioFormando.pessoaId = pessoaId;
                let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                if (response != undefined && response != null) {
                    this.questionarioFormando = response;
                    this.cadastrarVinculoParecerD2(this.questionarioFormando.id);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioD2Ext(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.questionarioFormando.anoFormativoId = 2;
                this.questionarioFormando.questionarioId = 2;
                this.questionarioFormando.pessoaId = pessoaId;
                let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                if (response != undefined && response != null) {
                    this.questionarioFormando = response;
                    this.cadastrarVinculoParecerD2(this.questionarioFormando.id);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoParecerD2(questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                this.parecerResponsavel.parecer_id = 1;
                this.parecerResponsavel.pessoaResponsavelId = this.dadosUsuarioLogado.pessoaId;
                let response = yield this.parecerResponsavelService.cadastrar(this.parecerResponsavel);
                if (response != undefined && response != null) {
                    this.parecerResponsavel = response;
                    this.router.navigate(['/secured/parecerd2-answer/' + this.parecerResponsavel.id]);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    consultarVinculoQuestionarioD2(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (formando.idQuestionario != null) {
                    let response = yield this.parecerResponsavelService.buscarPorQuestionarioFormandoId(formando.idQuestionario);
                    if (response != undefined && response != null) {
                        this.parecerResponsavel = response;
                        this.router.navigate(['/secured/parecerd2-answer/' + this.parecerResponsavel.id]);
                    }
                    else {
                        this.cadastrarVinculoParecerD2(formando.idQuestionario);
                    }
                }
                if (formando.idQuestionario == null) {
                    this.cadastrarVinculoQuestionarioD2(formando.pessoaId);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.carregarMembrosCasaComunitaria();
    }
}
