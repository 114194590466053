import { GenericService } from "src/app/core/services/generic.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CacheService } from "src/app/service/cache.service";

@Injectable()
export class ElementoGrupoDominioLocalService extends GenericService<ElementoGrupoDominio> {
  constructor(protected http: HttpClient, protected router: Router, private cacheService: CacheService) {
    super(http, router);
    this.setEntityType("elementogrupodominio");
  }

  async buscarElementosGrupoDominio(busca: any): Promise<any> {
    const cacheKey = 'buscarElementosGrupoDominioLocal-' + JSON.stringify(busca);

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP    
    return this.http
      .post<any>(this.apiURL + "/buscar", JSON.stringify(busca), {
        headers: this.getHeaders(),
      })
      .toPromise()
      .then(response => {
        this.cacheService.set(cacheKey, response);  // Armazena no cache
        return response;
      });
  }

  async buscarPorGrupoDominioNome(busca: any): Promise<any> {
    const cacheKey = 'buscarPorGrupoDominioLocalNome-' + JSON.stringify(busca);

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP    
    return this.http
      .post<any>(this.apiURL + "/pornomegrupo", JSON.stringify(busca), {
        headers: this.getHeaders(),
      })
      .toPromise()
      .then(response => {
        this.cacheService.set(cacheKey, response);  // Armazena no cache
        return response;
      });
  }

  async buscarPorGrupoDominioNomeModuloFormacao(nome: string): Promise<any> {
    const cacheKey = 'buscarPorGrupoDominioLocalNomeModuloFormacao-' + JSON.stringify(nome);

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP    
    let url = `${environment.moduloFormacao.urlModuloFormacao}secured/elementogrupodominio/pornomegrupo?nome=${nome}`;
    return this.http.get<any>(url, { headers: this.getHeaders() }).toPromise().then(response => {
      this.cacheService.set(cacheKey, response);  // Armazena no cache
      return response;
    });
  }
}
