import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from "@angular/core";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
export class GerenciamentoFaCelulaComponent {
    constructor(loggedInUserSharedDataService, usuarioService, pessoaService, celulaService, membroService, formadorComunitarioService, formadorAssistenteService, imagemService, celulaCriarLinkCadastroRequestBuilder, route, router, swtAlert2Service) {
        this.loggedInUserSharedDataService = loggedInUserSharedDataService;
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.imagemService = imagemService;
        this.celulaCriarLinkCadastroRequestBuilder = celulaCriarLinkCadastroRequestBuilder;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.unsubscribe$ = new Subject();
        this.formadoresComunitario = new Array();
        this.formadoresAssistente = new Array();
        this.membrosCelula = new Array();
        this.listaAniversariantesMes = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
        this.celulaCriarLinkCadastroRequest =
            this.celulaCriarLinkCadastroRequestBuilder.getInstance();
    }
    ngOnInit() {
        this.isLoaded = false;
        this.hasCelulaAssociada = true;
        this.loggedInUserSharedDataService.loggedInUser$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
            next: (usuario) => (this.usuario = usuario),
        });
        this.loggedInUserSharedDataService.loggedInPerson$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
            next: (pessoa) => (this.pessoa = pessoa),
        });
        this.initializer();
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // await this.carregarFormadorComunitario();
            yield this.carregarFormadorAssistente();
            if (this.formadorAssistente != undefined &&
                this.formadorAssistente.casaCelulaId != null) {
                yield this.carregarCelula();
                yield this.obterLinkCadastroCelula();
                this.buscaFCs = {
                    celulaId: this.celula.id,
                    pageNumber: 0,
                    quantityOfElements: 10,
                };
                this.carregarFormadoresComunitario();
                this.buscaFAs = {
                    celulaId: this.celula.id,
                    pageNumber: 0,
                    quantityOfElements: 10,
                };
                this.carregarFormadoresAssistente();
                this.buscaMembros = {
                    celulaId: this.celula.id,
                    pageNumber: 0,
                    quantityOfElements: 4,
                };
                yield this.carregarMembrosCelula();
                //await this.carregarAniversariantes();
                this.isLoaded = true;
            }
            else
                this.hasCelulaAssociada = false;
        });
    }
    carregarPessoa(usuarioId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(usuarioId);
                this.pessoa = response.entity;
                if (this.pessoa.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoa);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadorComunitario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorComunitarioService.buscarCelulaPorPessoaId(this.pessoa.id);
                this.formadorComunitario = response;
                if (this.formadorComunitario.pessoaImagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formadorComunitario.pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadorComunitario);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadorAssistente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorAssistenteService.buscarCelulaPorPessoaId(this.pessoa.id);
                this.formadorAssistente = response;
                if (this.formadorAssistente.pessoaImagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formadorAssistente.pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadorAssistente);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.find(this.formadorAssistente.casaCelulaId);
                this.celula = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadoresComunitario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorComunitarioService.buscarCelulaPorId(this.celula.id);
                this.formadoresComunitario = response;
                if (this.formadoresComunitario.length > 0) {
                    this.formadorComunitario = this.formadoresComunitario[0];
                }
                //this.formadoresComunitario = response.content;
                for (let i = 0; i < this.formadoresComunitario.length; i++) {
                    if (this.formadoresComunitario[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.formadoresComunitario[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.formadoresComunitario[i]);
                    }
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadoresAssistente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorAssistenteService.buscarCelulaPorId(this.celula.id);
                this.formadoresAssistente = response;
                for (let i = 0; i < this.formadoresAssistente.length; i++) {
                    if (this.formadoresAssistente[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.formadoresAssistente[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.formadoresAssistente[i]);
                    }
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarAniversariantes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.membroService.aniversariantesCelula(this.celula.id);
                this.listaAniversariantesMes = response.entity;
                for (let i = 0; i < this.listaAniversariantesMes.length; i++) {
                    if (this.listaAniversariantesMes[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.listaAniversariantesMes[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.listaAniversariantesMes[i]);
                    }
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    /*** MEMBROS ***/
    selecionarMembro(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.membroComunidade.pessoaId = pessoa.id;
            this.pessoaMembroSelecionada = pessoa;
            $("#listagem-pessoa-membro").modal("hide");
        });
    }
    salvarMembro() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.membroComunidade.casaCelulaId = this.celula.id;
                if (this.membroComunidade.dataEntrada != null &&
                    this.membroComunidade.pessoaId != null) {
                    let response = yield this.membroService.create(this.membroComunidade);
                    this.swtAlert2Service.successAlert(response.message);
                    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
                    this.limparSelect2("nmembro_pessoa");
                    this.carregarMembrosCelula();
                }
                else {
                    if (this.membroComunidade.pessoaId == null) {
                        this.swtAlert2Service.errorAlert("O campo pessoa é obrigatório.");
                    }
                    else if (this.membroComunidade.dataEntrada == null) {
                        this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarMembro(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.membroService.update(membro.id, membro);
                this.swtAlert2Service.successAlert(response.message);
                this.carregarMembrosCelula();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerMembro(membroId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (confirm("Deseja remover esse membro?")) {
                    let response = yield this.membroService.delete(membroId);
                    this.swtAlert2Service.successAlert(response.message);
                    this.carregarMembrosCelula();
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMembrosCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.membroService.listarDetalhesPorCelulaIdFromView(this.celula.id);
                this.membrosCelula = response;
                this.totalDePaginasMembros = 1;
                //this.membrosCelula = response.content;
                //this.totalDePaginasMembros = response.totalPages;
                if (this.membrosCelula.length > 0) {
                    this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.membrosCelula.length; i++) {
                    if (this.membrosCelula[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error);
            }
        });
    }
    cliqueNoBotaoMembrosListener(botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.carregarMembrosCelula();
    }
    limparSelect2(nome) {
        $("select[name='" + nome + "']").empty();
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    criarLinkCadastroCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.celulaCriarLinkCadastroRequest.dataValidadeInicioCadastro >
                    this.celulaCriarLinkCadastroRequest.dataValidadeFimCadastro) {
                    this.swtAlert2Service.warningAlert("A data inicial de validade do link deve ser menor ou igual a data final de validade do link");
                }
                else if (this.celulaCriarLinkCadastroRequest.dataValidadeInicioCadastro !=
                    null &&
                    this.celulaCriarLinkCadastroRequest.dataValidadeFimCadastro != null) {
                    this.celulaCriarLinkCadastroRequest.celulaId = this.celula.id;
                    let response = yield this.celulaService.criarLinkCadastroCelula(this.celulaCriarLinkCadastroRequest);
                    this.tokenLinkCelulaResponse = response;
                    this.celulaCriarLinkCadastroRequest =
                        this.celulaCriarLinkCadastroRequestBuilder.getInstance();
                }
                else {
                    this.swtAlert2Service.warningAlert("É preciso informar as datas de validade, de início e fim, antes de criar o link de registro na célula.");
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    obterLinkCadastroCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.obterLinkCadastroCelula(this.celula.id);
                this.tokenLinkCelulaResponse = response;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    copyToClipboard() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.tokenLinkCelulaResponse != null &&
                this.tokenLinkCelulaResponse.urlCadastro != undefined &&
                this.tokenLinkCelulaResponse.urlCadastro != null &&
                this.tokenLinkCelulaResponse.urlCadastro != "") {
                navigator.clipboard.writeText(this.tokenLinkCelulaResponse.urlCadastro);
                this.swtAlert2Service.successAlert("Link copiado");
            }
            else {
                this.swtAlert2Service.warningAlert("Não há link de cadastro para copia. Crie um link e tente novamente.");
            }
        });
    }
}
