<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Cadastro da Pessoa</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-pessoa-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill" href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab" aria-selected="true">Dados Pessoais</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="endereco-tab" data-toggle="pill" href="#endereco-tab-content" role="tab" aria-controls="endereco-tab" aria-selected="false">Endereço</a>
                        </li>
                        <!-- <li class="nav-item" *ngIf="pessoa.id != null && usuarioAtual?.autorizacoesNome.includes('ROLE_ADMIN')">
                            <a class="nav-link" id="dados-vocacionais-tab" data-toggle="pill" href="#dados-vocacionais-tab-content" role="tab" aria-controls="dados-vocacionais-tab" aria-selected="false">Dados Vocacionais</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" id="aptidoes-tab" data-toggle="pill" href="#aptidoes-tab-content" role="tab" aria-controls="aptidoes-tab" aria-selected="false">Aptidões</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="trabalho-tab" data-toggle="pill" href="#trabalho-tab-content" role="tab" aria-controls="trabalho-tab" aria-selected="false">Trabalho</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel" aria-labelledby="dados-pessoais-tab">
                            <form #dadosPessoaisForm="ngForm" (ngSubmit)="salvar(pessoa)" novalidate>
                                <div class="row">
                                    <div *ngIf="(pessoa.id != undefined)" class="col-sm-4 col-md-4 col-lg-4">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="text-center" style="margin: 1rem auto 2rem;">
                                                        <div class="widget-user-image">
                                                            <img class="profile-user-img img-fluid img-circle elevation-2" [src]="pessoa.thumbnail == null ? 'assets/images/ppl.png' : pessoa.thumbnail" alt="Imagem da pessoa" style="width: auto;">
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn btn-block btn-default" data-toggle="modal" data-target="#modal-pessoa-imagem">Alterar Imagem</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div [ngClass]="(pessoa.id != undefined) ? 'col-sm-8 col-md-8 col-lg-8' : 'col-sm-12 col-md-12 col-lg-12'">
                                        <div style="padding: 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_nome">Nome</label>
                                                                <input [(ngModel)]="pessoa.nome" name="npessoa_nome" type="text" class="form-control" id="ncev_nome" placeholder="Digite o seu nome" i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Sobrenome</label>
                                                                <input [(ngModel)]="pessoa.sobrenome" name="npessoa_sobrenome" type="text" class="form-control" id="npessoa_sobrenome" placeholder="Digite o seu sobrenome" i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_genero">Sexo</label>
                                                                <select class="form-control" id="npessoa_genero" [(ngModel)]="pessoa.genero" name="npessoa_genero" required>
                                                                <option disabled value="null" i18n>Selecione o sexo</option>
                                                                <option value="masculino" i18n>Masculino</option>
                                                                <option value="feminino" i18n>Feminino</option>
                                                            </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_dtnas">Data de Nascimento</label>
                                                                <input [(ngModel)]="pessoa.dataNascimento" name="npessoa_dtnas" type="date" class="form-control" id="npessoa_dtnas" i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_nacionalidade">Nacionalidade</label>
                                                                <select class="form-control" id="npessoa_nacionalidade" [(ngModel)]="pessoa.nacionalidadeId" name="npessoa_nacionalidade" required>
                                                                <option disabled value="null" i18n>Selecione a sua nacionalidade</option>
                                                                <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                            </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_usuario">Usuário</label>
                                                                <select2-component [name]="'usuario'" [url]="url" [multiplo]="false" (elementoSelecionado)="setarElementoSelecionado($event)" [required]="true">
                                                                    <option id="s2options" value="">Pesquisar usuários</option>
                                                                </select2-component>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_estado_civil">Estado Civil</label>
                                                                <select class="form-control" id="npessoa_estado_civil" [(ngModel)]="pessoa.estadoCivilId" name="npessoa_estado_civil" required>
                                                                    <option disabled value="null" i18n>Selecione um estado civil</option>
                                                                    <option *ngFor="let estadoCivil of estadosCivil" [value]="estadoCivil.id">{{estadoCivil.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_missao_origem">Missão de Origem</label>
                                                                <select class="form-control" id="npessoa_missao_origem" [(ngModel)]="pessoa.vinculoMissao.missaoOrigemId" name="npessoa_missao_origem" disabled>
                                                                    <option disabled value="null" i18n>Selecione uma missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo" [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_missao_atual">Missão Atual</label>
                                                                <select class="form-control" id="npessoa_missao_atual" [(ngModel)]="pessoa.vinculoMissao.missaoAtualId" name="npessoa_missao_atual" disabled>
                                                                    <option disabled value="null" i18n>Selecione uma missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo" [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_difusao_origem">Difusão de Origem</label>
                                                                <select class="form-control" id="npessoa_difusao_origem" [(ngModel)]="pessoa.vinculoDifusao.difusaoOrigemId" name="npessoa_difusao_origem" disabled>
                                                                    <option value="null" i18n>Selecione uma difusão</option>
                                                                    <option *ngFor="let difusao of difusoesVinculo" [value]="difusao.id">{{difusao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_difusao_atual">Difusão Atual</label>
                                                                <select class="form-control" id="npessoa_difusao_atual" [(ngModel)]="pessoa.vinculoDifusao.difusaoAtualId" name="npessoa_difusao_atual" disabled>
                                                                    <option value="null" i18n>Selecione uma difusão</option>
                                                                    <option *ngFor="let difusao of difusoesVinculo" [value]="difusao.id">{{difusao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_difusao_atual">Status</label>
                                                                <select class="form-control" id="npessoa_pessoa_status" [(ngModel)]="pessoa.statusId" name="npessoa_pessoa_status" [disabled]="pessoa.statusId != null">
                                                                    <option value="null" i18n>Selecione um status</option>
                                                                    <option *ngFor="let status of pessoaStatus" [value]="status.id">{{status.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="submit" class="btn" [ngClass]="pessoa.id != undefined ? 'btn-primary' : 'btn-success'" [disabled]="!dadosPessoaisForm.valid" i18n>{{(pessoa.id != undefined) ? 'Editar' : 'Salvar'}}</button>&nbsp;
                                                    <button type="button" class="btn btn-default" (click)="activateTab('endereco-tab-content')" *ngIf="pessoa.id != undefined" [disabled]="!dadosPessoaisForm.valid">Proximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="(pessoa.id != null && pessoa.formaVidaValor == 'CV') || usuarioAtual?.autorizacoesNome.includes('ROLE_ADMIN')">
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <h5>Telefone</h5>
                                                        </div>
                                                        <div class="col-sm-10 col-md-10 col-lg-10 mb-3 mt-3">
                                                            <input name="telefone" type="tel" class="form-control" id="pessoa-telefone" />
                                                        </div>
                                                        <div class="col-sm-2 col-md-2 col-lg-2 mt-3 mb-3">
                                                            <button type="button" class="btn btn-primary btn-md" (click)="adicionarTelefone()"><i class="fas fa-plus-square"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <div class="row" *ngFor="let tel of pessoa.telefones">
                                                        <div class="col-sm-10 col-md-10 col-lg-10 mb-3 mt-3">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="fas fa-phone-alt"></i></span>
                                                                </div>
                                                                <input type="text" class="form-control" placeholder="Telefone" value="{{tel}}" disabled>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-2 col-md-2 col-lg-2 mt-3 mb-3">
                                                            <button type="button" class="btn btn-danger btn-md" (click)="removerTelefone(tel)"><i class="fas fa-trash"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-8 col-md-8 col-lg-8">
                                        <div style="padding: 1rem 1rem 1rem 1rem;">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h5>Documentos</h5>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="form-group">
                                                                <label>Tipo de documento</label>
                                                                <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="documento.elementoGrupoDominioTipoId">
                                                                    <option [value]="null" disabled>Selecione um tipo</option>
                                                                    <option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">{{tipo.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4" *ngIf="valorTipoDocumento == 'NL'">
                                                            <div class="form-group">
                                                                <label>Tipo</label>
                                                                <input type="text" [ngModelOptions]="{standalone: true}" class="form-control" [(ngModel)]="documento.tipoNaoListado" />
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="form-group">
                                                                <label>Valor</label>
                                                                <input type="text" [ngModelOptions]="{standalone: true}" class="form-control" [(ngModel)]="documento.valor" />
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="form-group">
                                                                <label>Data de Validade</label>
                                                                <input type="date" [ngModelOptions]="{standalone: true}" class="form-control" [(ngModel)]="documento.dataValidade" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12 text-right">
                                                            <button type="button" class="btn btn-primary" (click)="salvarDocumento(documento)">Salvar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Tipo do Documento</th>
                                                                <th>Numero</th>
                                                                <th>Data Validade</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let documento of documentos">
                                                                <td>{{documento.elementoGrupoDominioNome != 'Tipo não listado' ? documento.elementoGrupoDominioNome : documento.tipoNaoListado}}</td>
                                                                <td>{{documento.valor}}</td>
                                                                <td>{{documento.dataValidade}}</td>
                                                                <td><button type="button" class="btn btn-danger btn-md" (click)="removerDocumento(documento.id)"><i class="fas fa-trash"></i></button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="endereco-tab-content" role="tabpanel" aria-labelledby="endereco-tab">
                            <form *ngIf="pessoa.id != undefined" #enderecoForm="ngForm" (ngSubmit)="salvarEndereco(endereco)" novalidate>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div style="padding: 1rem 1rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="ncev_pais">País</label>
                                                                <select class="form-control" id="ncev_pais" [(ngModel)]="endereco.paisId" (change)="carregarEstados(endereco.paisId)" name="ncev_pais" required>
                                                                    <option disabled [value]="null" i18n>Selecione um País</option>
                                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="ncev_estado">Estado</label>
                                                                <select class="form-control" id="ncev_estado" [(ngModel)]="endereco.estadoId" (change)="carregarCidades(endereco.estadoId)" name="ncev_estado" required>
                                                                    <option disabled [value]="null" i18n>Selecione um Estado</option>
                                                                    <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="ncev_cidade">Cidade</label>
                                                                <select class="form-control" id="ncev_cidade" [(ngModel)]="endereco.cidadeId" name="ncev_cidade" required>
                                                                    <option disabled [value]="null" i18n>Selecione uma Cidade</option>
                                                                    <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 col-lg-8">
                                                            <div class="form-group">
                                                                <label i18n for="ncev_endereco">Endereço</label>
                                                                <input [(ngModel)]="endereco.endereco" name="ncev_endereco" type="text" class="form-control" id="ncev_endereco" placeholder="Digite o endereço" i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="ncev_complemento">Complemento</label>
                                                                <input [(ngModel)]="endereco.complemento" name="ncev_complemento" type="text" class="form-control" id="ncev_complemento" placeholder="Digite o complemento" i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="ncev_bairro">Bairro</label>
                                                                <input [(ngModel)]="endereco.bairro" name="ncev_bairro" type="text" class="form-control" id="ncev_bairro" placeholder="Digite o bairro" i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-2 col-lg-2">
                                                            <div class="form-group">
                                                                <label i18n for="ncev_cep">CEP</label>
                                                                <input [(ngModel)]="endereco.cep" name="ncev_cep" type="text" class="form-control" id="ncev_cep" placeholder="Digite o cep" i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="button" class="btn btn-default float-left" style="margin-left: 0.5rem;" (click)="activateTab('dados-pessoais-tab-content')" i18n>Voltar</button>
                                                    <button type="submit" class="btn btn-primary" [disabled]="enderecoForm.invalid" i18n>{{(endereco.id != undefined) ? 'Editar' : 'Salvar'}}</button>&nbsp;
                                                    <button type="button" class="btn btn-default" [disabled]="enderecoForm.invalid || endereco.id == undefined" (click)="activateTab('dados-vocacionais-tab-content')" i18n>Proximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- <div class="tab-pane fade" id="dados-vocacionais-tab-content" role="tabpanel" aria-labelledby="dados-vocacionais-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Forma de vida</label>
                                        <select class="form-control" [(ngModel)]="pessoa.formaVidaId" (change)="setarFormaVidaSelecionada()">
                                            <option disabled [value]="null">Selecione uma forma de vida</option>
                                            <option *ngFor="let formaVida of formasVida" [value]="formaVida.id">{{formaVida.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="formaVidaSelecionada != null && formaVidaSelecionada.valor == 'O'">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <h5>Obra</h5>
                                                    <div class="form-group">
                                                        <label>Ano do Seminário de Vida no Espirito Santo</label>
                                                        <input type="number" class="form-control" [(ngModel)]="obra.anoSVES" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-right">
                                            <button type="button" class="btn btn-default float-left" style="margin-left: 0.5rem;" (click)="activateTab('endereco-tab-content')" i18n>Voltar</button>
                                            <button class="btn btn-primary" (click)="salvarObra(obra)">{{(pessoa.obraId != null) ? 'Editar' : 'Salvar' }}</button>&nbsp;
                                            <button type="button" class="btn btn-default" [disabled]="obra.id == undefined" (click)="activateTab('aptidoes-tab-content')" i18n>Proximo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="formaVidaSelecionada != null && formaVidaSelecionada.valor == 'CA'">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Ano de Ingresso</label>
                                                        <input type="number" class="form-control" [(ngModel)]="membroComunidadeAlianca.anoIngresso" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Ano da Promessa</label>
                                                        <input type="number" class="form-control" [(ngModel)]="membroComunidadeAlianca.anoPromessa" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Nível de Formação</label>
                                                        <select class="form-control" [(ngModel)]="membroComunidadeAlianca.nivelFormacaoId">
                                                            <option disabled [value]="null">Selecione um nivel de formação</option>
                                                            <option *ngFor="let nivelFormacao of niveisFormacao" [value]="nivelFormacao.id">{{nivelFormacao.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Estado de Vida</label>
                                                        <select class="form-control" [(ngModel)]="membroComunidadeAlianca.estadoVidaId">
                                                            <option disabled [value]="null">Selecione um estado de vida</option>
                                                            <option *ngFor="let estadoVida of estadosVida" [value]="estadoVida.id">{{estadoVida.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                                            <div class="form-group">
                                                                <label i18n for="np_o">Origem</label>
                                                                <select class="form-control" [(ngModel)]="tipoOrigem" (change)="carregarOrigem()">
                                                                    <option [value]="undefined">Selecione uma origem</option>
                                                                    <option value="M">Missão</option>
                                                                    <option value="D">Difusão</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                                            <div class="form-group">
                                                                <label i18n for="np_r">Regional</label>
                                                                <select class="form-control" id="np_r" [(ngModel)]="regionalSelecionadoId" (change)="carregarOrigem()" name="np_r">
                                                                    <option [value]="undefined" i18n>Selecione um Regional</option>
                                                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-3 col-lg-3" *ngIf="tipoOrigem == 'M'">
                                                            <div class="form-group">
                                                                <label>Missão de Origem</label>
                                                                <select class="form-control" [(ngModel)]="membroComunidadeAlianca.missaoOrigemId">
                                                                    <option disabled [value]="null">Selecione uma missão</option>
                                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-3 col-lg-3" *ngIf="tipoOrigem == 'D'">
                                                            <div class="form-group">
                                                                <label>Difusão de Origem</label>
                                                                <select class="form-control" [(ngModel)]="membroComunidadeAlianca.difusaoOrigemId">
                                                                    <option disabled [value]="null">Selecione uma difusão</option>
                                                                    <option *ngFor="let difusao of difusoes" [value]="difusao.id">{{difusao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-right">
                                            <button type="button" class="btn btn-default float-left" style="margin-left: 0.5rem;" (click)="activateTab('endereco-tab-content')" i18n>Voltar</button>
                                            <button class="btn btn-primary" (click)="salvarCAAsync(membroComunidadeAlianca)">{{(pessoa.membroId != null) ? 'Editar' : 'Salvar' }}</button>&nbsp;
                                            <button type="button" class="btn btn-default" [disabled]="membroComunidadeAlianca.id == undefined" (click)="activateTab('aptidoes-tab-content')" i18n>Proximo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="formaVidaSelecionada != null && formaVidaSelecionada.valor == 'CV'">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Ano de Ingresso</label>
                                                        <input type="number" class="form-control" [(ngModel)]="membroComunidadeVida.anoIngresso" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Ano da Promessa</label>
                                                        <input type="number" class="form-control" [(ngModel)]="membroComunidadeVida.anoPromessa" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Nível de Formação</label>
                                                        <select class="form-control" [(ngModel)]="membroComunidadeVida.nivelFormacaoId">
                                                            <option disabled [value]="null">Selecione um nivel de formação</option>
                                                            <option *ngFor="let nivelFormacao of niveisFormacao" [value]="nivelFormacao.id">{{nivelFormacao.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Estado de Vida</label>
                                                        <select class="form-control" [(ngModel)]="membroComunidadeVida.estadoVidaId">
                                                            <option disabled [value]="null">Selecione um estado de vida</option>
                                                            <option *ngFor="let estadoVida of estadosVida" [value]="estadoVida.id">{{estadoVida.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                                            <div class="form-group">
                                                                <label i18n for="np_o">Origem</label>
                                                                <select class="form-control" [(ngModel)]="tipoOrigem" (change)="carregarOrigem()">
                                                                    <option [value]="undefined">Selecione uma origem</option>
                                                                    <option value="M">Missão</option>
                                                                    <option value="D">Difusão</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                                            <div class="form-group">
                                                                <label i18n for="np_r">Regional</label>
                                                                <select class="form-control" id="np_r" [(ngModel)]="regionalSelecionadoId" (change)="carregarOrigem()" name="np_r">
                                                                    <option [value]="undefined" i18n>Selecione um Regional</option>
                                                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-3 col-lg-3" *ngIf="tipoOrigem == 'M'">
                                                            <div class="form-group">
                                                                <label>Missão de Origem</label>
                                                                <select class="form-control" [(ngModel)]="membroComunidadeVida.missaoOrigemId">
                                                                    <option disabled [value]="null">Selecione uma missão</option>
                                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-3 col-lg-3" *ngIf="tipoOrigem == 'D'">
                                                            <div class="form-group">
                                                                <label>Difusão de Origem</label>
                                                                <select class="form-control" [(ngModel)]="membroComunidadeVida.difusaoOrigemId">
                                                                    <option disabled [value]="null">Selecione uma difusão</option>
                                                                    <option *ngFor="let difusao of difusoes" [value]="difusao.id">{{difusao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-right">
                                            <button type="button" class="btn btn-default float-left" style="margin-left: 0.5rem;" (click)="activateTab('endereco-tab-content')" i18n>Voltar</button>
                                            <button class="btn btn-primary" (click)="salvarCVAsync(membroComunidadeVida)">{{(pessoa.membroId != null) ? 'Editar' : 'Salvar' }}</button>&nbsp;
                                            <button type="button" class="btn btn-default" [disabled]="membroComunidadeVida.id == undefined" (click)="activateTab('aptidoes-tab-content')" i18n>Proximo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="tab-pane fade" id="aptidoes-tab-content" role="tabpanel" aria-labelledby="aptidoes-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div style="padding: 1rem 1rem 1rem 1rem;">
                                        <div class="card card-default">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                                <div class="card card-default">
                                                                    <div class="card-header">
                                                                        <h5>Línguas</h5>
                                                                    </div>
                                                                    <div class="card-body">
                                                                        <div class="form-group">
                                                                            <select class="select2" name="linguas-select" multiple="multiple" data-placeholder="Selecione a(s) língua(s)" style="width: 100%;">
                                                                                <option *ngFor="let lingua of linguas" [value]="lingua.id">{{lingua.nome}}</option>                                                                                   
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer text-right">
                                                                        <button class="btn btn-primary" (click)="salvarAptidao(aptidao)">{{(aptidao.id != null) ? 'Editar' : 'Salvar' }}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                                <div class="card card-default">
                                                                    <div class="card-header">
                                                                        <h5>Habilidades</h5>
                                                                    </div>
                                                                    <div class="card-body">
                                                                        <div class="form-group">
                                                                            <select class="select2" name="habilidades-select" multiple="multiple" data-placeholder="Selecione a(s) habilidade(s)" style="width: 100%;">
                                                                                <option *ngFor="let habilidade of habilidades" [value]="habilidade.id">{{habilidade.nome}}</option>                                                                                   
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer text-right">
                                                                        <button class="btn btn-primary" (click)="salvarAptidao(aptidao)">{{(aptidao.id != null) ? 'Editar' : 'Salvar' }}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-8 col-lg-8">
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                                <div class="card card-default">
                                                                    <div class="card-header">
                                                                        <h5>Escolaridade</h5>
                                                                    </div>
                                                                    <div class="card-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-4 col-md-4 col-lg-4">
                                                                                <div class="form-group">
                                                                                    <label>Nível de escolaridade</label>
                                                                                    <select class="form-control" [(ngModel)]="escolaridade.nivelEscolaridadeId" (change)="setarNivelEscolaridadeSelecionado()">
                                                                                        <option disabled [value]="null">Selecione um nível de escolaridade</option>
                                                                                        <option *ngFor="let nivel of niveisEscolaridade" [value]="nivel.id">{{nivel.nome}}</option>                                                                                        
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-4 col-md-4 col-lg-4" *ngIf="nivelEscolaridadeSelecionado != undefined && nivelEscolaridadeSelecionado.valorAlternativo == 'NG'">
                                                                                <div class="form-group">
                                                                                    <label>Nome do curso</label>
                                                                                    <input type="text" class="form-control" [(ngModel)]="escolaridade.nomeGraduacao" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-4 col-md-4 col-lg-4" *ngIf="nivelEscolaridadeSelecionado != undefined && nivelEscolaridadeSelecionado.valor == 'C'">
                                                                                <div class="form-group">
                                                                                    <label>Ano em que se formou</label>
                                                                                    <input type="number" maxlength="4" class="form-control" [(ngModel)]="escolaridade.anoFormacao" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer text-right">
                                                                        <button class="btn btn-primary" (click)="salvarEscolaridade(escolaridade)">Salvar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Nível de Escolaridade</th>
                                                                            <th>Nome do Curso</th>
                                                                            <th>Ano de Formação</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let escolaridade of escolaridades">
                                                                            <td>{{escolaridade.nivelEscolaridadeNome}}</td>
                                                                            <td>{{escolaridade.nomeGraduacao}}</td>
                                                                            <td>{{escolaridade.anoFormacao}}</td>
                                                                            <td><button class="btn btn-sm btn-danger" (click)="removerEscolaridade(escolaridade.id)"><i class="fas fa-trash"></i></button></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-right">
                                                <button type="button" class="btn btn-default float-left" style="margin-left: 0.5rem;" (click)="activateTab('dados-vocacionais-tab-content')" i18n>Voltar</button>
                                                <button type="submit" class="btn btn-primary" i18n>{{(endereco.id != undefined) ? 'Editar' : 'Salvar'}}</button>&nbsp;
                                                <button type="button" class="btn btn-default" [disabled]="aptidao.id==undefined" (click)="activateTab('trabalho-tab-content')" i18n>Proximo</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="trabalho-tab-content" role="tabpanel" aria-labelledby="trabalho-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div style="padding: 1rem 1rem 1rem 1rem;">
                                        <form #trabalhoForm="ngForm" (ngSubmit)="salvarTrabalho(trabalho)" novalidate>
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Empresa</label>
                                                                <input type="text" class="form-control" name="empresa" [(ngModel)]="trabalho.empresa" required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Trabalho iniciado em</label>
                                                                <input type="date" class="form-control" name="iniciadoEm" [(ngModel)]="trabalho.iniciadoEm" required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Trabalho finalizado em</label>
                                                                <input type="date" class="form-control" name="finalizadoEm" [(ngModel)]="trabalho.finalizadoEm">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="button" class="btn btn-default float-left" style="margin-left: 0.5rem;" (click)="activateTab('aptidoes-tab-content')" i18n>Voltar</button>
                                                    <button type="submit" class="btn btn-primary" [disabled]="trabalhoForm.invalid">{{(trabalho.id != null) ? 'Editar' : 'Salvar' }}</button>&nbsp;
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Empresa</th>
                                                <th>Trabalho Iniciado Em</th>
                                                <th>Trabalho Finalizado Em</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let trabalho of trabalhos">
                                                <td>{{trabalho.empresa}}</td>
                                                <td>{{trabalho.iniciadoEm | date: 'dd/MM/yyyy'}}</td>
                                                <td>{{trabalho.finalizadoEm | date: 'dd/MM/yyyy'}}</td>
                                                <td class="text-right"><button class="btn btn-sm btn-danger" (click)="removerTrabalho(trabalho.id)"><i class="fas fa-trash"></i></button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="modal fade" id="modal-pessoa-imagem" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Selecione a imagem</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4">
                            <div class="card text-center">
                                <div class="card-title">
                                    Preview
                                </div>
                                <div class="card-body">
                                    <img class="img-circle elevation-2" [src]="(croppedImage=='' ) ? 'assets/images/ppl.png' : croppedImage" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-8">
                            <div class="card text-center">
                                <div class="card-title">
                                    Recortar Imagem
                                </div>
                                <div class="card-body" style="min-height: 242px;">
                                    <image-cropper style="max-height: 300px;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="200" format="png" (imageCropped)="imageCropped($event)
                                                                                   " (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 5%;">
                        <div class="text-center col-md-12">
                            <div class="box">
                                <input style="display:none;" (change)="fileChangeEvent($event)" type="file" name="file-3[]" id="file-3" class="inputfile inputfile-3">
                                <label for="file-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6
                                                                                    0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                </svg> 
                                <span>Clique para selecione a imagem</span></label>
                            </div>
                        </div>
                        <label class="custom-file" id="customFile">
                            <input type="file" (change)="fileChangeEvent($event)" class="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp">
                            <span class="custom-file-control form-control-file"></span>
                        </label>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" (click)="salvarImagem()" class="btn btn-primary">Salvar Imagem</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</secured>