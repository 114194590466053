import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
export class LoggedInUserSharedDataService {
    constructor() {
        this.loggedInUser$ = new BehaviorSubject(null);
        this.loggedInPerson$ = new BehaviorSubject(null);
    }
    updateLoggedInUser(user) {
        this.loggedInUser$.next(user);
    }
    updateLoggedInPerson(person) {
        this.loggedInPerson$.next(person);
    }
}
LoggedInUserSharedDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInUserSharedDataService_Factory() { return new LoggedInUserSharedDataService(); }, token: LoggedInUserSharedDataService, providedIn: "root" });
