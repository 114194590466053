import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Apostolado } from 'src/app/model/apostolado.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { DadosDetalhamentoMissionarioMissaoAtual } from 'src/app/model/dadosdetalhamentomissionariomissaoatual.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Usuario } from 'src/app/model/usuario.model';
import { ImagemService } from '../../../../service/imagem.service';
import { MissionarioMissaoAtualService } from '../../../dadosvocacionais/service/missionariomissaoatual.service';
import { HistoricoMissionarioMissaoService } from '../../../dadosvocacionais/service/historicomissionariomissao.service';
import { ElementoGrupoDominioService } from 'src/app/secured/service/elementogrupodominio.service';
import { ApostoladoService } from '../../../dadosvocacionais/service/apostolado.service';


@Component({
    selector: 'adicao-equipe-formacao-component',
    templateUrl: './adicao-equipe-formacao.component.html'
})
export class AdicaoEquipeFormacaoComponent implements OnInit {

    @Input() 
    public usuarioLogado: Usuario;
    @Input()
    public casaComunitaria: CasaComunitaria;

    @Output() 
    public eventEmitter = new EventEmitter();

    public busca: any;
    public totalDePaginas: number;

    public cadastroEquipeFormacaoFormulario: any;
    public detalhesMissionariosDaCasaComunitaria: Array<DadosDetalhamentoMissionarioMissaoAtual>;
    public listaTipoApostolado: Array<ElementoGrupoDominio>;
    public listaApostolados: Array<Apostolado>;
    public listaEquipeFormacao: Array<any>;

    constructor(
        public imagemService: ImagemService,     
        public missionarioMissaoAtualService: MissionarioMissaoAtualService,
        public historicoMissionarioMissaoService: HistoricoMissionarioMissaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public apostoladoService: ApostoladoService,        
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ){
        this.detalhesMissionariosDaCasaComunitaria = new Array<any>();
        this.cadastroEquipeFormacaoFormulario = {
            pessoaId: null,
            casaComunitariaId: null,
            apostoladoId: null,
            dataEntrada: null
        };
    }

    ngOnInit() {
        this.initializer();
    }

    async initializer() : Promise<any> {
        if(this.casaComunitaria.id != undefined)
        {   
            await this.carregarMissionariosCasaComunitaria();
            await this.carregarEquipeFormacaoCasaComunitaria();
            await this.carregarTipoApostolado();
            await this.carregarApostolados();
        }        
    }

    /*** MEMBROS ***/

    async adicionarMembroEquipeFormacao() : Promise<any> {
        try
        {
            this.cadastroEquipeFormacaoFormulario.casaComunitariaId = this.casaComunitaria.id;

            if(this.cadastroEquipeFormacaoFormulario.dataEntrada != null && this.cadastroEquipeFormacaoFormulario.pessoaId != null)
            {
                let response = await this.missionarioMissaoAtualService.inserirApostoladoEquipeFormacao(this.cadastroEquipeFormacaoFormulario);
                this.cadastroEquipeFormacaoFormulario = {
                    pessoaId: null,
                    casaComunitariaId: null,
                    apostoladoId: null,
                    dataEntrada: null
                };
                this.carregarEquipeFormacaoCasaComunitaria();
                this.swtAlert2Service.successAlert(response.message);
            }
            else
            {
                if(this.cadastroEquipeFormacaoFormulario.pessoaId == null)
                {
                    this.swtAlert2Service.errorAlert("O campo missionário é obrigatório.");
                }
                else if(this.cadastroEquipeFormacaoFormulario.dataEntrada == null)
                {
                    this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                }
                
            }       
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    // async atualizarMembro(membro) : Promise<any> {
    //     try
    //     {

    //         let response = await this.historicoMissionarioMissaoApostoladoService.update(membro.id,membro);
    //         this.swtAlert2Service.successAlert(response.message);
    //         //this.carregarEquipeFormacao();
                  
    //     }
    //     catch(err)
    //     {
    //         this.swtAlert2Service.errorAlert(err.error.errors);
    //     }
    // }

    // async removerMembro(id:number) : Promise<any> {
    //     if(confirm("Deseja remover este membro da casa comunitária?"))
    //     {
    //         try
    //         {
    //             let response = await this.historicoMissionarioMissaoApostoladoService.delete(id);
    //             this.swtAlert2Service.successAlert(response.message);
    //             //this.carregarEquipeFormacao();                  
    //         }
    //         catch(err)
    //         {
    //             this.swtAlert2Service.errorAlert(err.error.errors);
    //         }
    //     }        
    // }

    async carregarMissionariosCasaComunitaria() : Promise<any> {

        try
        {
            let response = await this.missionarioMissaoAtualService.listarDetalhesDeMissionariosNaMissaoPorCasaComunitaria(this.casaComunitaria.id);
            this.detalhesMissionariosDaCasaComunitaria = response.sort((m1,m2) => m1.pessoaNome.localeCompare(m2.pessoaNome));

            this.totalDePaginas = response.quantity;

            for(let i = 0; i < this.detalhesMissionariosDaCasaComunitaria.length ; i++)
            {

                if(this.detalhesMissionariosDaCasaComunitaria[i].imagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.detalhesMissionariosDaCasaComunitaria[i].imagemId);

                    this.imagemService.createImage(blogImage, this.detalhesMissionariosDaCasaComunitaria[i]);
                }  
                
            } 

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarEquipeFormacaoCasaComunitaria() : Promise<any> {

        try
        {
            let response = await this.missionarioMissaoAtualService.findEquipeFormacaoPorCasaComunitaria(this.casaComunitaria.id);
            this.listaEquipeFormacao = response.sort((a,b) => a.pessoaNome.localeCompare(b.pessoaNome));

            for(let i = 0; i < this.listaEquipeFormacao.length ; i++)
            {

                if(this.listaEquipeFormacao[i].imagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.listaEquipeFormacao[i].imagemId);

                    this.imagemService.createImage(blogImage, this.listaEquipeFormacao[i]);
                }  
                
            } 

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarTipoApostolado() : Promise<any> {

        try 
        {
            let request = {
                grupoNome : "TIPO_APOSTOLADO"
            }

            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.listaTipoApostolado = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarApostolados() : Promise<any> {

        try 
        {

            let servicoAutoridadeCasaComunitaria = this.listaTipoApostolado.filter(t => t.valor.localeCompare("SA") == 0);
            if(servicoAutoridadeCasaComunitaria.length > 0)
            {
                let response = await this.apostoladoService.porTipoId(servicoAutoridadeCasaComunitaria[0].id);
                this.listaApostolados = response.entity.filter(ap => ap.id != 21 && ap.id != 23);
            }
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

}