<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Questionários</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-secondary">
                            <div class="card-header">
                                <h3 class="card-title">Questionários Disponíveis</h3>
                            </div>
                            <div class="card-body">
                                <!-- <div class="row">
                                    <div class="col-12">
                                        Não há nenhum questionário disponível para preenchimento.
                                    </div>
                                </div> -->
                                <div *ngIf="(formaVida != 'CA') || (questionarioConfiguracao.status == 'DIVULGADO')"
                                    class="row">
                                    <div class="col-12">
                                        Não há nenhum questionário disponível para preenchimento.
                                    </div>
                                </div>
                                <div *ngIf="(formaVida == 'CA') && (questionarioConfiguracao.status == 'EM_DISCERNIMENTO')"
                                    class="row">
                                    <div class="col-3">
                                        &nbsp;
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>1º Questionario Pastoreio - CAL</label>
                                        </div>
                                    </div>
                                    <div *ngIf="!temQuestionarioVinculado" class="col-3">
                                        <button class="btn btn-primary" [disabled]="(!verificouDados)"
                                            (click)="cadastrarVinculoQuestionarioCAL()" title="Questionario CAL"
                                            alt="Questionario CAL">Responder</button>
                                    </div>
                                    <div *ngIf="temQuestionarioVinculado" class="col-3">
                                        <button *ngIf="questionarioFormando?.status != 'ENVIADO'"
                                            class="btn btn-primary"
                                            routerLink="/secured/questionariocal-answer/{{questionarioFormando.id}}"
                                            title="Questionario CAL" alt="Questionario CAL">Responder</button>
                                        <button *ngIf="questionarioFormando?.status == 'ENVIADO'"
                                            class="btn btn-primary" disabled title="Questionario CAL"
                                            alt="Questionario CAL">Enviado</button>
                                    </div>
                                </div>
                                <!--div *ngIf="(nivelFormacao == 'D2')" class="row">
                                    <div class="col-3">
                                        &nbsp;
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>1º Questionario do D2 - CV</label>
                                        </div>
                                    </div>
                                    <div *ngIf="!temQuestionarioVinculado" class="col-3">                                        
                                        <button class="btn btn-primary" [disabled]="(!verificouDados)" (click)="cadastrarVinculoQuestionarioD2()" title="Questionario D2" alt="Questionario D2">Responder</button>
                                    </div>
                                    <div *ngIf="temQuestionarioVinculado" class="col-3">                                        
                                        <button *ngIf="questionarioFormando.status != 'ENVIADO'" class="btn btn-primary" routerLink="/secured/questionariod2-answer/{{questionarioFormando.id}}" title="Questionario D2" alt="Questionario D2">Responder</button>
                                        <button *ngIf="questionarioFormando.status == 'ENVIADO'" class="btn btn-primary" disabled title="Questionario D2" alt="Questionario D2">Enviado</button>
                                    </div>
                                </div-->
                                <!--div div *ngIf="(nivelFormacao == 'D2 Ext')" class="row">
                                    <div class="col-3">
                                        &nbsp;
                                    </div>
                                    <div class="col-6" >
                                        <div class="form-group">
                                            <label>1º Questionario do D2 em Prova - CV</label>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button class="btn btn-primary" routerLink="../questionariod2-ext-answer" title="Questionario D2" alt="Questionario D2">Responder</button>
                                    </div>
                                </div-->
                                <!--div *ngIf="(!temQuestionarioVinculado && (nivelFormacao == 'D2' || nivelFormacao == 'D2 Ext.'))" class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!verificouDados">                            
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" [(ngModel)]="verificouDados">
                                            <label class="form-check-label">Dados como o seu nível formativo, estado de vida e missão atual são muito importantes para o pastoreio. Antes de preencher o questionário, por favor, confira se seus dados vocacionais estão atualizados!</label>
                                        </div>                            
                                    </div>
                                </div-->
                                <div *ngIf="(!temQuestionarioVinculado && formaVida == 'CA')" class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!verificouDados">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox"
                                                [(ngModel)]="verificouDados">
                                            <label class="form-check-label">Dados como o seu nível formativo, estado de
                                                vida, formador pessoal são muito importantes para o pastoreio. Antes de
                                                preencher o questionário, por favor, confira se seus dados vocacionais
                                                estão atualizados!</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>