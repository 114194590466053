import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { environment } from "src/environments/environment";
export class ListagemPessoaInfoModalComponent {
    constructor(pessoaService, paisService, missaoService, wopGetAllPublic, swtAlert2Service, imagemService) {
        this.pessoaService = pessoaService;
        this.paisService = paisService;
        this.missaoService = missaoService;
        this.wopGetAllPublic = wopGetAllPublic;
        this.swtAlert2Service = swtAlert2Service;
        this.imagemService = imagemService;
        this.eventEmitter = new EventEmitter();
        this.pessoas = new Array();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.paises = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.filtrosExtras = new Array();
        this.busca = {
            nome: null,
            sobrenome: null,
            genero: null,
            anonascimento: null,
            nacionalidadeId: null,
            missaoAtualId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.carregarPaises();
        this.carregarMissoes();
        this.buscarPessoas();
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity.sort((a, b) => a.nome.trim().localeCompare(b.nome.trim()));
        }).catch((err) => {
            console.log(err);
        });
    }
    buscarPessoas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.busca.missionario = (this.onlyMissionaries != null && this.onlyMissionaries != undefined) ? this.onlyMissionaries : null;
            if (this.bringNotInformedWayOfLife == true) {
                this.busca.formaVidaId = 0;
            }
            else if (this.bringNotInformedWayOfLife == false) {
                this.busca.formaVidaId = -1;
            }
            this.busca.filtrarPorAcessoUsuario = this.filterByUserAccess;
            try {
                let response = yield this.pessoaService.pesquisarPessoasInfoListagem(this.busca);
                this.pessoas = response.entity;
                this.totalDePaginas = response.quantity;
                for (let i = 0; i < this.pessoas.length; i++) {
                    if (this.pessoas[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.pessoas[i].imagemId);
                        this.imagemService.createImage(blogImage, this.pessoas[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = null;
                if (this.filterByUserAccess == undefined || this.filterByUserAccess == true) {
                    response = yield this.missaoService.findAll();
                }
                else if (this.filterByUserAccess == false) {
                    response = yield this.wopGetAllPublic.getAllMissao();
                }
                this.missoes = response.entity.sort((a, b) => a.nome.localeCompare(b.nome.trim()));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarPessoas();
    }
    getUrlImagem(imagemId) {
        return this.urlBaseImagem + imagemId;
    }
    pessoaSelecionada(pessoa) {
        this.eventEmitter.emit(pessoa);
    }
    liberarFiltro(filtro) {
        return this.filtrosExtras.includes(filtro);
    }
}
