import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { PessoaService } from '../pessoa/service/pessoa.service';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagemService } from '../../service/imagem.service';
import { EnderecoService } from '../../service/endereco.service';
import { DocumentoService } from '../../service/documento.service';
import { AptidaoService } from '../../service/aptidao.service';
import { EscolaridadeService } from '../../service/escolaridade.service';
import { TrabalhoService } from '../../service/trabalho.service';
import { PerfilEditComponent } from './perfil.edit.component';
import { PerfilRoutingModule } from './perfil-routing.module';
import { FamiliaInfoBuilder } from 'src/app/builder/familiainfo.builder';
import { PessoaNacionalidadeBuilder } from 'src/app/builder/pessoanacionalidade.builder';
import { PessoaNacionalidadeService } from '../pessoa/service/pessoanacionalidade.service';
import { NacionalidadeBuilder } from 'src/app/builder/nacionalidade.builder';
import { VistoBuilder } from 'src/app/builder/visto.builder';
import { FamiliaInfoLocalService } from '../../service/familiainfo.local.service';
import { NacionalidadeService } from '../../service/nacionalidade.service';
import { VistoLocalService } from '../../service/visto.local.service';

 @NgModule({
     declarations:[
         PerfilEditComponent
     ],
     imports:[
         CommonModule,
         RouterModule,
         PerfilRoutingModule,
         FormsModule,
         SecuredModule,
         IgxTabsModule,
         ImageCropperModule
     ],
     providers:[
         PessoaService,
         EnderecoService,
         ImagemService,
         DocumentoService,
         AptidaoService,
         EscolaridadeService,
         TrabalhoService,
         FamiliaInfoLocalService,
         FamiliaInfoBuilder,
         PessoaNacionalidadeService,
         PessoaNacionalidadeBuilder,
         NacionalidadeService,
         NacionalidadeBuilder,
         VistoLocalService,
         VistoBuilder,
     ]
 })
 export class PerfilModule {
     
 }