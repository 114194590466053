import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Usuario } from "../model/usuario.model";
import { Pessoa } from "../model/pessoa.model";

@Injectable({
  providedIn: "root",
})
export class LoggedInUserSharedDataService {
  loggedInUser$ = new BehaviorSubject<Usuario | null>(null);
  loggedInPerson$ = new BehaviorSubject<Pessoa | null>(null);

  constructor() {}

  updateLoggedInUser(user: Usuario) {
    this.loggedInUser$.next(user);
  }

  updateLoggedInPerson(person: Pessoa) {
    this.loggedInPerson$.next(person);
  }
}
