<body class="hold-transition login-page">
    <div class="login-page-item">
        <div class="login-logo">
            <a i18n href="../../login"><b>WoP - </b>MISSIONáRIO<b></b></a>
        </div>
        <div class="login-page-item">
            <div class="callout callout-info">
                <p><i class="fas fa-info"></i>&nbsp;&nbsp;&nbsp;&nbsp;ATENÇÃO! Para concluir o seu cadastro é necessário acessar o link de confirmação que foi enviado para o e-mail cadastrado. <br>Obs.: Caso não encontre o e-mail na sua "Caixa de Entrada", verifique sua caixa de "Spam/Lixo Eletrônico"!</p>
            </div>
        </div>
    </div>
    <!-- /.login-logo -->
</body>