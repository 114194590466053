import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GenericService } from "src/app/core/services/generic.service";
import { RespostaQuestionario } from "src/app/model/respostaquestionario.model";
import { environment } from "src/environments/environment";

@Injectable()
export class RespostaQuestionarioService extends GenericService<RespostaQuestionario> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("respostaquestionario");
  }

  porPessoaId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.buscarRespostaQuestionario}${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  cadastrar(request: any) {
    return this.http
      .post<any>(
        environment.moduloFormacao.cadastrarRespostaQuestionario,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizar(id: number, request: any) {
    return this.http
      .put<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioRespostaFormando/${id}`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }
}
