import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RemocaoMembroCasaComunitariaBuilder } from 'src/app/builder/remocaomembrocasacomunitaria.builder';
import { SecuredModule } from 'src/app/secured/layout/secured.module';
import { RemocaoMembroCasaComunitariaComponent } from './remocao-membro-casa-comunitaria.component';
import { ListagemPessoaInfoModalModule } from '../../../pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module';

 @NgModule({
     declarations:[
      RemocaoMembroCasaComunitariaComponent
     ],
     imports:[
        CommonModule,
        RouterModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule,
        ListagemPessoaInfoModalModule
    ],
     exports:[
      RemocaoMembroCasaComunitariaComponent
    ],
    providers: [
      RemocaoMembroCasaComunitariaBuilder
    ]
 })
 export class RemocaoMembroCasaComunitariaModule {
     
 }