import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Pais } from 'src/app/model/pais.model';

import { environment } from "src/environments/environment";
import { ImagemService } from '../../../../service/imagem.service';
import { PessoaService } from '../../service/pessoa.service';
import { PessoaInfo } from 'src/app/model/pessoainfo.model';
import { Missao } from 'src/app/model/missao.model';
import { Difusao } from 'src/app/model/difusao.model';
import { PaisService } from 'src/app/secured/service/pais.service';
import { MissaoService } from 'src/app/secured/service/missao.service';
import { WopGetAllService } from 'src/app/service/getall.service';

@Component({
    selector: 'listagem-pessoainfo-modal-component',
    templateUrl: './listagempessoainfo.modal.component.html'})
export class ListagemPessoaInfoModalComponent implements OnInit {

    @Input() 
    public modalId: string;
    @Input() 
    public modalTitle: string;
    @Input() 
    public buttonText: string;
    @Input() 
    public altButtonText: string;
    @Input() 
    public onlyMissionaries: boolean;
    @Input() 
    public bringNotInformedWayOfLife: boolean;
    @Input() 
    public filterByUserAccess: boolean;
    @Input()
    public filtrosExtras: Array<string>;
    @Input()
    public bringEntitiesWithoutLinkedUser: boolean;

    @Output() 
    public eventEmitter = new EventEmitter();

    public pessoas: Array<PessoaInfo>;
    public paises: Array<Pais>;
    public missoes : Array<Missao>;
    public difusoes : Array<Difusao>;
    public busca: any;
    public totalDePaginas: number; 
    public urlBaseImagem: string;

    constructor(
        public pessoaService: PessoaService,
        public paisService: PaisService,
        public missaoService: MissaoService,
        public wopGetAllPublic: WopGetAllService,
        public swtAlert2Service: SwtAlert2Service,
        public imagemService: ImagemService
    ){
        this.pessoas = new Array<PessoaInfo>();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.paises = new Array<Pais>();
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.filtrosExtras = new Array<string>();
        this.busca = {
            nome: null,
            sobrenome: null,
            genero: null,
            anonascimento: null,
            nacionalidadeId: null,
            missaoAtualId: null,
            pageNumber: 0,
            quantityOfElements : 10
        };

    }

    ngOnInit(): void {
        this.carregarPaises();
        this.carregarMissoes();
        this.buscarPessoas();
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity.sort((a,b) => a.nome.trim().localeCompare(b.nome.trim()));
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    async buscarPessoas() : Promise<any> {

        this.busca.missionario = (this.onlyMissionaries != null && this.onlyMissionaries != undefined) ? this.onlyMissionaries : null;

        if(this.bringNotInformedWayOfLife == true)
        {
            this.busca.formaVidaId = 0;
        }        
        else if(this.bringNotInformedWayOfLife == false)
        {
            this.busca.formaVidaId = -1;
        }

        this.busca.filtrarPorAcessoUsuario = this.filterByUserAccess;

        try
        {
            let response = await this.pessoaService.pesquisarPessoasInfoListagem(this.busca);

            this.pessoas = response.entity;

            this.totalDePaginas = response.quantity;

            for(let i = 0; i < this.pessoas.length ; i++)
            {

                if(this.pessoas[i].imagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.pessoas[i].imagemId);

                    this.imagemService.createImage(blogImage, this.pessoas[i]);
                }  
                
            } 

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarMissoes(): Promise<any> {

        try
        {
            let response = null;
            
            if(this.filterByUserAccess == undefined || this.filterByUserAccess == true)
            {
                response = await this.missaoService.findAll();
            }
            else if(this.filterByUserAccess == false)
            {
                response = await this.wopGetAllPublic.getAllMissao();
            }
            
            this.missoes = response.entity.sort((a,b) => a.nome.localeCompare(b.nome.trim()));
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }
    
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarPessoas();
    }

    getUrlImagem(imagemId) : string {
        return this.urlBaseImagem+imagemId;
    }

    pessoaSelecionada(pessoa) {
        this.eventEmitter.emit(pessoa);
    }

    liberarFiltro(filtro: string) : boolean {
        return this.filtrosExtras.includes(filtro);
    }

}