import { NgModule } from '@angular/core';
import { GenericService } from 'src/app/core/services/generic.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UsuarioService } from '../../component/usuario/service/usuario.service';
import { LeftSidebarConselhoComponent } from './left-sidebar-conselho.component';

@NgModule({
    declarations: [ 
        LeftSidebarConselhoComponent
    ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [
        LeftSidebarConselhoComponent
    ],
    providers: [
        UsuarioService,
        GenericService
    ]
})
export class LeftSidebarConselhoModule {

}