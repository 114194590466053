import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SaudeEditComponent } from './saude.edit.component';
import { SaudeRoutingModule } from './saude-routing.module';
import { SaudeBuilder } from 'src/app/builder/saude.builder';
import { SaudeService } from './service/saude.service';
import { PessoaService } from '../pessoa/service/pessoa.service';

@NgModule({
    declarations:[
        SaudeEditComponent
    ],
    imports:[
        CommonModule,
        RouterModule,
        SaudeRoutingModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule
    ],
     providers:[
         PessoaService,
         SaudeService,
         SaudeBuilder
     ]
 })
  export class SaudeModule {
     
 }