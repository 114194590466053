import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import localeBr from "@angular/common/locales/br";
import { registerLocaleData } from "@angular/common";
import { ChartsModule } from "ng2-charts";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { LoginModule } from "./login/login.module";
import { SecuredModule } from "./secured/layout/secured.module";
import { LoaderComponent } from "./core/loader/loader.component";
import { LoaderService } from "./core/loader/service/loader.service";
import { LoaderInterceptor } from "./core/loader/interceptors/loader.interceptor";
import { IgxTabsModule } from "igniteui-angular";
import { ImageCropperModule } from "ngx-image-cropper";
import {
  DEFAULT_TIMEOUT,
  TimeoutInterceptor,
} from "./core/interceptor/timeout.interceptor";
import { AppRouteChangeService } from "./core/services/app-route-change.service";
import { NgxSummernoteModule } from "ngx-summernote";
import { WopGetAllService } from "./service/getall.service";
import { UsuarioModule } from "./secured/component/usuario/usuario.module";
import { PessoaModule } from "./secured/component/pessoa/pessoa.module";
import { CelulaModule } from "./secured/component/celula/celula.module";
import { CasaComunitariaLocalModule } from "./secured/component/casacomunitaria/casacomunitaria.module";
import { PerfilModule } from "./secured/component/perfil/perfil.module";
import { DadosVocacionaisModule } from "./secured/component/dadosvocacionais/dadosvocacionais.module";
import { SaudeModule } from "./secured/component/saude/saude.module";
import { CadastroPublicoModule } from "./secured/component/cadastropublico/cadastro.publico.module";
import { ConfirmacaoCadastroModule } from "./secured/component/confirmacaocadastro/confirmacao.cadastro.module";
import { ListagemPessoaModalModule } from "./secured/component/pessoa/modulo/listagem-pessoa-modal/listagempessoa.modal.module";
import { BemVindoModule } from "./secured/component/bemvindo/bemvindo.module";
import { AceiteTermoModule } from "./secured/component/aceitetermouso/aceitetermouso.module";
import { AceiteTermoTratamentoDadosModule } from "./secured/component/aceitetermotratamentodados/aceitetermotratamentodados.module";
import { QuestionarioModule } from "./secured/component/questionario/questionario.module";
import { ParecerModule } from "./secured/component/parecer/parecer.module";
import { PaisService } from "./secured/service/pais.service";
import { EstadoService } from "./secured/service/estado.service";
import { CidadeService } from "./secured/service/cidade.service";
import { RegionalService } from "./secured/service/regional.service";
import { MissaoService } from "./secured/service/missao.service";
import { DifusaoService } from "./secured/service/difusao.service";
import { PaisBuilder } from "./builder/pais.builder";
import { EstadoBuilder } from "./builder/estado.builder";
import { CidadeBuilder } from "./builder/cidade.builder";
import { ElementoGrupoDominioService } from "./secured/service/elementogrupodominio.service";
import { ElementoGrupoDominioLocalService } from "./secured/service/elementogrupodominio.local.service";
import { ElementoGrupoDominioBuilder } from "./builder/elementogrupodominio.builder";
import { CadastroPublicoCelulaModule } from "./public/cadastropublicocelula/cadastro.publico.celula.module";
import { ColegiadoModule } from "./secured/component/colegiado/colegiado.module";
import { CacheService } from "./service/cache.service";

registerLocaleData(localeBr);

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    ChartsModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LoginModule,
    SecuredModule,
    UsuarioModule,
    PessoaModule,
    CelulaModule,
    CasaComunitariaLocalModule,
    BrowserAnimationsModule,
    IgxTabsModule,
    ImageCropperModule,
    PerfilModule,
    DadosVocacionaisModule,
    SaudeModule,
    CadastroPublicoModule,
    ConfirmacaoCadastroModule,
    NgxSummernoteModule,
    ListagemPessoaModalModule,
    BemVindoModule,
    AceiteTermoModule,
    AceiteTermoTratamentoDadosModule,
    CasaComunitariaLocalModule,
    QuestionarioModule,
    ParecerModule,
    CadastroPublicoCelulaModule,
    ColegiadoModule,
  ],
  providers: [
    LoaderService,
    WopGetAllService,
    AppRouteChangeService,
    PaisService,
    EstadoService,
    CidadeService,
    RegionalService,
    MissaoService,
    DifusaoService,
    ElementoGrupoDominioService,
    ElementoGrupoDominioLocalService,
    PaisBuilder,
    EstadoBuilder,
    CidadeBuilder,
    ElementoGrupoDominioBuilder,
    CacheService,
    [
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    ],
    { provide: DEFAULT_TIMEOUT, useValue: 1800000 },
    { provide: LOCALE_ID, useValue: "br" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
