<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Pastoreio CAL - 1º Questionario</h3>
                    </div>
                </div>
            </div>
            <div class="callout callout-info">
                <h5>Atenção!</h5>
                Após o preenchimendo dos <strong><em>Dados Pessoais</em></strong> solicitados abaixo, os botões
                <strong><em>Salvar</em></strong> e <strong><em>Próximo</em></strong> serão liberados.
                <br>
                Salve o formulário e, após o salvamento, clique em <strong><em>Próximo</em></strong> para acessar a aba
                <strong><em>Questionário</em></strong>.
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-questionario-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill"
                                href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab"
                                aria-selected="true">Dados Pessoais</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="questionario-tab" data-toggle="pill"
                                href="#questionario-tab-content" role="tab" aria-controls="questionario-tab"
                                aria-selected="false" style="pointer-events: none;">Questionário</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel"
                            aria-labelledby="dados-pessoais-tab">
                            <form #dadosPessoaisForm="ngForm" (ngSubmit)="salvar(pessoa)" novalidate>
                                <div class="row">
                                    <div *ngIf="(pessoa.id != undefined)" class="col-sm-3 col-md-3 col-lg-3">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="text-center" style="margin: 1rem auto 2rem;">
                                                        <div class="widget-user-image">
                                                            <img class="profile-user-img img-fluid img-circle elevation-2"
                                                                [src]="pessoa.thumbnail == null ? 'assets/images/ppl.png' : pessoa.thumbnail"
                                                                alt="Imagem da pessoa" style="width: auto;">
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn btn-block btn-default"
                                                        data-toggle="modal" data-target="#modal-pessoa-imagem">Alterar
                                                        Imagem</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="(pessoa.id != undefined) ? 'col-sm-9 col-md-9 col-lg-9' : 'col-sm-12 col-md-12 col-lg-12'">
                                        <div style="padding: 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_nome">Nome</label>
                                                                <input [(ngModel)]="pessoa.nome" name="npessoa_nome"
                                                                    type="text" class="form-control" id="ncev_nome"
                                                                    placeholder="Digite o seu nome" i18n-placeholder
                                                                    required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Sobrenome</label>
                                                                <input [(ngModel)]="pessoa.sobrenome"
                                                                    name="npessoa_sobrenome" type="text"
                                                                    class="form-control" id="npessoa_sobrenome"
                                                                    placeholder="Digite o seu sobrenome"
                                                                    i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_dtnas">Data de
                                                                    Nascimento</label>
                                                                <input [(ngModel)]="pessoa.dataNascimento"
                                                                    name="npessoa_dtnas" type="date"
                                                                    class="form-control" id="npessoa_dtnas"
                                                                    i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_missao_atual">Missão
                                                                    Atual</label>
                                                                <select class="form-control" id="npessoa_missao_atual"
                                                                    [(ngModel)]="pessoa.vinculoMissao.missaoAtualId"
                                                                    name="npessoa_missao_atual">
                                                                    <option disabled value="null" i18n>Selecione uma
                                                                        missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo"
                                                                        [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 col-lg-8">
                                                            <div class="form-group">
                                                                <div class="checkbox icheck-concrete">
                                                                    <input [(ngModel)]="dadoFormandoCA.ja_permaneceu"
                                                                        type="checkbox" name="nja_permaneceu"
                                                                        id="nja_permaneceu" />&nbsp;
                                                                    <label class="form-check-label"
                                                                        for="nja_permaneceu">Você já permaneceu em algum
                                                                        Nível Formativo?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group"
                                                                *ngIf="dadoFormandoCA.ja_permaneceu">
                                                                <label i18n for="nivel_permaneceu">Em qual nível
                                                                    permaneceu?</label>
                                                                <select class="form-control" name="nivel_form"
                                                                    name="nivel_permaneceu" id="nivel_permaneceu"
                                                                    [(ngModel)]="dadoFormandoCA.nivel_permaneceu">
                                                                    <option disabled [value]="null">Selecione um nível
                                                                        de
                                                                        formação
                                                                    </option>
                                                                    <option *ngFor="let nivelFormacao of niveisFormacao"
                                                                        [value]="nivelFormacao.id">
                                                                        {{nivelFormacao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_pessoal">Formador Pessoal</label>
                                                                <span class="form-control"
                                                                    name="form_pessoal">{{pessoaFormadorPessoalSelecionado.nome}}
                                                                    {{pessoaFormadorPessoalSelecionado.sobrenome}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nqtdeform_pessoais">Nº de Formações
                                                                    Pessoais</label>
                                                                <input
                                                                    [(ngModel)]="caminhoFormativo.qtde_formacao_pessoal"
                                                                    name="nqtdeform_pessoais" type="number"
                                                                    class="form-control" id="nqtdeform_pessoais"
                                                                    placeholder="Digite..." i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <!--div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Há quanto tempo ele
                                                                    é seu Formador Pessoal</label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Digite..." i18n-placeholder required>
                                                            </div>
                                                        </div-->
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Formador
                                                                    Comunitário</label>
                                                                <input
                                                                    [(ngModel)]="caminhoFormativo.nome_formador_comunitario"
                                                                    name="form_comunitario" type="text"
                                                                    class="form-control" id="form_comunitario"
                                                                    placeholder="Digite..." i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Nº de
                                                                    Acompanhamentos Comunitários</label>
                                                                <input
                                                                    [(ngModel)]="caminhoFormativo.qtde_acompanhamento_comunitario"
                                                                    name="nacomp_comunitario" type="number"
                                                                    class="form-control" id="nacomp_comunitario"
                                                                    placeholder="Digite..." i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nacompanhadoPor">Quem te acompanha
                                                                    comunitariamente?</label>
                                                                <select class="form-control" id="nacompanhadoPor"
                                                                    name="nacompanhadoPor"
                                                                    [(ngModel)]="caminhoFormativo.acompanhado_por"
                                                                    required>
                                                                    <option value="1" i18n>Formador Comunitário</option>
                                                                    <option value="2">Formador Assistente</option>
                                                                    <option value="3">Coordenador do Colegiado</option>
                                                                    <option value="4">Responsável Local</option>
                                                                    <option value="5">Outro Missionário</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nform_pessoais">Qual a sua situação
                                                                    profissional?</label>
                                                                <select class="form-control" id="nsituacao_profissional"
                                                                    name="nsituacao_profissional"
                                                                    [(ngModel)]="dadoFormandoCA.situacao_profissional"
                                                                    required>
                                                                    <option value="0" i18n>Nenhuma das opções</option>
                                                                    <option value="1">Trabalha</option>
                                                                    <option value="2">Estuda</option>
                                                                    <option value="3">Aposentado</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Exerce função de
                                                                    autoridade apostolica? Qual?</label>
                                                                <input
                                                                    [(ngModel)]="caminhoFormativo.funcao_autoridade_apostolica"
                                                                    name="nfunc_autoridadeapostolica" type="text"
                                                                    class="form-control" id="nfunc_autoridadeapostolica"
                                                                    placeholder="Digite..." i18n-placeholder>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <div class="checkbox icheck-concrete">
                                                                    <input
                                                                        [(ngModel)]="dadoFormandoCA.alianca_missionaria"
                                                                        type="checkbox" name="nalianca_missionaria"
                                                                        id="nalianca_missionaria" />&nbsp;
                                                                    <label class="form-check-label"
                                                                        for="nalianca_missionaria">Está atualmente como
                                                                        Aliança Missionária?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6"
                                                            *ngIf="dadoFormandoCA.alianca_missionaria">
                                                            <div class="form-group">
                                                                <label i18n for="casa_comunitaria_desc">Informe em qual
                                                                    Casa Comunitária você está.</label>
                                                                <input
                                                                    [(ngModel)]="dadoFormandoCA.casa_comunitaria_desc"
                                                                    type="text" name="casa_comunitaria_desc"
                                                                    id="casa_comunitaria_desc" class="form-control"
                                                                    placeholder="Digite..." i18n-placeholder>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <div class="form-group">
                                                                <label i18n>Esses dados são importantes para o seu
                                                                    pastoreio, por favor confirme-os!</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="submit" class="btn"
                                                        [ngClass]="pessoa.id != undefined ? 'btn-primary' : 'btn-success'"
                                                        [disabled]="!dadosPessoaisForm.valid" i18n>Salvar</button>&nbsp;
                                                    <button type="button" class="btn btn-default"
                                                        (click)="activateTab('questionario-tab-content')"
                                                        *ngIf="pessoa.id != undefined"
                                                        [disabled]="dadosPessoaisForm.valid === false || caminhoFormativo.id === null">Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="questionario-tab-content" role="tabpanel"
                            aria-labelledby="questionario-tab">
                            <form *ngIf="pessoa.id != undefined" #questionarioForm="ngForm" novalidate>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div style="padding: 1rem 1rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <h4 class="card-title">A partir de agora, responda com
                                                                sinceridade e transparência as seguintes perguntas. Seja
                                                                sincero
                                                                consigo mesmo(a), mas seja livre, partilhe apenas aquilo
                                                                que julgar que deve. Se existe algo mais a
                                                                expressar e que você não queira fazer por aqui, pode
                                                                buscar o teu FC ou a Formação Inicial.
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta39">
                                                                    1. Você recebeu o feedback do pastoreio de 2023?
                                                                </label>
                                                                <select class="form-control" id="nresposta39"
                                                                    name="nresposta39" [(ngModel)]="resposta39.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Sim</option>
                                                                    <option value="2">Não</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta40">
                                                                    2. Como foi pra você ter recebido este feedback,
                                                                    você acredita que os pontos recebidos tem a ver com
                                                                    o caminho que você tem trilhado?
                                                                </label>
                                                                <select class="form-control" id="nresposta40"
                                                                    name="nresposta40" [(ngModel)]="resposta40.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Me senti conhecido pela
                                                                        Comunidade. Acredito que estes são mesmo os
                                                                        pontos que preciso trabalhar.</option>
                                                                    <option value="2">Fiquei surpreso, não esperava. Mas
                                                                        acolhi bem, concordo com o que me foi dito.
                                                                    </option>
                                                                    <option value="3">Não me senti confortável com o que
                                                                        recebi, não me percebo da forma como foi dito.
                                                                    </option>
                                                                    <option value="4">Acredito que o feedback
                                                                        corresponde a mim apenas parcialmente. Sinto que
                                                                        preciso ser melhor conhecido pelas minhas
                                                                        autoridades.</option>
                                                                    <option value="5">Não recebi nenhum feedback de
                                                                        pastoreio ainda.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta41">
                                                                    3. Você se sente ajudado no seu caminho de formação?
                                                                </label>
                                                                <select class="form-control" id="nresposta41"
                                                                    name="nresposta41" [(ngModel)]="resposta41.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Sim, me sinto ajudado pelas
                                                                        minhas autoridades. Sinto que tenho o suporte
                                                                        necessário.</option>
                                                                    <option value="2">Sinto que sou ajudado, mas preciso
                                                                        buscar mais aqueles que podem me auxiliar.
                                                                    </option>
                                                                    <option value="3">Sinto que não sou ajudado, porque
                                                                        evito buscar ajuda.</option>
                                                                    <option value="4">Sinto que não recebo a ajuda
                                                                        necessária.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    4. Em que aspectos você sente mais necessidade de
                                                                    ser ajudado?
                                                                </label>
                                                                <input [(ngModel)]="resposta42.resposta" maxlength="600"
                                                                    type="text" id="resposta42" name="resposta42"
                                                                    required class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    5. Como você percebe a sua vivência dos conselhos
                                                                    evangelicos:
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta43">
                                                                    5.1 Como você percebe a sua vivência da pobreza?
                                                                </label>
                                                                <select class="form-control" id="nresposta43"
                                                                    name="nresposta43" [(ngModel)]="resposta43.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que preciso crescer no meu
                                                                        caminho de conversão.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta44">
                                                                    5.2 Como você percebe a sua vivência da castidade?
                                                                </label>
                                                                <select class="form-control" id="nresposta44"
                                                                    name="nresposta44" [(ngModel)]="resposta44.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que preciso crescer no meu
                                                                        caminho de conversão.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    5.3 Como você percebe a sua vivência da obediência?
                                                                </label>
                                                                <select class="form-control" id="nresposta45"
                                                                    name="nresposta45" [(ngModel)]="resposta45.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que preciso crescer no meu
                                                                        caminho de conversão.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    5.4 Ainda sobre os conselhos evangelicos, existe
                                                                    algo que você gostaria de partilhar?
                                                                </label>
                                                                <input [(ngModel)]="resposta46.resposta" maxlength="600"
                                                                    type="text" id="resposta46" name="resposta46"
                                                                    required class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    6. Como você se vê na vivência do tripé da vocação?
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    6.1 Como você se percebe na vivencia da sua vida de
                                                                    contemplação, intimidade com Deus?
                                                                </label>
                                                                <select class="form-control" id="nresposta47"
                                                                    name="nresposta47" [(ngModel)]="resposta47.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que não tenho conseguido ser
                                                                        fiel a minha vida de contemplação.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    6.2 Como você se percebe na tua vivencia apostolica?
                                                                </label>
                                                                <select class="form-control" id="nresposta48"
                                                                    name="nresposta48" [(ngModel)]="resposta48.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que não tenho conseguido me
                                                                        dedicar aos meus compromissos apostólicos.
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    6.3 Como você se percebe na vivencia fraterna, na
                                                                    unidade?
                                                                </label>
                                                                <select class="form-control" id="nresposta49"
                                                                    name="nresposta49" [(ngModel)]="resposta49.resposta"
                                                                    required>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que não tenho conseguido
                                                                        viver bem a minha vida fraterna</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <div class="card card-secondary">
                                                                <div class="card-header">
                                                                    <h3 class="card-title">Este campo é de acesso
                                                                        exclusivo do Responsavel Local</h3>
                                                                </div>
                                                                <div class="card-body">
                                                                    <div class="form-group">
                                                                        <label for="descricao">Caso você deseje fazer
                                                                            alguma partilha de acesso somente do
                                                                            Responsável Local, utilize este espaço. Este
                                                                            campo não é obrigatório!
                                                                        </label>
                                                                        <input [(ngModel)]="mensagemRL.mensagem"
                                                                            type="text" id="msg_cl" name="msg_cl"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="button" class="btn btn-default float-left"
                                                        style="margin-left: 0.5rem;"
                                                        (click)="activateTab('dados-pessoais-tab-content')"
                                                        i18n>Voltar</button>
                                                    <button type="button" (click)="salvarQuestionario()"
                                                        class="btn btn-primary" i18n>Salvar</button>&nbsp;
                                                    <button type="button" class="btn btn-primary"
                                                        [disabled]="questionarioForm.invalid"
                                                        (click)="enviarQuestionario()" i18n>Enviar</button>
                                                    <!--button type="button" class="btn btn-default" [disabled]="questionarioForm.invalid || endereco.id == undefined" (click)="activateTab('endereco-tab-content')" i18n>Proximo</button-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="modal fade" id="modal-pessoa-imagem" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Selecione a imagem</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4">
                            <div class="card text-center">
                                <div class="card-title">
                                    Preview
                                </div>
                                <div class="card-body">
                                    <img class="img-circle elevation-2"
                                        [src]="(croppedImage=='' ) ? 'assets/images/ppl.png' : croppedImage" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-8">
                            <div class="card text-center">
                                <div class="card-title">
                                    Recortar Imagem
                                </div>
                                <div class="card-body" style="min-height: 242px;">
                                    <image-cropper style="max-height: 300px;" [imageChangedEvent]="imageChangedEvent"
                                        [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="200"
                                        format="png" (imageCropped)="imageCropped($event)
                                                                                   " (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 5%;">
                        <div class="text-center col-md-12">
                            <div class="box">
                                <input style="display:none;" (change)="fileChangeEvent($event)" type="file"
                                    name="file-3[]" id="file-3" class="inputfile inputfile-3">
                                <label for="file-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                        <path
                                            d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6
                                                                                    0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                        </path>
                                    </svg>
                                    <span>Clique para selecione a imagem</span></label>
                            </div>
                        </div>
                        <label class="custom-file" id="customFile">
                            <input type="file" (change)="fileChangeEvent($event)" class="custom-file-input"
                                id="exampleInputFile" aria-describedby="fileHelp">
                            <span class="custom-file-control form-control-file"></span>
                        </label>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" (click)="salvarImagem()" class="btn btn-primary">Salvar Imagem</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-formador-pessoal'" [filtrosExtras]="['missao']"
            [filterByUserAccess]="false" [onlyMissionaries]="true" [modalTitle]="'Selecione o seu formador(a) pessoal'"
            [altButtonText]="'Selecionar Formador(a)'" [buttonText]="'Selecionar'"
            (eventEmitter)="selecionarFormadorPessoal($event)">
        </listagem-pessoainfo-modal-component>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-formador-comunitario'"
            [filtrosExtras]="['missao']" [filterByUserAccess]="false" [onlyMissionaries]="true"
            [modalTitle]="'Selecione o seu formador(a) comunitário(a)'" [altButtonText]="'Selecionar Formador(a)'"
            [buttonText]="'Selecionar'" (eventEmitter)="selecionarFormadorComunitario($event)">
        </listagem-pessoainfo-modal-component>
    </div>
</secured>