import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
export class QuestionariosComponent {
    constructor(missaoConfiguracaoService, pessoaService, elementoGrupoDominioService, elementoGrupoDominioLocalService, pessoaBuilder, questionarioFormandoBuilder, dadoVocacionalService, questionarioFormandoService, swtAlert2Service, usuarioService, route, router) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.pessoaService = pessoaService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.pessoaBuilder = pessoaBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.route = route;
        this.router = router;
        this.niveisFormacao = new Array();
        this.formasVida = new Array();
        this.pessoa = this.pessoaBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.nivelFormacao = "";
        this.formaVida = "";
        this.verificouDados = false;
        this.temQuestionarioVinculado = false;
    }
    ngOnInit() {
        this.initialization();
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getUsuarioAtual();
            this.carregarNiveisFormacao();
            yield this.carregarPessoa();
            yield this.carregarDadoVocacional();
            yield this.carregarQuestionarios();
            this.carregarQuestionarioConfiguracao();
        });
    }
    carregarQuestionarioConfiguracao() {
        const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, 3
        // this.questionarioFormando.questionarioId
        )
            .subscribe((questionarioConfiguracao) => (this.questionarioConfiguracao = questionarioConfiguracao));
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarNiveisFormacao() {
        let busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then((lista) => {
            this.niveisFormacao = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.nivelFormacao = this.dadoVocacional.nivelFormacaoNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarios() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorPessoaId(this.pessoa.id);
                if (response != undefined && response != null) {
                    this.questionarioFormando = response;
                    if (this.questionarioFormando.questionarioId == 3)
                        this.temQuestionarioVinculado = true;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioCAL() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.questionarioFormando.anoFormativoId = 2;
                this.questionarioFormando.questionarioId = 3;
                this.questionarioFormando.pessoaId = this.pessoa.id;
                let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                if (response != undefined && response != null) {
                    this.questionarioFormando = response;
                    this.router.navigate([
                        "/secured/questionariocal-answer/" + this.questionarioFormando.id,
                    ]);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioD2() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.questionarioFormando.anoFormativoId = 2;
                this.questionarioFormando.questionarioId = 1;
                this.questionarioFormando.pessoaId = this.pessoa.id;
                let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                if (response != undefined && response != null) {
                    this.questionarioFormando = response;
                    this.router.navigate([
                        "/secured/questionariod2-answer/" + this.questionarioFormando.id,
                    ]);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);
                this.pessoa = response.entity;
                this.pessoaId = this.pessoa.id;
                if (this.pessoa.formaVidaId != null) {
                    this.setarFormaVidaSelecionada();
                    this.formaVida = this.pessoa.formaVidaValor;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    setarFormaVidaSelecionada() {
        for (let i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    }
}
