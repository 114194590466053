import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';


@Injectable()
export class CadastroPublicoLocalService {
    
    constructor(protected http: HttpClient, protected router:Router) {
    }

    public getHeaders() : HttpHeaders {
        return new HttpHeaders({
            'Content-Type' : 'application/json; charset=UTF-8'            
        }); 
    }

    cadastrar(obj: any) : Promise<any> {
        return this.http.post<any>(environment.usuario.cadastroPublico, JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    elementosGrupoDominioPorNomeGrupo(obj: any) : Promise<any> {
        return this.http.post<any>(environment.elementoGrupoDominio.porNomeGrupo, JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    getTermoVigente(): Promise<any> {
        return this.http.get<any>(environment.termoUso.getTermoVigentePublic).toPromise();
    }

    getTermoTratamentoDadosVigente(): Promise<any> {
        return this.http.get<any>(environment.termoTratamentoDados.getTermoVigentePublic).toPromise();
    }

}