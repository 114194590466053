import { NgModule } from "@angular/core";
import { GenericService } from "src/app/core/services/generic.service";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { UsuarioService } from "../../component/usuario/service/usuario.service";
import { LeftSidebarComissaoDiscernimentoComponent } from "./left-sidebar-comissao-discernimento.component";

@NgModule({
  declarations: [LeftSidebarComissaoDiscernimentoComponent],
  imports: [RouterModule, CommonModule],
  exports: [LeftSidebarComissaoDiscernimentoComponent],
  providers: [UsuarioService, GenericService],
})
export class LeftSidebarComissaoDiscernimentoModule {}
