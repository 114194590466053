import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit, } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
export class QuestionarioCasaComunitariaComponent {
    //questionarioConfiguracao: QuestionarioConfiguracao;
    constructor(
    //private missaoConfiguracaoService: MissaoConfiguracaoService,
    usuarioService, imagemService, pessoaService, casacomunitariaService, membroService, elementoGrupoDominioService, formadorComunitarioService, formadorAssistenteService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, caminhoFormativoService, route, router, swtAlert2Service, arquivoService, sanitizer) {
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.casacomunitariaService = casacomunitariaService;
        this.membroService = membroService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.unsubscribe$ = new Subject();
        this.totalDePaginas = 1;
        this.idsPPs = [25];
        this.idsPDs = [30, 31, 108, 204, 205];
        this.idsD1s = [24];
        this.idsPostulantes = [22, 23];
        this.idsRenovacao = [26, 27, 28, 29, 30, 31, 108, 204];
        this.selectedFile = null;
        this.errorMessage = "";
        this.isFormValidUpload = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCasaComunitaria = new Array();
        this.questionariosCasaComunitaria = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCasaComunitariaAssociada = false;
        this.buscaMembros = {
            casacomunitariaId: 0,
            pageNumber: 0,
            quantityOfElements: 0,
        };
        this.buscaQuestionarioFormando = {
            questionarioId: null,
            casaCelulaId: null,
            pessoaId: null,
            pageNumber: 0,
            quantityOfElements: 10,
        };
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.questionarioId = Number(this.route.snapshot.paramMap.get("id"));
            this.carregarNivelFormacao();
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            yield this.carregarFormadorComunitario();
            if (this.formadorComunitario != undefined &&
                this.formadorComunitario.casaCelulaId != null) {
                yield this.carregarCasaComunitaria(this.formadorComunitario.casaCelulaId);
                this.buscaMembros = {
                    celulaId: this.casacomunitaria.id,
                    pageNumber: 0,
                    quantityOfElements: 4,
                };
                this.buscaQuestionarioFormando = {
                    questionarioId: this.questionarioId,
                    casaCelulaId: this.casacomunitaria.id,
                    pessoaId: null,
                };
                yield this.carregarMembrosCasaComunitaria();
            }
            else {
                yield this.carregarFormadorAssistente();
                if (this.formadorAssistente != undefined &&
                    this.formadorAssistente.casaCelulaId != null) {
                    yield this.carregarCasaComunitaria(this.formadorAssistente.casaCelulaId);
                    this.buscaMembros = {
                        celulaId: this.casacomunitaria.id,
                        pageNumber: 0,
                        quantityOfElements: 4,
                    };
                    this.buscaQuestionarioFormando = {
                        questionarioId: this.questionarioId,
                        casaCelulaId: this.casacomunitaria.id,
                        pessoaId: null,
                    };
                    yield this.carregarMembrosCasaComunitaria();
                }
                else
                    this.hasCasaComunitariaAssociada = false;
            }
        });
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
                /*if(this.pessoa.imagemId != null)
                  {
                      let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);
          
                      this.imagemService.createImage(blogImage, this.pessoa);
                  } */
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadorComunitario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorComunitarioService.buscarCasaComunitariaPorPessoaId(this.pessoa.id);
                this.formadorComunitario = response;
                if (this.formadorComunitario &&
                    this.formadorComunitario.pessoaImagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formadorComunitario.pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadorComunitario);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadorAssistente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorAssistenteService.buscarCasaComunitariaPorPessoaId(this.pessoa.id);
                this.formadorAssistente = response;
                if (this.formadorAssistente &&
                    this.formadorAssistente.pessoaImagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formadorAssistente.pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadorAssistente);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarCasaComunitaria(casaComunitariaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casacomunitariaService.find(casaComunitariaId);
                this.casacomunitaria = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarQuestionarioCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorCasaComunitariaId(this.buscaQuestionarioFormando);
                this.questionariosCasaComunitaria = response;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMembrosCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let idsBusca = [];
                switch (this.questionarioId) {
                    case 4:
                        idsBusca = this.idsPPs;
                        break;
                    case 5:
                        idsBusca = this.idsPDs;
                        break;
                    case 6:
                        idsBusca = this.idsPostulantes;
                        break;
                    case 7:
                        idsBusca = this.idsD1s;
                        break;
                    case 8:
                        idsBusca = this.idsRenovacao;
                        break;
                    default:
                        idsBusca = null;
                        break;
                }
                if (idsBusca != null) {
                    let response = yield this.membroService.listarMembrosCasaComunitariaPorNivel(this.casacomunitaria.id, idsBusca);
                    this.membrosCasaComunitaria = response.content;
                    this.totalDePaginasMembros = response.totalPages;
                }
                else {
                    let response = yield this.membroService.listarTodosMembrosCasaComunitaria(this.casacomunitaria.id);
                    this.membrosCasaComunitaria = response.content;
                    this.totalDePaginasMembros = response.totalPages;
                }
                yield this.carregarQuestionarioCasaComunitaria();
                /*if (this.membrosCasaComunitaria.length > 0) {
                  this.membrosCasaComunitaria = this.membrosCasaComunitaria.sort((a, b) =>
                    a.pessoaNome.localeCompare(b.pessoaNome)
                  );
                }*/
                for (let i = 0; i < this.membrosCasaComunitaria.length; i++) {
                    let response = yield this.pessoaService.buscaPorId(this.membrosCasaComunitaria[i].pessoaId);
                    let pessoa = response.entity;
                    this.membrosCasaComunitaria[i].pessoaNome =
                        pessoa.nome + " " + pessoa.sobrenome;
                    this.membrosCasaComunitaria[i].pessoaImagemId = pessoa.imagemId;
                    if (this.membrosCasaComunitaria[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCasaComunitaria[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                    }
                    let nivelFormativoMembro = this.niveisFormacao.find((obj) => obj.id === this.membrosCasaComunitaria[i].nivelFormativoId);
                    this.membrosCasaComunitaria[i].nivelFormativoNome = nivelFormativoMembro
                        ? nivelFormativoMembro.nome
                        : null;
                    const questionario = this.questionariosCasaComunitaria.filter((questionarioFormando) => questionarioFormando.id === this.membrosCasaComunitaria[i].id);
                    if (questionario.length > 0) {
                        this.membrosCasaComunitaria[i].statusQuestionario =
                            questionario[0].status_questionario;
                        this.membrosCasaComunitaria[i].idQuestionarioFormando =
                            questionario[0].questionarioFormandoId;
                        this.membrosCasaComunitaria[i].idQuestionario =
                            questionario[0].questionarioId;
                        this.membrosCasaComunitaria[i].statusParecerFC =
                            questionario[0].status_parecer_fc;
                        this.membrosCasaComunitaria[i].idParecerFC =
                            questionario[0].parecerIdFC;
                        this.membrosCasaComunitaria[i].acompanhadoPor =
                            questionario[0].acompanhadoPor;
                        this.membrosCasaComunitaria[i].acompanhadoPorNome =
                            questionario[0].acompanhadoPorNome;
                        this.membrosCasaComunitaria[i].arquivo = questionario[0].arquivo;
                        this.membrosCasaComunitaria[i].dataEnvioParecerFC =
                            questionario[0].dataEnvioParecerFC;
                        this.membrosCasaComunitaria[i].dataEnvioQuestionario =
                            questionario[0].dataEnvioQuestionario;
                        this.membrosCasaComunitaria[i].missaoAtualId =
                            questionario[0].missaoAtualId;
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    /*async carregarQuestionario(pessoaId: number): Promise<any> {
      try {
        let response = await this.questionarioFormandoService.buscarPorPessoaId(
          pessoaId
        );
        if (response != undefined && response != null) {
          return response;
        }
      } catch (err) {
        this.swtAlert2Service.errorAlert(err.error.errors);
      }
    }
  
    async carregarParecer(idQuestionario: number): Promise<any> {
      try {
        let response =
          await this.parecerResponsavelService.buscarPorQuestionarioFormandoId(
            idQuestionario
          );
        return response;
      } catch (err) {
        this.swtAlert2Service.errorAlert(err.error.errors);
      }
    }*/
    consultarCaminhoFormativo(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(formando.pessoaId);
                if (response != null && response != undefined) {
                    this.caminhoFormativo = response;
                }
                else {
                    this.caminhoFormativo = {};
                    this.caminhoFormativo.pessoaId = formando.pessoaId;
                    this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
                    this.caminhoFormativo.ano_formativo_id = 2;
                    this.caminhoFormativo.nome_formador_comunitario =
                        this.formadorComunitario.pessoaNome;
                    let response = yield this.caminhoFormativoService.cadastrar(this.caminhoFormativo);
                    this.caminhoFormativo = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioCAL(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.consultarCaminhoFormativo(formando);
                let responsePorPessoa = yield this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(formando.pessoaId, this.questionarioId);
                if (responsePorPessoa != undefined &&
                    responsePorPessoa != null &&
                    responsePorPessoa.questionarioId == this.questionarioId) {
                    this.questionarioFormando = responsePorPessoa;
                    this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                    //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                }
                else {
                    this.questionarioFormando.anoFormativoId = 2;
                    this.questionarioFormando.questionarioId = this.questionarioId;
                    this.questionarioFormando.pessoaId = formando.pessoaId;
                    let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                    if (response != undefined && response != null) {
                        this.questionarioFormando = response;
                        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                        formando.idQuestionarioFormando = this.questionarioFormando.id;
                        //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoParecerCAL(questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                this.parecerResponsavel.parecer_id = 3;
                this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                this.parecerResponsavel.tipo = "FC";
                let response = yield this.parecerResponsavelService.cadastrar(this.parecerResponsavel);
                if (response != undefined && response != null) {
                    this.parecerResponsavel = response;
                    this.router.navigate([
                        "/secured/parecer-cal-answer-default/" + this.parecerResponsavel.id,
                    ]);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    consultarVinculoQuestionarioCAL(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (formando.idQuestionarioFormando != undefined &&
                    formando.idQuestionarioFormando != null) {
                    if (formando.idParecerFC != undefined && formando.idParecerFC != null) {
                        this.router.navigate([
                            "/secured/parecer-cal-answer-default/" + formando.idParecerFC,
                        ]);
                    }
                    else {
                        yield this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
                    }
                }
                if (formando.idQuestionarioFormando == undefined ||
                    formando.idQuestionarioFormando == null) {
                    yield this.cadastrarVinculoQuestionarioCAL(formando);
                    yield this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarNivelFormacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: "NIVEL_FORMACAO",
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.niveisFormacao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    /**
     * CARTA
     */
    configModalUploadCarta(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (formando.questionarioFormandoId != undefined &&
                formando.questionarioFormandoId != null) {
                this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
            }
            else {
                yield this.cadastrarVinculoQuestionarioCAL(formando);
            }
        });
    }
    carregarCartaPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfCartaUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    onCartaFileSelected(event) {
        const file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFile = file;
        }
        else {
            this.selectedFile = null;
        }
    }
    onCartaUpload() {
        if (this.selectedFile) {
            this.questionarioFormandoService
                .uploadCarta(this.questionarioFormandoSelectedId, this.selectedFile)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: (data) => {
                    this.swtAlert2Service.successAlert(data);
                    this.carregarMembrosCasaComunitaria();
                    $("#modal-upload-carta").modal("hide");
                },
                error: (error) => {
                    this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: () => {
                    this.unsubscribe$.next();
                },
            });
        }
    }
    /**
     * Questionário
     */
    carregarArquivoPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    configModalUploadQuestionario(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (formando.questionarioFormandoId != undefined &&
                formando.questionarioFormandoId != null) {
                this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
            }
            else {
                yield this.cadastrarVinculoQuestionarioCAL(formando);
            }
        });
    }
    onFileSelected(event) {
        const file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFile = file;
            this.errorMessage = "";
        }
        else {
            this.selectedFile = null;
            this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
        }
        this.checkFormUploadValidity();
    }
    onUpload() {
        if (this.selectedFile && this.pedidoFormandoId) {
            this.questionarioFormandoService
                .uploadQuestionario(this.questionarioFormandoSelectedId, this.pedidoFormandoId, this.selectedFile)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: (data) => {
                    this.swtAlert2Service.successAlert(data);
                    this.carregarMembrosCasaComunitaria();
                    $("#modal-upload-questionario").modal("hide");
                },
                error: (error) => {
                    this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: () => {
                    this.unsubscribe$.next();
                },
            });
            this.pedidoFormandoId = null;
        }
        else {
            if (!this.pedidoFormandoId) {
                this.errorMessage =
                    "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
            }
            else {
                this.errorMessage =
                    "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
            }
        }
    }
    onTipoPedidoChange() {
        this.checkFormUploadValidity();
    }
    // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
    checkFormUploadValidity() {
        this.isFormValidUpload = !!this.pedidoFormandoId && !!this.selectedFile;
    }
    cliqueNoBotaoListener(botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.carregarMembrosCasaComunitaria();
    }
}
