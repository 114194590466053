import { Component, OnInit } from "@angular/core";
import { Pessoa } from "src/app/model/pessoa.model";
import { Usuario } from "src/app/model/usuario.model";
import { environment } from "src/environments/environment";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { UsuarioBuilder } from "src/app/builder/usuario.builder";
import { PessoaService } from "../../component/pessoa/service/pessoa.service";
import { UsuarioService } from "../../component/usuario/service/usuario.service";
import { ImagemService } from "../../service/imagem.service";

@Component({
  selector: "left-sidebar-comissao-discernimento-comp",
  templateUrl: "./left-sidebar-comissao-discernimento.component.html",
  styleUrls: ["./style/scrollbar.css"],
})
export class LeftSidebarComissaoDiscernimentoComponent implements OnInit {
  public usuarioAtual: Usuario;
  public usuarioBuilder: UsuarioBuilder;
  public pessoaAtual: Pessoa;
  public pessoaBuilder: PessoaBuilder;
  public urlBaseImagem: string;

  constructor(
    public usuarioService: UsuarioService,
    public pessoaService: PessoaService,
    public imagemService: ImagemService,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.urlBaseImagem = environment.imagem.urlBaseImagem;
    this.usuarioBuilder = new UsuarioBuilder();
    this.usuarioAtual = this.usuarioBuilder.getInstance();
    this.pessoaBuilder = new PessoaBuilder();
    this.pessoaAtual = this.pessoaBuilder.getInstance();
  }

  async getUsuarioAtual(): Promise<any> {
    this.usuarioAtual = JSON.parse(sessionStorage.getItem("usuarioAtual"));

    if (this.usuarioAtual == undefined || this.usuarioAtual == null) {
      try {
        let response = await this.usuarioService.getCurrentUser();
        this.usuarioAtual = response.entity;
        sessionStorage.setItem(
          "usuarioAtual",
          JSON.stringify(this.usuarioAtual)
        );
      } catch (e) {
        console.log(e);
      }
    }

    await this.getPessoaAtual();
  }

  async getPessoaAtual(): Promise<any> {
    this.pessoaAtual = JSON.parse(sessionStorage.getItem("pessoaAtual"));

    try {
      if (this.pessoaAtual == undefined || this.pessoaAtual == null) {
        let response = await this.pessoaService.buscaPorUsuarioId(
          this.usuarioAtual.id
        );
        this.pessoaAtual = response.entity;
        sessionStorage.setItem("pessoaAtual", JSON.stringify(this.pessoaAtual));
      }
      if (this.pessoaAtual.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.pessoaAtual.imagemId);
        this.imagemService.createImage(blogImage, this.pessoaAtual);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  exibirParaRole(regras: string[]): boolean {
    let retorno = false;

    if (
      this.usuarioAtual &&
      regras.includes(this.usuarioAtual.autorizacoesNome[0])
    ) {
      retorno = true;
    }

    return retorno;
  }

  async initialize(): Promise<any> {
    await this.getUsuarioAtual();
  }

  ngOnInit(): void {
    this.initialize();
  }
}
