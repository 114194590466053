import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CadastroPublicoCelulaComponent } from './cadastro.publico.celula.component';

const cadastroPublicoGrupoOracaoRoutes: Routes = [
    {
        path: 'cadastro/celula/:token',
        component: CadastroPublicoCelulaComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(cadastroPublicoGrupoOracaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CadastroPublicoCelulaRoutingModule {

}