import { NgModule } from "@angular/core";
import { LeftSidebarFormadorAssistenteComponent } from "./left-sidebar-formador-assistente.component";
import { GenericService } from "src/app/core/services/generic.service";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { UsuarioService } from "../../component/usuario/service/usuario.service";

@NgModule({
  declarations: [LeftSidebarFormadorAssistenteComponent],
  imports: [RouterModule, CommonModule],
  exports: [LeftSidebarFormadorAssistenteComponent],
  providers: [UsuarioService, GenericService],
})
export class LeftSidebarFormadorAssistenteModule {}
