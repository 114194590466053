import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
export class ParecerD2FCAnswerComponent {
    constructor(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCVService, caminhoFormativoService, respostaParecerService, questionarioFormandoService, parecerResponsavelService, dadoFormandoCVBuilder, caminhoFormativoFormandoBuilder, respostaParecerBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCVService = dadoFormandoCVService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.respostaParecerService = respostaParecerService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelService = parecerResponsavelService;
        this.dadoFormandoCVBuilder = dadoFormandoCVBuilder;
        this.caminhoFormativoFormandoBuilder = caminhoFormativoFormandoBuilder;
        this.respostaParecerBuilder = respostaParecerBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.dadoFormandoCV = this.dadoFormandoCVBuilder.getInstance();
        this.caminhoFormativoFormando =
            this.caminhoFormativoFormandoBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoaResponsavel = this.pessoaBuilder.getInstance();
        this.formando = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta1 = respostaParecerBuilder.getInstance();
        this.resposta2 = respostaParecerBuilder.getInstance();
        this.resposta3 = respostaParecerBuilder.getInstance();
        this.resposta4 = respostaParecerBuilder.getInstance();
        this.resposta5 = respostaParecerBuilder.getInstance();
        this.resposta6 = respostaParecerBuilder.getInstance();
        this.resposta7 = respostaParecerBuilder.getInstance();
        this.resposta8 = respostaParecerBuilder.getInstance();
        this.resposta9 = respostaParecerBuilder.getInstance();
        this.resposta10 = respostaParecerBuilder.getInstance();
        this.resposta11 = respostaParecerBuilder.getInstance();
        this.resposta12 = respostaParecerBuilder.getInstance();
        this.resposta13 = respostaParecerBuilder.getInstance();
        this.resposta14 = respostaParecerBuilder.getInstance();
        this.resposta15 = respostaParecerBuilder.getInstance();
        this.resposta16 = respostaParecerBuilder.getInstance();
        this.resposta17 = respostaParecerBuilder.getInstance();
        this.resposta18 = respostaParecerBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getUsuarioAtual();
            this.carregarPaises();
            this.carregarFormasVida();
            this.carregarNiveisFormacao();
            yield this.carregarMissoesVinculo();
            this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
            yield this.carregarParecerResponsavel();
            yield this.carregarQuestionarioFormando();
            yield this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
            this.pessoaResponsavel = yield this.carregarPessoaResponsavel(this.usuarioAtual.id);
            yield this.carregarDadoVocacional();
            yield this.carregarDadoFormandoCV();
            yield this.carregarCaminhoFormativo();
            yield this.carregarRespostas();
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllPublic.getAllMissao();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPessoaFormando(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorId(pessoaId);
                this.formando = response.entity;
                this.pessoaFormandoId = this.formando.id;
                if (this.formando.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formando.imagemId);
                    this.imagemService.createImage(blogImage, this.formando);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPessoaResponsavel(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorId(pessoaId);
                return response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFormasVida() {
        let buscaFormaVida = {
            grupoNome: "FORMA_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFormaVida)
            .then((lista) => {
            this.formasVida = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarNiveisFormacao() {
        let busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then((lista) => {
            this.niveisFormacao = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarPaises() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.paisService.findAll();
                this.paises = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstados(paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    paisId: paisId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000,
                };
                let response = yield this.estadoService.buscarEstados(request);
                this.estados = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCidades(estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    estadoId: estadoId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000,
                };
                let response = yield this.cidadeService.buscarCidades(request);
                this.cidades = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    logOnConsole(dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    }
    activateTab(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    }
    activateTab2(action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    }
    salvar() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.atualizarCaminhoFormativo();
            this.atualizarParecerResponsavel("INICIADO");
        });
    }
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log("Imagem carregada");
    }
    cropperReady() {
        console.log("Imagem cortada");
    }
    loadImageFailed() {
        console.log("Carregamento da imagem falhou!");
    }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.formando.id);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.pessoaFormadorPessoalSelecionado =
                        this.pessoaInfoBuilder.getInstance();
                    this.pessoaFormadorPessoalSelecionado.nome =
                        this.dadoVocacional.formadorPessoalPessoaNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    selecionarFormadorComunitario(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            //this.pessoaFormadorComunitarioSelecionado = pessoa;
            //$('#listagem-pessoa-formador-comunitario').modal('hide');
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadoFormandoCV() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFormandoCVService.porPessoaId(this.formando.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCV = response;
                    this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
                    this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(this.formando.id);
                if (response != null && response != undefined) {
                    this.caminhoFormativoFormando = response;
                    console.log(this.caminhoFormativoFormando);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.caminhoFormativoFormando.id != null) {
                    let response = yield this.caminhoFormativoService.atualizar(this.caminhoFormativoFormando);
                    this.caminhoFormativoFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRespostas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.respostaParecerService.porPessoaId(this.pessoaResponsavel.id);
                if (response != null && response != undefined) {
                    this.respostas = response.content;
                    console.log(this.respostas);
                    this.respostas.forEach((resposta) => {
                        switch (resposta.parecer_pergunta_id) {
                            case 1:
                                this.resposta1 = resposta;
                            case 2:
                                this.resposta2 = resposta;
                            case 3:
                                this.resposta3 = resposta;
                            case 4:
                                this.resposta4 = resposta;
                            case 5:
                                this.resposta5 = resposta;
                            case 6:
                                this.resposta6 = resposta;
                            case 7:
                                this.resposta7 = resposta;
                            case 8:
                                this.resposta8 = resposta;
                            case 9:
                                this.resposta9 = resposta;
                            case 10:
                                this.resposta10 = resposta;
                            case 11:
                                this.resposta11 = resposta;
                            case 12:
                                this.resposta12 = resposta;
                            case 13:
                                this.resposta13 = resposta;
                            case 14:
                                this.resposta14 = resposta;
                            case 15:
                                this.resposta15 = resposta;
                            case 16:
                                this.resposta16 = resposta;
                            case 17:
                                this.resposta17 = resposta;
                            case 18:
                                this.resposta18 = resposta;
                        }
                    });
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarParecerResponsavel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.parecerResponsavelService.buscarPorId(this.parecerResponsavelId);
                if (response != null && response != undefined) {
                    this.parecerResponsavel = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarioFormando() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorId(this.parecerResponsavel.questionarioFormandoId);
                if (response != null && response != undefined) {
                    this.questionarioFormando = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarResposta(resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                resposta.pessoaId = this.pessoaResponsavel.id;
                if (resposta.id == null) {
                    console.log(resposta);
                    let response = yield this.respostaParecerService.cadastrar(resposta);
                    resposta = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.respostaParecerService.atualizar(resposta.id, resposta);
                    resposta = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    salvarQuestionario() {
        this.resposta1.parecer_pergunta_id = 1;
        this.atualizarResposta(this.resposta1);
        this.resposta2.parecer_pergunta_id = 2;
        this.atualizarResposta(this.resposta2);
        this.resposta3.parecer_pergunta_id = 3;
        this.atualizarResposta(this.resposta3);
        this.resposta4.parecer_pergunta_id = 4;
        this.atualizarResposta(this.resposta4);
        this.resposta5.parecer_pergunta_id = 5;
        this.atualizarResposta(this.resposta5);
        this.resposta6.parecer_pergunta_id = 6;
        this.atualizarResposta(this.resposta6);
        this.resposta7.parecer_pergunta_id = 7;
        this.atualizarResposta(this.resposta7);
        this.resposta8.parecer_pergunta_id = 8;
        this.atualizarResposta(this.resposta8);
        this.resposta9.parecer_pergunta_id = 9;
        this.atualizarResposta(this.resposta9);
        this.resposta10.parecer_pergunta_id = 10;
        this.atualizarResposta(this.resposta10);
        this.resposta11.parecer_pergunta_id = 11;
        this.atualizarResposta(this.resposta11);
        this.resposta12.parecer_pergunta_id = 12;
        this.atualizarResposta(this.resposta12);
        this.resposta13.parecer_pergunta_id = 13;
        this.atualizarResposta(this.resposta13);
        this.resposta14.parecer_pergunta_id = 14;
        this.atualizarResposta(this.resposta14);
        this.resposta15.parecer_pergunta_id = 15;
        this.atualizarResposta(this.resposta15);
        this.resposta16.parecer_pergunta_id = 16;
        this.atualizarResposta(this.resposta16);
        this.resposta17.parecer_pergunta_id = 17;
        this.atualizarResposta(this.resposta17);
        this.resposta18.parecer_pergunta_id = 18;
        this.atualizarResposta(this.resposta18);
        this.atualizarParecerResponsavel("INICIADO");
    }
    enviarQuestionario() {
        this.salvarQuestionario();
        this.atualizarParecerResponsavel("ENVIADO");
        this.router.navigate(["secured/parecer-agradecimento"]);
    }
    atualizarParecerResponsavel(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.parecerResponsavel.id != null) {
                    this.parecerResponsavel.status = status;
                    if (status == "ENVIADO") {
                        this.parecerResponsavel.data_envio = new Date();
                    }
                    let response = yield this.parecerResponsavelService.atualizar(this.parecerResponsavel.id, this.parecerResponsavel);
                    this.parecerResponsavel = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
