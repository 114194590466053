import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CadastroPublicoBuilder } from 'src/app/builder/cadastropublico.builder';
export class CadastroPublicoComponent {
    constructor(termoUsoBuilder, termoTratamentoDadosBuilder, cadastroPublicoService, wopGetAllService, router, swtAlert2Service) {
        this.termoUsoBuilder = termoUsoBuilder;
        this.termoTratamentoDadosBuilder = termoTratamentoDadosBuilder;
        this.cadastroPublicoService = cadastroPublicoService;
        this.wopGetAllService = wopGetAllService;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.locations = [
            { name: 'Fortaleza', latitude: -3.7327141, longitude: -38.5269986 },
            { name: 'Juazeiro do Norte', latitude: -7.2429651, longitude: -39.3517942 },
            { name: 'Parnaíba', latitude: -2.9052581, longitude: -41.7759774 },
            { name: 'Cuiabá', latitude: -15.5989177, longitude: -56.0948941 },
            { name: 'Goiânia', latitude: -16.6868912, longitude: -49.2647943 },
            { name: 'Campina Grande', latitude: -7.2290753, longitude: -35.8801621 },
            { name: 'Garanhuns', latitude: -8.8903699, longitude: -36.4960527 },
            { name: 'Patos', latitude: -7.0176357, longitude: -37.2753105 },
            { name: 'Feira de Santana', latitude: -12.2577314, longitude: -38.9594383 },
            { name: 'Juazeiro da Bahia', latitude: -9.3966617, longitude: -40.5002195 },
            { name: 'Petrolina', latitude: -9.3856869, longitude: -40.4898112 },
            { name: 'Senhor do Bonfim', latitude: -10.4610879, longitude: -40.1861337 },
            { name: 'Chaves', latitude: -1.7142902, longitude: -49.5820479 },
            { name: 'Imperatriz', latitude: -5.5200398, longitude: -47.4778112 },
            { name: 'Macapá', latitude: 0.0382922, longitude: -51.0664393 },
            { name: 'Aparecida', latitude: -22.8494329, longitude: -45.2319618 },
            { name: 'Araraquara', latitude: -21.7845052, longitude: -48.1782561 },
            { name: 'Santo André', latitude: -23.6528707, longitude: -46.5386002 },
            { name: 'São Paulo', latitude: -23.5506507, longitude: -46.6333824 },
            { name: 'Belo Horizonte', latitude: -19.9166813, longitude: -43.9344931 },
            { name: 'Campos dos Goytacazes', latitude: -21.7623415, longitude: -41.3184864 },
            { name: 'Niterói', latitude: -22.8838963, longitude: -43.1033505 },
            { name: 'Juiz de Fora', latitude: -21.7641793, longitude: -43.3501558 },
            { name: 'Sobral', latitude: -3.6861847, longitude: -40.3507991 },
            { name: 'Natal', latitude: -5.7792569, longitude: -35.200916 },
            { name: 'Castelão', latitude: -3.7130483, longitude: -38.5324329 },
            { name: 'Ponta Grossa', latitude: -25.0916012, longitude: -50.1571693 },
            { name: 'Guarulhos', latitude: -23.4538327, longitude: -46.5333177 },
            { name: 'Santo Amaro', latitude: -23.6375, longitude: -46.5394 },
            { name: 'Curitiba', latitude: -25.4297, longitude: -49.2719 },
            { name: 'Joinville', latitude: -26.3044, longitude: -48.8467 },
            { name: 'Florianópolis', latitude: -27.5956, longitude: -48.5482 },
            { name: 'Vitória', latitude: -20.3194, longitude: -40.3378 },
            { name: 'Rio de Janeiro', latitude: -22.9035, longitude: -43.2096 },
            { name: 'Palmas', latitude: -10.2491, longitude: -48.3243 },
            { name: 'Cruzeiro do Sul', latitude: -7.6315, longitude: -72.6753 },
            { name: 'Maceió', latitude: -9.6658, longitude: -35.7353 },
            { name: 'Arapiraca', latitude: -9.7549, longitude: -36.6611 },
            { name: 'Manaus', latitude: -3.119, longitude: -60.0212 },
            { name: 'Salvador', latitude: -12.9704, longitude: -38.5124 },
            { name: 'Eunápolis', latitude: -16.3717, longitude: -39.5828 },
            { name: 'Vitória da Conquista', latitude: -14.8615, longitude: -40.8442 },
            { name: 'Itapipoca', latitude: -3.4997, longitude: -39.5747 },
            { name: 'Crateús', latitude: -5.1672, longitude: -40.6667 },
            { name: 'Aracati', latitude: -4.5628, longitude: -37.7678 },
            { name: 'Quixadá', latitude: -4.9667, longitude: -39.0167 },
            { name: 'Brasília', latitude: -15.7939, longitude: -47.8828 },
            { name: 'São Luis', latitude: -2.5387, longitude: -44.2825 },
            { name: 'Campo Grande', latitude: -20.4435, longitude: -54.6478 },
            { name: 'Belém', latitude: -1.4558, longitude: -48.5039 },
            { name: 'João Pessoa', latitude: -7.1195, longitude: -34.845 },
            { name: 'Recife', latitude: -8.0522, longitude: -34.9286 },
            { name: 'Teresina', latitude: -5.0919, longitude: -42.8034 },
            { name: 'Mossoró', latitude: -5.1833, longitude: -37.3444 },
            { name: 'Aracaju', latitude: -10.9091, longitude: -37.0677 },
            { name: 'Propriá', latitude: -10.2158, longitude: -36.8442 },
            { name: 'Fortaleza - Reg. Aldeota', latitude: -3.7340358811219105, longitude: -38.501369603615416 },
            { name: 'Fortaleza - Reg. Parangaba', latitude: -3.7739331752956344, longitude: -38.569831368675004 },
            { name: 'Fortaleza - Reg. Pacajus', latitude: -4.174844133170093, longitude: -38.46349791738701 },
            { name: 'Fortaleza - Reg. Fátima', latitude: -3.7494561516720855, longitude: -38.52592721112724 },
            { name: 'Fortaleza - Reg. Cidade', latitude: -3.7959260803490458, longitude: -38.4957810948576 },
            { name: 'Fortaleza - Reg. Parquelandia', latitude: -3.7345187328268836, longitude: -38.5546068612861 },
        ];
        this.locationsDifusao = [
            { name: 'Bogotá', latitude: 4.710989, longitude: -74.072092 },
            { name: 'Barranquilla', latitude: 10.963889, longitude: -74.796389 },
            { name: 'Dublin', latitude: 53.349805, longitude: -6.26031 },
            { name: 'Foz de Iguaçu', latitude: -25.5469, longitude: -54.5882 },
            { name: 'Guarapuava', latitude: -25.3907, longitude: -51.4628 },
            { name: 'Londrina', latitude: -23.3044524, longitude: -51.1695824 },
            { name: 'Maringá', latitude: -23.4240829, longitude: -51.9375118 },
            { name: 'Porto Alegre', latitude: -30.0277041, longitude: -51.2287346 },
            { name: 'São Leopoldo', latitude: -29.7681908, longitude: -51.1496059 },
            { name: 'Águas Lindas', latitude: -15.75389, longitude: -48.2775 },
            { name: 'Patos de Minas', latitude: -18.5767, longitude: -46.5181 },
            { name: 'Carmo do Parnaíba', latitude: -21.4794, longitude: -43.1283 },
            { name: 'Curvelo', latitude: -18.7569, longitude: -44.4308 },
            { name: 'Montes Claros', latitude: -16.735, longitude: -43.8614 },
            { name: 'Poços de Caldas', latitude: -21.7872, longitude: -46.5614 },
            { name: 'Uberaba', latitude: -19.7473, longitude: -47.9381 },
            { name: 'Teófilo Otoni', latitude: -17.8594, longitude: -41.5089 },
            { name: 'Bebedouro', latitude: -20.9492, longitude: -48.4792 },
            { name: 'Franca', latitude: -20.5386, longitude: -47.4003 },
            { name: 'Santos', latitude: -23.9561, longitude: -46.3331 },
            { name: 'São José dos Campos', latitude: -23.1791, longitude: -45.8872 },
            { name: 'Barretos', latitude: -20.5575, longitude: -48.5675 },
            { name: 'Presidente Prudente', latitude: -22.1231, longitude: -51.39 },
            { name: 'Taquaritinga', latitude: -21.4064, longitude: -48.5078 },
        ];
        this.locationsNearest = [
            { name: '', latitude: 0, longitude: 0 },
        ];
        this.cadastroPublicoBuilder = new CadastroPublicoBuilder();
        this.paises = new Array();
        this.missoesVinculo = new Array();
        this.difusoesVinculo = new Array();
        this.termoVigente = this.termoUsoBuilder.getInstance();
        this.termoTratamentoDadosVigente = this.termoTratamentoDadosBuilder.getInstance();
        this.locationFlag = false;
    }
    ngOnInit() {
        this.cadastroPublico = this.cadastroPublicoBuilder.getInstance();
        this.carregarFormasVida();
        this.carregarNiveisFormacao();
        this.carregarPaises();
        this.carregarTermoVigente();
        this.carregarTermoTratamentoDadosVigente();
        this.carregarMissoesVinculo();
        this.carregarDifusoesVinculo();
        this.getLocation();
        this.cadastroPublico.formaVidaId = 16;
    }
    carregarFormasVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'FORMA_VIDA'
            };
            try {
                let response = yield this.cadastroPublicoService.elementosGrupoDominioPorNomeGrupo(request);
                this.formasVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarNiveisFormacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'NIVEL_FORMACAO'
            };
            try {
                let response = yield this.cadastroPublicoService.elementosGrupoDominioPorNomeGrupo(request);
                this.niveisFormacao = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPaises() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllService.getAllPais();
                this.paises = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarTermoVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoVigente();
                this.termoVigente = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarTermoTratamentoDadosVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoTratamentoDadosVigente();
                this.termoTratamentoDadosVigente = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllService.getAllMissao();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
                this.missoesLocation = this.missoesVinculo;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarDifusoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllService.getAllDifusao();
                this.difusoesVinculo = response.entity;
                this.difusoesVinculo = this.difusoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
                this.difusoesLocation = this.difusoesVinculo;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    cadastrar(cadastro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let cadastroValido = true;
            let retorno = null;
            try {
                retorno = this.usuarioValido(cadastro.username);
                if (retorno.valido == false) {
                    cadastroValido = retorno.valido;
                    this.swtAlert2Service.warningAlert(retorno.mensagem);
                    return;
                }
                retorno = this.confirmacaoSenhaValida(cadastro.password, cadastro.confPassword);
                if (retorno.valido == false) {
                    cadastroValido = retorno.valido;
                    this.swtAlert2Service.warningAlert(retorno.mensagem);
                    return;
                }
                retorno = this.missaoDifusaoValida(cadastro.missaoAtualId, cadastro.difusaoAtualId);
                if (retorno.valido == false) {
                    cadastroValido = retorno.valido;
                    this.swtAlert2Service.warningAlert(retorno.mensagem);
                    return;
                }
                if (cadastro.email == null
                    ||
                        cadastro.email == ''
                    ||
                        (this.termoVigente != null && cadastro.aceiteTermo == false)
                    ||
                        (this.termoTratamentoDadosVigente != null && cadastro.aceiteTermoTratamentoDados == false)) {
                    cadastroValido = false;
                }
                if (cadastroValido == true) {
                    let response = yield this.cadastroPublicoService.cadastrar(cadastro);
                    this.cadastroPublico = this.cadastroPublicoBuilder.getInstance();
                    this.router.navigate(['/', 'confirmacaoCadastro']);
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    usuarioValido(usuario) {
        let retorno = {
            valido: true,
            mensagem: ''
        };
        //Usuario contem espaço?
        if (usuario.split(' ').length > 1) {
            retorno.valido = false;
            retorno.mensagem = "Seu nome de usuário não pode conter espaços.";
            return retorno;
        }
        //Valida caracteres
        if (usuario.length > 20) {
            retorno.valido = false;
            retorno.mensagem = "Seu nome de usuário só pode conter 20 caracteres.";
            return retorno;
        }
        /*         //Valida caps
                if(/[A-Z]/.test(usuario)==true)
                {
                    retorno.valido = false;
                    retorno.mensagem = "Seu nome de usuário só pode conter letras minúsculas e números.";
                    return retorno;
                } */
        if (this.isUserNameValid(usuario) == false) {
            retorno.valido = false;
            retorno.mensagem = "Seu nome de usuário só pode conter letras minúsculas e números.";
            return retorno;
        }
        return retorno;
    }
    isUserNameValid(username) {
        /*
          Usernames can only have:
          - Lowercase Letters (a-z)
          - Numbers (0-9)
          - Dots (.)
          - Underscores (_)
        */
        const res = /^[a-z0-9._@]+$/.exec(username);
        const valid = !!res;
        return valid;
    }
    missaoDifusaoValida(missaoAtualId, difusaoAtualId) {
        let retorno = {
            valido: true,
            mensagem: ''
        };
        //Ambos foram selecionados?
        if (missaoAtualId != null && difusaoAtualId != null) {
            retorno.valido = false;
            retorno.mensagem = "Você deve selecionar somente uma das opções, ou sua missão atual ou sua difusão atual, não ambas.";
            return retorno;
        }
        //Nenhum foi selecionado
        if (missaoAtualId == null && difusaoAtualId == null) {
            retorno.valido = false;
            retorno.mensagem = "Você deve selecionar ou sua missão atual ou sua difusão atual.";
            return retorno;
        }
        return retorno;
    }
    confirmacaoSenhaValida(senha, confirmacaoSenha) {
        let retorno = {
            valido: true,
            mensagem: ''
        };
        if (senha != confirmacaoSenha) {
            retorno.valido = false;
            retorno.mensagem = "Campos Senha e Confirmação de Senha não conferem.";
            return retorno;
        }
        return retorno;
    }
    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position && this.locationFlag === false) {
                    console.log("Missão Latitude: " + position.coords.latitude +
                        " Longitude: " + position.coords.longitude);
                    this.userLatitude = position.coords.latitude;
                    this.userLongitude = position.coords.longitude;
                    this.locations.sort((a, b) => {
                        const distanceA = this.calculateDistance(this.userLatitude, this.userLongitude, a.latitude, a.longitude);
                        const distanceB = this.calculateDistance(this.userLatitude, this.userLongitude, b.latitude, b.longitude);
                        return distanceA - distanceB;
                    });
                    this.locationsNearest[0] = this.locations[0];
                    this.locationsDifusao.sort((a, b) => {
                        const distanceA = this.calculateDistance(this.userLatitude, this.userLongitude, a.latitude, a.longitude);
                        const distanceB = this.calculateDistance(this.userLatitude, this.userLongitude, b.latitude, b.longitude);
                        return distanceA - distanceB;
                    });
                    this.locationsNearest[1] = this.locationsDifusao[0];
                    this.locationsNearest.sort((a, b) => {
                        const distanceA = this.calculateDistance(this.userLatitude, this.userLongitude, a.latitude, a.longitude);
                        const distanceB = this.calculateDistance(this.userLatitude, this.userLongitude, b.latitude, b.longitude);
                        return distanceA - distanceB;
                    });
                    setTimeout(() => {
                        // The closest location is now the first item in the locations array
                        if (this.locationsNearest[0] == this.locationsNearest[1]) {
                            for (let i = 0; i < this.locationsDifusao.length; i++) {
                                if (this.difusoesVinculo[i].nome == this.locationsNearest[0].name) {
                                    this.nomeDifusaoMaisPertoId = i;
                                    this.cadastroPublico.difusaoAtualId = this.difusoesVinculo[this.nomeDifusaoMaisPertoId].id;
                                    this.locationFlag = true;
                                }
                            }
                        }
                        else {
                            for (let j = 0; j < this.locations.length; j++) {
                                if (this.missoesVinculo[j].nome == this.locationsNearest[0].name) {
                                    this.nomeMissaoMaispertoId = j;
                                    this.cadastroPublico.missaoAtualId = this.missoesVinculo[this.nomeMissaoMaispertoId].id;
                                    this.locationFlag = true;
                                }
                            }
                        }
                    }, 500);
                }
            }, (error) => console.log(error));
        }
        else {
            alert("A Geolocalização não é suportada pelo seu navegador.");
        }
    }
    calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // r da terra
        const dLat = this.toRad(lat2 - lat1);
        const dLon = this.toRad(lon2 - lon1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // distancia em km
        return distance;
    }
    toRad(value) {
        return value * Math.PI / 180;
    }
}
