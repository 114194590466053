import { AbstractBuilder } from './abstract.builder';
import { Injectable } from '@angular/core';
import { CriarLinkCadastroRequest } from '../request/criarlinkcadastro.request';


@Injectable()
export class CriarLinkCadastroRequestBuilder extends AbstractBuilder<CriarLinkCadastroRequest> {

    reset() : void {
        this.entity = new CriarLinkCadastroRequest(null,null,null);
    }

}