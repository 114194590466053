export class QuestionarioFormando {

    constructor(
        public id: number,
        public pessoaId: number,
        public questionarioId: number,
        public anoFormativoId: number,
        public status: string,
        public data_envio: Date,
        public indicacaoFC: number,
        public justificativaFC: string,
        public indicacaoCD: number,
        public justificativaCD: string,
        public indicacaoCL: number,
        public justificativaCL: string,
        public indicacaoGG: number,
        public justificativaGG: string,
        public isDiscernimentoGG: boolean,
        public consideracaoConcorda: boolean,
        public caminhoFormativoFeedback: string,
        public caminhoFormativoSugeridoFC: string,
        public consideracaoCL: string,
        public corStatusVocacionalId: number
    ){}

}