/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./header.component";
import * as i2 from "../../../login/service/login.service";
var styles_HeaderComponent = [];
var RenderType_HeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "nav", [["class", "main-header navbar navbar-expand navbar-light navbar-orange border-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["class", "nav-link"], ["data-widget", "pushmenu"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-bars"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "ul", [["class", "navbar-nav ml-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "li", [["class", "nav-item d-none d-sm-inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [["class", "nav-link cursor-pointer color_white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sair"]))], null, null); }
export function View_HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "header-comp", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.HeaderComponent, [i2.LoginService], null, null)], null, null); }
var HeaderComponentNgFactory = i0.ɵccf("header-comp", i1.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
