import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ColegiadoComponent } from "./colegiado.component";
import { ColegiadoRoutesModule } from "./colegiado.routing";
import { SecuredModule } from "../../layout/secured.module";

@NgModule({
  imports: [CommonModule, ColegiadoRoutesModule, SecuredModule],
  declarations: [ColegiadoComponent],
})
export class ColegiadoModule {}
