import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { Cidade } from 'src/app/model/cidade.model';
import { Celula } from 'src/app/model/celula.model';
import { CelulaService } from './service/celula.service';
import { CelulaBuilder } from 'src/app/builder/celula.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CentroEvangelizacaoService } from '../../service/centroevangelizacao.service';
import { ElementoGrupoDominioService } from '../../service/elementogrupodominio.service';
import { RegionalService } from '../../service/regional.service';
import { MissaoService } from '../../service/missao.service';
import { PaisService } from '../../service/pais.service';
import { EstadoService } from '../../service/estado.service';
import { CidadeService } from '../../service/cidade.service';

@Component({
    selector: 'celulaedit',
    templateUrl: './celula.component.html'
})
export class CelulaEditComponent implements OnInit {

    public celula: Celula;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public fases : Array<ElementoGrupoDominio>;
    public missoes : Array<Missao>;
    public regionalSelecionadoId : number;
    public missaoSelecionadoId : number;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public celulaId: number;
    public centroEvangelizacaoId: number;
    public celulaBuilder: CelulaBuilder;

    constructor(
        public celulaService: CelulaService,
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.celulaBuilder = new CelulaBuilder();
        this.celula = this.celulaBuilder.getInstance();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
        this.fases = new Array<ElementoGrupoDominio>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
    }

    ngOnInit() { 
        this.celulaId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarNiveisDaCelula(); 
        this.regionalSelecionadoId = null;
        this.carregarCelula(this.celulaId);
        
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            console.log(lista);
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarMissoes(regionalSelecionadoId: number): void {
        this.missaoService.porRegional(regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarCev(missaoSelecionadoId: number): void {
        this.centroEvangelizacaoService.porMissao(missaoSelecionadoId).then((response: any) => {
             this.centrosEvangelizacao = response.entity;          
         }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
         });;
     } 

     carregarNiveisDaCelula() : void {

        let buscaFase = {
            grupoNome : 'FASE_CELULA'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fases = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }


    carregarCelula(id : number): void {
        this.celulaService.find(id).then((response: any) => {
            this.celula = response.entity;   
            this.regionalSelecionadoId = response.entity.missaoRegionalId;
            this.carregarRegionais();
            this.carregarMissoes(this.celula.regionalId);
            this.carregarCev(this.celula.missaoId);
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    salvar(celula: Celula) : void {

        this.celulaService.update(celula.id, celula).then( (response:any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/celulas']);
            }, 2000);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    } 

}