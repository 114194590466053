import { NgModule } from '@angular/core';
import { GenericService } from 'src/app/core/services/generic.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UsuarioService } from '../../component/usuario/service/usuario.service';
import { LeftSidebarFormadorComunitarioCasaComponent } from './left-sidebar-formador-comunitario-casa.component';

@NgModule({
    declarations: [ 
        LeftSidebarFormadorComunitarioCasaComponent
    ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [
        LeftSidebarFormadorComunitarioCasaComponent
    ],
    providers: [
        UsuarioService,
        GenericService
    ]
})
export class LeftSidebarFormadorComunitarioCasaModule {

}