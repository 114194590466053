import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SecuredComponent } from "./secured.component";

const securedRoutes: Routes = [
  {
    path: "secured",
    component: SecuredComponent,
  },
  {
    path: "secured/celula/cc/meucolegiado",
    loadChildren: () =>
      import("../component/colegiado/colegiado.module").then(
        (module) => module.ColegiadoModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(securedRoutes)],
  exports: [RouterModule],
})
export class SecuredRoutingModule {}
