import { GenericService } from "src/app/core/services/generic.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class DiscernimentoService extends GenericService<any> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("discernimentos");
  }

  obterMembrosParaDiscernimentoDoResponsavelLocal(
    page: number,
    size: number
  ): Promise<any> {
    let url = `${environment.moduloFormacao.urlModuloFormacao}discernimentos/responsavellocal/listar/membros?page=${page}&size=${size}`;
    return this.http.get<any>(url, { headers: this.getHeaders() }).toPromise();
  }

  obterMembrosParaDiscernimentoDoCoordenadorDeColegiado(
    page: number,
    size: number
  ): Promise<any> {
    let url = `${environment.moduloFormacao.urlModuloFormacao}discernimentos/coordenadorcolegiado/listar/membros?page=${page}&size=${size}`;
    return this.http.get<any>(url, { headers: this.getHeaders() }).toPromise();
  }
}
