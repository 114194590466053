import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Celula } from 'src/app/model/celula.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class CelulaService extends GenericService<Celula> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/celula');
    }    

    buscarCelulas(busca: any) : Promise<any> {
        //return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
        return this.http.post<any>(environment.moduloFormacao.buscarCelula, JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    getAllNotInAcessoUsuarioEstrutura(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/getallnotinacessousuarioestrutura/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    getAllFromAcessoUsuarioEstrutura(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/getallfromacessousuarioestrutura/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    async criarLinkCadastroCelula(request: any) : Promise<any> {
        return this.http.post<any>(`${environment.moduloFormacao.urlModuloFormacao}celula/criarlinkcadastro`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    async obterLinkCadastroCelula(id: number): Promise<any> {
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}celula/obterlinkcadastro/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }
    
    async unirCelulas(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/unircelulas', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    /**
     * Formadores Comunitários
     */

    cadastrarFormadorComunitario(data: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/cadastrar/formadorcomunitario`, JSON.stringify(data), { 'headers' : this.getHeaders() }).toPromise();
    }

    listarFormadorComunitarioPorCelulaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/${id}/formadorescomunitarios`, { 'headers' : this.getHeaders() }).toPromise();
    }

    deletarFormadorComunitario(id: number): Promise<any> {
        return this.http.delete<any>(`${this.apiURL}/deletar/formadorcomunitario/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    atualizarFormadorComunitario(data: any) : Promise<any> {
        return this.http.put<any>(`${this.apiURL}/atualizar/formadorcomunitario`, JSON.stringify(data), { 'headers' : this.getHeaders() }).toPromise();
    }

     /**
     * Formadores Assistentes
     */

    cadastrarFormadorAssistente(data: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/cadastrar/formadorassistente`, JSON.stringify(data), { 'headers' : this.getHeaders() }).toPromise();
    }

    listarFormadorAssistentePorCelulaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/${id}/formadoresassistentes`, { 'headers' : this.getHeaders() }).toPromise();
    }

    deletarFormadorAssistente(id: number): Promise<any> {
        return this.http.delete<any>(`${this.apiURL}/deletar/formadorassistente/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    atualizarFormadorAssistente(data: any) : Promise<any> {
        return this.http.put<any>(`${this.apiURL}/atualizar/formadorassistente`, JSON.stringify(data), { 'headers' : this.getHeaders() }).toPromise();
    }

} 