import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Login } from "../model/login.model";
export class LoginComponent {
    constructor(esqueceuSenhaService, loginService, termoUsoBuilder, termoTratamentoDadosBuilder, cadastroPublicoService, swtAlert2Service) {
        this.esqueceuSenhaService = esqueceuSenhaService;
        this.loginService = loginService;
        this.termoUsoBuilder = termoUsoBuilder;
        this.termoTratamentoDadosBuilder = termoTratamentoDadosBuilder;
        this.cadastroPublicoService = cadastroPublicoService;
        this.swtAlert2Service = swtAlert2Service;
        this.passwordFieldType = "password";
        this.termoVigente = this.termoUsoBuilder.getInstance();
        this.termoTratamentoDadosVigente =
            this.termoTratamentoDadosBuilder.getInstance();
    }
    ngOnInit() {
        this.login = new Login("", "");
        this.carregarTermoVigente();
        this.carregarTermoTratamentoDadosVigente();
    }
    fazerLogin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.login.username = this.login.username.trim();
            this.loginService.fazerLogin(this.login);
        });
    }
    togglePasswordVisibility() {
        this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password";
    }
    recuperarSenha() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.emailRecuperacao != "" &&
                this.emailRecuperacao != undefined &&
                this.emailRecuperacao != null) {
                try {
                    let response = yield this.esqueceuSenhaService.esqueceuSenha(this.emailRecuperacao);
                    this.swtAlert2Service.infoAlert(response.message);
                    $("#modal-esqueceu-senha").modal("hide");
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            else {
                this.swtAlert2Service.warningAlert("Informe o e-mail de recuperação!");
            }
        });
    }
    carregarTermoVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoVigente();
                this.termoVigente = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarTermoTratamentoDadosVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoTratamentoDadosVigente();
                this.termoTratamentoDadosVigente = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
}
