<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>1º Questionario do D2 - CV</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-questionario-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill" href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab" aria-selected="true">Dados Pessoais</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="questionario-tab" data-toggle="pill" href="#questionario-tab-content" role="tab" aria-controls="questionario-tab" aria-selected="false">Questionário</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel" aria-labelledby="dados-pessoais-tab">
                            <form #dadosPessoaisForm="ngForm">
                                <div class="row">
                                    <div *ngIf="(pessoa.id != undefined)" class="col-sm-3 col-md-3 col-lg-3">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="text-center" style="margin: 1rem auto 2rem;">
                                                        <div class="widget-user-image">
                                                            <img class="profile-user-img img-fluid img-circle elevation-2" [src]="pessoa.thumbnail == null ? 'assets/images/ppl.png' : pessoa.thumbnail" alt="Imagem da pessoa" style="width: auto;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div [ngClass]="(pessoa.id != undefined) ? 'col-sm-8 col-md-8 col-lg-8' : 'col-sm-12 col-md-12 col-lg-12'">
                                        <div style="padding: 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_nome">Nome</label>
                                                                {{pessoa.nome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Sobrenome</label>
                                                                {{pessoa.sobrenome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="pais">País de Origem</label>
                                                                <select disabled class="form-control" id="pais"
                                                                    [(ngModel)]="dadoFormandoCV.pais_origem_id"
                                                                    (change)="carregarEstados(dadoFormandoCV.pais_origem_id)"
                                                                    name="pais" required>
                                                                    <option [value]="null" i18n>
                                                                        Selecione um País</option>
                                                                    <option *ngFor="let pais of paises"
                                                                        [value]="pais.id">{{pais.nome}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nestado">Estado de Origem</label>
                                                                <select disabled class="form-control"
                                                                    id="nestado"
                                                                    [(ngModel)]="dadoFormandoCV.estado_origem_id"
                                                                    (change)="carregarCidades(dadoFormandoCV.estado_origem_id)"
                                                                    name="nestado" required>
                                                                    <option [value]="null" i18n>
                                                                        Selecione um Estado</option>
                                                                    <option
                                                                        *ngFor="let estado of estados"
                                                                        [value]="estado.id">
                                                                        {{estado.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="ncidade_origem_id">Cidade de Origem</label>
                                                                <select  disabled class="form-control"
                                                                    id="ncidade_origem_id"
                                                                    [(ngModel)]="dadoFormandoCV.cidade_origem_id"
                                                                    name="ncidade_origem_id" required>
                                                                    <option [value]="null" i18n>
                                                                        Selecione uma Cidade</option>
                                                                    <option
                                                                        *ngFor="let cidade of cidades"
                                                                        [value]="cidade.id">
                                                                        {{cidade.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="cidade_nao_cadastrada">Se
                                                                    sua cidade não estiver cadastrada,
                                                                    escreva abaixo o País, Estado e
                                                                    Cidade</label>
                                                                {{dadoFormandoCV.cidade_nao_cadastrada}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_missao_atual">Missão Atual</label>
                                                                <select disabled class="form-control" id="npessoa_missao_atual" [(ngModel)]="pessoa.vinculoMissao.missaoAtualId" name="npessoa_missao_atual">
                                                                    <option disabled value="null" i18n>Selecione uma missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo" [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="ndiscipulado_origem_id">Discipulado de Origem</label>
                                                                <select disabled class="form-control" id="ndiscipulado_origem_id" [(ngModel)]="dadoFormandoCV.discipulado_origem_id" name="ndiscipulado_origem_id" required>
                                                                    <option value="null" i18n>Selecione uma discipulado</option>
                                                                    <option value="123">Disc. Esposa do Espírito - Pacajus</option>
                                                                    <option value="122">Disc. Porta do Céu - Eusébio</option>
                                                                    <option value="121">Disc. Rainha da Paz - Quixadá</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_dtnas">Data de Nascimento</label>
                                                                {{pessoa.dataNascimento}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            &nbsp;
                                                        </div>                                                    
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="form_pessoal">Formador Pessoal</label>
                                                                {{pessoaFormadorPessoalSelecionado.nome}} {{pessoaFormadorPessoalSelecionado.sobrenome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nform_pessoais">Nº Formações Pessoais</label>
                                                                {{caminhoFormativo.qtde_formacao_pessoal}}
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Formador Comunitário</label>
                                                                {{caminhoFormativo.nome_formador_comunitario}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Nº de Acompanhamentos Comunitários</label>
                                                                {{caminhoFormativo.qtde_acompanhamento_comunitario}}
                                                            </div>
                                                        </div>
                                                        <!--div class="col-12">
                                                            <div class="form-group">
                                                                <label i18n for="button-add-pessoa">&nbsp;</label>
                                                                <button name="button-add-pessoa" data-toggle="modal"
                                                                    data-target="#listagem-pessoa-formador-pessoal"
                                                                    style="cursor: pointer" class="btn btn-primary"
                                                                    i18n>Selecionar Formador Comunitario</button>
                                                            </div>
                                                        </div-->
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Exerce função de autoridade em casa? Qual?</label>
                                                                {{caminhoFormativo.funcao_autoridade_casa}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Exerce função de autoridade apostolica? Qual?</label>
                                                                {{caminhoFormativo.funcao_autoridade_apostolica}}
                                                            </div>
                                                        </div>                                                         
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="button" class="btn btn-default" (click)="activateTab('questionario-tab-content')" >Proximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </form>
                        </div>
                        <div class="tab-pane fade" id="questionario-tab-content" role="tabpanel" aria-labelledby="questionario-tab">
                            <form *ngIf="pessoa.id != undefined" #questionarioForm="ngForm" novalidate>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div style="padding: 1rem 1rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <h4 class="card-title">A partir de agora, responda com sinceridade e transparência as seguintes perguntas. Seja sincero
                                                                consigo mesmo(a), mas seja livre, partilhe apenas aquilo que julgar que deve. Se existe algo mais a
                                                                expressar e que você não queira fazer por aqui, pode buscar o teu FC ou a Formação Inicial.
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <h3 class="card-title">1. Fale-nos sobre os seguintes aspectos:</h3>
                                                        </div>
                                                    </div>                                            
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">a) Tua vida de intimidade com Deus (zelo e compromisso com sua vida de oração,
                                                                    respeito pelo sagrado, silêncio da manhã, oração comunitária, eucaristia diária, terço
                                                                    diário etc.):</label>
                                                                {{resposta1.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">b) Tua vivência apostólica (zelo apostólico e ardor evangelizador, acolhimento e
                                                                    responsabilidade dos apostolados confiados, relacionamentos com os irmãos da obra e
                                                                    da Comunidade de Aliança, desafios etc.):</label>
                                                                {{resposta2.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">c) Quais ministérios e apostolados você assumiu na tua missão:</label>
                                                                {{resposta3.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">d) Como você tem vivido as exigências apostólicas em relação à primazia da vida de
                                                                    oração:</label>
                                                                    {{resposta4.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">e) Tua vida fraterna (capacidade de diálogo, relacionamentos dentro da casa
                                                                    comunitária, capacidade de amar e sacrificar-se em vista do outro):</label>
                                                                    {{resposta5.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">f) Quanto aos Conselhos Evangélicos, partilhe as graças e desafios vividos em cada um:</label>
                                                                {{resposta6.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <div class="checkbox icheck-concrete">
                                                                    <input disabled [(ngModel)]="resposta7.resposta" type="checkbox" id="resposta7" name="resposta7"
                                                                         />&nbsp;
                                                                    <label class="form-check-label" for="hasCelibato">Renovou o celibato formativo?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 col-lg-8">
                                                            <div class="form-group">        
                                                                <label for="descricao">Partilhe sobre este tempo de celibato formativo, aquilo que você
                                                                    julgar importante:</label>
                                                                    {{resposta8.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">h) Você acredita que as formações pessoais e acompanhamentos comunitários têm
                                                                    sido proveitosos? Partilhe.
                                                                    </label>
                                                                    {{resposta9.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">2. Como você avalia seu relacionamento com suas autoridades (Responsável Local,
                                                                    Formador Comunitário, Coordenador de Residência e de Apostolado)?</label>
                                                                    {{resposta10.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">3. Como você acredita que tem compreendido este caminho de preparação para as
                                                                    Primeiras Promessas, você percebe qual a via que o Senhor tem te conduzido?
                                                                    </label>
                                                                    {{resposta11.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">4. Em que dimensão você gostaria de ser mais ajudado(a) neste tempo de preparação
                                                                    para a consagração?
                                                                    </label>
                                                                    {{resposta12.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">5. Como você acredita estar vivendo o caminho de discernimento do seu estado de
                                                                    vida?
                                                                    </label>
                                                                    {{resposta13.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">6. Você tem acompanhado a formação específica deste tempo formativo? Se você
                                                                    estiver atrasado, Como pretende colocá-las em dia?                                                            
                                                                    </label>
                                                                    {{resposta14.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">7. Você crê que este tempo de D2 tem sido um caminhar cada vez mais firme rumo ao
                                                                    definitivo como Comunidade de Vida? Partilhe sobre isso.                                                            
                                                                    </label>
                                                                    {{resposta15.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">8. Em vista do compromisso que vai assumir com a vida consagrada, existe alguma
                                                                    realidade que precisa ser resolvida, como: alguma dívida em aberto, cartão de crédito
                                                                    ou conta bancária ainda em seu nome?                                                            
                                                                    </label>
                                                                    {{resposta16.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">9. Como você percebe a qualidade da sua saúde física e emocional? Tem alguma
                                                                    realidade de saúde que exija tratamento?
                                                                    </label>
                                                                    {{resposta17.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">10. Existe algum fato específico, questão familiar ou algo mais que você julga
                                                                    importante partilhar?
                                                                    </label>
                                                                    {{resposta18.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">        
                                                                <label for="descricao">11. Este espaço é para você partilhar livremente sobre este tempo de segundo ano de
                                                                    discipulado.
                                                                    </label>
                                                                    {{resposta19.resposta}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <div class="checkbox icheck-concrete">
                                                                    <input disabled [(ngModel)]="resposta20.resposta" type="checkbox" id="resposta20" name="resposta20"
                                                                         />&nbsp;
                                                                    <label class="form-check-label" for="resposta20">Sente-se bem seguro com que foi partilhado?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="button" class="btn btn-default float-left" style="margin-left: 0.5rem;" (click)="activateTab('dados-pessoais-tab-content')" i18n>Voltar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>                            
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="modal fade" id="modal-pessoa-imagem" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Selecione a imagem</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4">
                            <div class="card text-center">
                                <div class="card-title">
                                    Preview
                                </div>
                                <div class="card-body">
                                    <img class="img-circle elevation-2" [src]="(croppedImage=='' ) ? 'assets/images/ppl.png' : croppedImage" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-8">
                            <div class="card text-center">
                                <div class="card-title">
                                    Recortar Imagem
                                </div>
                                <div class="card-body" style="min-height: 242px;">
                                    <image-cropper style="max-height: 300px;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="200" format="png" (imageCropped)="imageCropped($event)
                                                                                   " (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 5%;">
                        <div class="text-center col-md-12">
                            <div class="box">
                                <input style="display:none;" (change)="fileChangeEvent($event)" type="file" name="file-3[]" id="file-3" class="inputfile inputfile-3">
                                <label for="file-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6
                                                                                    0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                </svg> 
                                <span>Clique para selecione a imagem</span></label>
                            </div>
                        </div>
                        <label class="custom-file" id="customFile">
                            <input type="file" (change)="fileChangeEvent($event)" class="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp">
                            <span class="custom-file-control form-control-file"></span>
                        </label>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" (click)="salvarImagem()" class="btn btn-primary">Salvar Imagem</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-formador-pessoal'" 
        [filtrosExtras]="['missao']" 
        [filterByUserAccess]="false"
        [onlyMissionaries]="true"
        [modalTitle]="'Selecione o seu formador(a) pessoal'" 
        [altButtonText]="'Selecionar Formador(a)'"
        [buttonText]="'Selecionar'"             
        (eventEmitter)="selecionarFormadorPessoal($event)">
        </listagem-pessoainfo-modal-component>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-formador-comunitario'" 
        [filtrosExtras]="['missao']" 
        [filterByUserAccess]="false"
        [onlyMissionaries]="true"
        [modalTitle]="'Selecione o seu formador(a) comunitário(a)'" 
        [altButtonText]="'Selecionar Formador(a)'"
        [buttonText]="'Selecionar'"             
        (eventEmitter)="selecionarFormadorComunitario($event)">
        </listagem-pessoainfo-modal-component>
    </div>
</secured>