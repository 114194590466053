import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CacheService {
  private cache: any = {};

  // Salva os dados no cache com uma chave específica
  set(key: string, data: any) {
    this.cache[key] = data;
  }

  // Recupera os dados do cache com base na chave
  get(key: string): any {
    return this.cache[key];
  }

  // Verifica se há dados no cache
  has(key: string): boolean {
    return this.cache.hasOwnProperty(key);
  }

  // Limpa o cache (se precisar)
  clear() {
    this.cache = {};
  }
}
