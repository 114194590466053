import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { UsuarioBuilder } from "src/app/builder/usuario.builder";
export class LeftSidebarFormadorAssistenteComponent {
    constructor(usuarioService, pessoaService, imagemService, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.swtAlert2Service = swtAlert2Service;
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuarioAtual = this.usuarioBuilder.getInstance();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaAtual = this.pessoaBuilder.getInstance();
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.usuarioAtual = JSON.parse(sessionStorage.getItem("usuarioAtual"));
            if (this.usuarioAtual == undefined || this.usuarioAtual == null) {
                try {
                    let response = yield this.usuarioService.getCurrentUser();
                    this.usuarioAtual = response.entity;
                    sessionStorage.setItem("usuarioAtual", JSON.stringify(this.usuarioAtual));
                }
                catch (e) {
                    console.log(e);
                }
            }
            yield this.getPessoaAtual();
        });
    }
    getPessoaAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.pessoaAtual = JSON.parse(sessionStorage.getItem("pessoaAtual"));
            try {
                if (this.pessoaAtual == undefined || this.pessoaAtual == null) {
                    let response = yield this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);
                    this.pessoaAtual = response.entity;
                    sessionStorage.setItem("pessoaAtual", JSON.stringify(this.pessoaAtual));
                }
                if (this.pessoaAtual.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoaAtual.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoaAtual);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    exibirParaRole(regras) {
        let retorno = false;
        if (this.usuarioAtual &&
            regras.includes(this.usuarioAtual.autorizacoesNome[0])) {
            retorno = true;
        }
        return retorno;
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getUsuarioAtual();
        });
    }
    ngOnInit() {
        this.initialize();
    }
}
