import { GenericService } from "src/app/core/services/generic.service";
import { Usuario } from "src/app/model/usuario.model";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { CacheService } from "src/app/service/cache.service";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ResponseObject } from "src/app/model/ResponseObject.model";

@Injectable()
export class UsuarioService extends GenericService<Usuario> {
  constructor(
    protected http: HttpClient,
    protected router: Router,
    private cacheService: CacheService
  ) {
    super(http, router);
    this.setEntityType("r/usuario");
  }

  getCurrentUser(): Promise<any> {
    const cacheKey = "currentUser";

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP
    return this.http
      .get<Usuario>(this.apiURL + "/currentuser", {
        headers: this.getHeaders(),
      })
      .toPromise()
      .then((response) => {
        this.cacheService.set(cacheKey, response); // Armazena no cache
        return response;
      });
  }
}
