import { Component, OnInit } from "@angular/core";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { Missao } from "src/app/model/missao.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { Usuario } from "src/app/model/usuario.model";
import { environment } from "src/environments/environment";
import { CadastroPublicoLocalService } from "../../cadastropublico/service/cadastro.publico.local.service";
import { ElementoGrupoDominioLocalService } from "../../../service/elementogrupodominio.local.service";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { Pais } from "src/app/model/pais.model";
import { Estado } from "src/app/model/estado.model";
import { Cidade } from "src/app/model/cidade.model";
import { PessoaInfo } from "src/app/model/pessoainfo.model";
import { PessoaInfoBuilder } from "src/app/builder/pessoainfo.builder";
import { DadoVocacional } from "src/app/model/dadovocacional.model";
import { DadoFormandoCV } from "src/app/model/dadoformandocv.model";
import { CaminhoFormativo } from "src/app/model/caminhoformativo.model";
import { DadoFormandoCVService } from "../service/dadoformandocv.service";
import { CaminhoFormativoService } from "../service/caminhoformativo.service";
import { DadoFormandoCVBuilder } from "src/app/builder/dadoformandocv.builder";
import { CaminhoFormativoBuilder } from "src/app/builder/caminhoformativo.builder";
import { Mensagem } from "src/app/model/mensagem.model";
import { RespostaQuestionario } from "src/app/model/respostaquestionario.model";
import { RespostaQuestionarioBuilder } from "src/app/builder/respostaquestionario.builder";
import { MensagemBuilder } from "src/app/builder/mensagem.builder";
import { MensagemService } from "../service/mensagem.service";
import { RespostaQuestionarioService } from "../service/respostaquestionario.service";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { QuestionarioFormandoService } from "../service/questionarioformando.service";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { PessoaService } from "../../pessoa/service/pessoa.service";
import { ImagemService } from "../../../service/imagem.service";
import { PaisService } from "src/app/secured/service/pais.service";
import { EstadoService } from "src/app/secured/service/estado.service";
import { CidadeService } from "src/app/secured/service/cidade.service";
import { ElementoGrupoDominioService } from "../../../service/elementogrupodominio.service";
import { MissaoService } from "src/app/secured/service/missao.service";
import { UsuarioService } from "../../usuario/service/usuario.service";
import { DadoVocacionalService } from "../../dadosvocacionais/service/dadovocacional.service";
import { WopGetAllService } from "src/app/service/getall.service";

@Component({
  selector: "app-questionario-view",
  templateUrl: "./questionariod2-view-answer.component.html",
  styleUrls: [],
})
export class QuestionarioD2ViewAnswerComponent implements OnInit {
  public pessoa: Pessoa;
  public missoesVinculo: Array<Missao>;
  public missoes: Array<Missao>;
  public pessoaId: number;
  public tabNumber: number;
  public imageChangedEvent: any;
  public croppedImage: any;
  public nextClicked: boolean;
  public url: string;
  public intlTelInputCalled: boolean = false;
  public intlTellInputInstance: any;
  public formasVida: Array<ElementoGrupoDominio>;
  public formaVidaSelecionada: ElementoGrupoDominio;
  public niveisFormacao: Array<ElementoGrupoDominio>;
  public estadosVida: Array<ElementoGrupoDominio>;
  public pessoaFormadorPessoalSelecionado: PessoaInfo;
  public pessoaFormadorComunitarioSelecionado: PessoaInfo;
  public dadoVocacional: DadoVocacional;
  public dadoFormandoCV: DadoFormandoCV;
  public caminhoFormativo: CaminhoFormativo;
  public urlBaseImagem: string;
  public usuarioAtual: Usuario;
  public mensagemAF: Mensagem;
  public mensagemRL: Mensagem;

  public imageUrl: any;

  public tabNames: Array<string>;
  public activeTabNumber: number;

  public paises: Array<Pais>;
  public estados: Array<Estado>;
  public cidades: Array<Cidade>;

  public questionarioFormandoId: number;
  public questionarioFormando: QuestionarioFormando;

  public respostas: Array<RespostaQuestionario>;

  public resposta1: RespostaQuestionario;
  public resposta2: RespostaQuestionario;
  public resposta3: RespostaQuestionario;
  public resposta4: RespostaQuestionario;
  public resposta5: RespostaQuestionario;
  public resposta6: RespostaQuestionario;
  public resposta7: RespostaQuestionario;
  public resposta8: RespostaQuestionario;
  public resposta9: RespostaQuestionario;
  public resposta10: RespostaQuestionario;
  public resposta11: RespostaQuestionario;
  public resposta12: RespostaQuestionario;
  public resposta13: RespostaQuestionario;
  public resposta14: RespostaQuestionario;
  public resposta15: RespostaQuestionario;
  public resposta16: RespostaQuestionario;
  public resposta17: RespostaQuestionario;
  public resposta18: RespostaQuestionario;
  public resposta19: RespostaQuestionario;
  public resposta20: RespostaQuestionario;

  constructor(
    public wopGetAllPublic: WopGetAllService,
    public pessoaService: PessoaService,
    public imagemService: ImagemService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public cidadeService: CidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public missaoService: MissaoService,
    public swtAlert2Service: SwtAlert2Service,
    public usuarioService: UsuarioService,
    public pessoaBuilder: PessoaBuilder,
    public pessoaInfoBuilder: PessoaInfoBuilder,
    public dadoVocacionalService: DadoVocacionalService,
    public dadoFormandoCVService: DadoFormandoCVService,
    public caminhoFormativoService: CaminhoFormativoService,
    public mensagemService: MensagemService,
    public respostaQuestionarioService: RespostaQuestionarioService,
    public questionarioFormandoService: QuestionarioFormandoService,
    public dadoFormandoCVBuilder: DadoFormandoCVBuilder,
    public caminhoFormativoBuilder: CaminhoFormativoBuilder,
    public mensagemBuilder: MensagemBuilder,
    public respostaQuestionarioBuilder: RespostaQuestionarioBuilder,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.missoesVinculo = new Array<Missao>();
    this.missoes = new Array<Missao>();
    this.formasVida = new Array<ElementoGrupoDominio>();
    this.niveisFormacao = new Array<ElementoGrupoDominio>();
    this.estadosVida = new Array<ElementoGrupoDominio>();
    this.pessoaFormadorPessoalSelecionado =
      this.pessoaInfoBuilder.getInstance();
    this.pessoaFormadorComunitarioSelecionado =
      this.pessoaInfoBuilder.getInstance();
    this.dadoFormandoCV = this.dadoFormandoCVBuilder.getInstance();
    this.caminhoFormativo = this.caminhoFormativoBuilder.getInstance();
    this.mensagemAF = this.mensagemBuilder.getInstance();
    this.mensagemRL = this.mensagemBuilder.getInstance();
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.nextClicked = false;

    this.pessoa = this.pessoaBuilder.getInstance();

    this.urlBaseImagem = environment.imagem.urlBaseImagem;
    this.url = environment.usuario.selectPorUsername;

    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();

    this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];

    this.activeTabNumber = 0;

    this.resposta1 = respostaQuestionarioBuilder.getInstance();
    this.resposta2 = respostaQuestionarioBuilder.getInstance();
    this.resposta3 = respostaQuestionarioBuilder.getInstance();
    this.resposta4 = respostaQuestionarioBuilder.getInstance();
    this.resposta5 = respostaQuestionarioBuilder.getInstance();
    this.resposta6 = respostaQuestionarioBuilder.getInstance();
    this.resposta7 = respostaQuestionarioBuilder.getInstance();
    this.resposta8 = respostaQuestionarioBuilder.getInstance();
    this.resposta9 = respostaQuestionarioBuilder.getInstance();
    this.resposta10 = respostaQuestionarioBuilder.getInstance();
    this.resposta11 = respostaQuestionarioBuilder.getInstance();
    this.resposta12 = respostaQuestionarioBuilder.getInstance();
    this.resposta13 = respostaQuestionarioBuilder.getInstance();
    this.resposta14 = respostaQuestionarioBuilder.getInstance();
    this.resposta15 = respostaQuestionarioBuilder.getInstance();
    this.resposta16 = respostaQuestionarioBuilder.getInstance();
    this.resposta17 = respostaQuestionarioBuilder.getInstance();
    this.resposta18 = respostaQuestionarioBuilder.getInstance();
    this.resposta19 = respostaQuestionarioBuilder.getInstance();
    this.resposta20 = respostaQuestionarioBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initialization();
  }

  async initialization(): Promise<any> {
    await this.getUsuarioAtual();
    this.carregarPaises();
    this.carregarFormasVida();
    this.carregarNiveisFormacao();
    this.carregarEstadoVida();

    await this.carregarMissoesVinculo();

    this.questionarioFormandoId = Number(
      this.route.snapshot.paramMap.get("id")
    );
    await this.carregarQuestionarioFormando();
    await this.carregarPessoa(this.questionarioFormando.pessoaId);
    await this.carregarDadoVocacional();
    await this.carregarDadoFormandoCV();
    await this.carregarCaminhoFormativo();

    await this.carregarRespostas();
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMissoesVinculo(): Promise<any> {
    try {
      let response = await this.wopGetAllPublic.getAllMissao();
      this.missoesVinculo = response.entity;
      this.missoesVinculo = this.missoesVinculo.sort((a: Missao, b: Missao) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarPessoa(pessoaId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorId(pessoaId);

      this.pessoa = response.entity;

      this.pessoaId = this.pessoa.id;

      if (this.pessoa.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

        this.imagemService.createImage(blogImage, this.pessoa);
      }

      if (this.pessoa.formaVidaId != null) {
        this.setarFormaVidaSelecionada();
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  carregarFormasVida(): void {
    let buscaFormaVida = {
      grupoNome: "FORMA_VIDA",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(buscaFormaVida)
      .then((lista: any) => {
        this.formasVida = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarNiveisFormacao(): void {
    let busca = {
      grupoNome: "NIVEL_FORMACAO",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(busca)
      .then((lista: any) => {
        this.niveisFormacao = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarEstadoVida(): void {
    let busca = {
      grupoNome: "ESTADO_VIDA",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(busca)
      .then((lista: any) => {
        this.estadosVida = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  async carregarPaises(): Promise<any> {
    try {
      let response = await this.paisService.findAll();
      this.paises = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarEstados(paisId: number): Promise<any> {
    try {
      let request = {
        paisId: paisId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.estadoService.buscarEstados(request);
      this.estados = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }
  async carregarCidades(estadoId: number): Promise<any> {
    try {
      let request = {
        estadoId: estadoId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.cidadeService.buscarCidades(request);
      this.cidades = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  logOnConsole(dadosPessoaisForm: any): void {
    console.log(dadosPessoaisForm);
  }

  activateTab(tab: string): void {
    $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
    $('.nav-tabs a[href="#' + tab + '"]').tab("show");
  }

  activateTab2(action: string): void {
    if (action.localeCompare("previous") == 0) {
      if (this.activeTabNumber > 0) {
        this.activeTabNumber -= 1;
      }
    } else if (action.localeCompare("next") == 0) {
      if (this.activeTabNumber < this.tabNames.length - 1) {
        this.activeTabNumber += 1;
      }
    }
    $(
      '.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]'
    ).removeClass("disabled");
    $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab(
      "show"
    );
  }

  setarElementoSelecionado(valor) {
    this.pessoa.usuarioId = valor;
  }

  async salvar(pessoa: Pessoa): Promise<any> {
    try {
      let response = await this.pessoaService.update(pessoa.id, pessoa);

      this.pessoa = response.entity;

      if (this.pessoa.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

        this.imagemService.createImage(blogImage, this.pessoa);
      }

      this.swtAlert2Service.successAlert(response.message);
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
    this.atualizarDadoFormandoCV();
    this.atualizarCaminhoFormativo();
    this.atualizarQuestionarioFormando("INICIADO");
  }

  salvarImagem(): void {
    this.imagemService
      .salvarCropped({ imageData: this.croppedImage })
      .then((response: any) => {
        this.swtAlert2Service.successAlert(response.message);
        this.pessoa.imagemId = response.entity.id;
        this.salvar(this.pessoa);
        $("#modal-pessoa-imagem").modal("hide");
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log(event);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log("Imagem carregada");
  }

  cropperReady() {
    console.log("Imagem cortada");
  }

  loadImageFailed() {
    console.log("Carregamento da imagem falhou!");
  }

  setarFormaVidaSelecionada(): void {
    for (let i = 0; i < this.formasVida.length; i++) {
      if (this.formasVida[i].id == this.pessoa.formaVidaId) {
        this.formaVidaSelecionada = this.formasVida[i];
      }
    }
  }

  async carregarDadoVocacional(): Promise<any> {
    try {
      let response = await this.dadoVocacionalService.porPessoaId(
        this.pessoa.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.dadoVocacional = response.entity;
        this.pessoaFormadorPessoalSelecionado =
          this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado.nome =
          this.dadoVocacional.formadorPessoalPessoaNome;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async selecionarFormadorPessoal(pessoa: PessoaInfo): Promise<any> {
    this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    this.pessoaFormadorPessoalSelecionado = pessoa;
    $("#listagem-pessoa-formador-pessoal").modal("hide");
  }

  async selecionarFormadorComunitario(pessoa: PessoaInfo): Promise<any> {
    //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    this.pessoaFormadorComunitarioSelecionado = pessoa;
    $("#listagem-pessoa-formador-comunitario").modal("hide");
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarDadoFormandoCV(): Promise<any> {
    try {
      let response = await this.dadoFormandoCVService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCV = response;
        this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
        this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarCaminhoFormativo(): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.caminhoFormativo = response;
        console.log(this.caminhoFormativo);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarCaminhoFormativo(): Promise<any> {
    try {
      this.caminhoFormativo.missao_atual_id =
        this.pessoa.vinculoMissao.missaoAtualId;
      this.caminhoFormativo.nome_formador_pessoal =
        this.pessoaFormadorPessoalSelecionado.nome;
      if (this.caminhoFormativo.id == null) {
        this.caminhoFormativo.pessoaId = this.pessoa.id;
        let response = await this.caminhoFormativoService.cadastrar(
          this.caminhoFormativo
        );
        this.caminhoFormativo = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.caminhoFormativoService.atualizar(
          this.caminhoFormativo
        );
        this.caminhoFormativo = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarRespostas(): Promise<any> {
    try {
      let response = await this.respostaQuestionarioService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.respostas = response.content;
        console.log(this.respostas);
        this.respostas.forEach((resposta) => {
          switch (resposta.questionario_pergunta_id) {
            case 1:
              this.resposta1 = resposta;
            case 2:
              this.resposta2 = resposta;
            case 3:
              this.resposta3 = resposta;
            case 4:
              this.resposta4 = resposta;
            case 5:
              this.resposta5 = resposta;
            case 6:
              this.resposta6 = resposta;
            case 7:
              this.resposta7 = resposta;
            case 8:
              this.resposta8 = resposta;
            case 9:
              this.resposta9 = resposta;
            case 10:
              this.resposta10 = resposta;
            case 11:
              this.resposta11 = resposta;
            case 12:
              this.resposta12 = resposta;
            case 13:
              this.resposta13 = resposta;
            case 14:
              this.resposta14 = resposta;
            case 15:
              this.resposta15 = resposta;
            case 16:
              this.resposta16 = resposta;
            case 17:
              this.resposta17 = resposta;
            case 18:
              this.resposta18 = resposta;
            case 19:
              this.resposta19 = resposta;
            case 20:
              this.resposta20 = resposta;
          }
        });
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarioFormando(): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorId(
        this.questionarioFormandoId
      );
      if (response != null && response != undefined) {
        this.questionarioFormando = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarMensagemAF(): Promise<any> {
    try {
      let response = await this.dadoFormandoCVService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCV = response;
        this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
        this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarMensagemRL(): Promise<any> {
    try {
      let response = await this.dadoFormandoCVService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCV = response;
        this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
        this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarDadoFormandoCV(): Promise<any> {
    try {
      if (this.dadoFormandoCV.id == null) {
        this.dadoFormandoCV.pessoaId = this.pessoa.id;
        console.log(this.dadoFormandoCV);
        let response = await this.dadoFormandoCVService.cadastrar(
          this.dadoFormandoCV
        );
        this.dadoFormandoCV = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.dadoFormandoCVService.atualizar(
          this.dadoFormandoCV
        );
        this.dadoFormandoCV = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarResposta(resposta: RespostaQuestionario): Promise<any> {
    try {
      resposta.pessoaId = this.pessoa.id;
      if (resposta.id == null) {
        console.log(resposta);
        let response = await this.respostaQuestionarioService.cadastrar(
          resposta
        );
        resposta = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.respostaQuestionarioService.atualizar(
          resposta.id,
          resposta
        );
        resposta = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarMensagemAF(): Promise<any> {
    try {
      if (this.mensagemAF.id == null) {
        this.mensagemAF.origem = 1;
        this.mensagemAF.origem_id = this.questionarioFormandoId;
        this.mensagemAF.lida = 0;
        let response = await this.mensagemService.cadastrarMensagemAF(
          this.mensagemAF
        );
        this.mensagemAF = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.mensagemService.atualizarMensagemAF(
          this.mensagemAF
        );
        this.mensagemAF = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarMensagemRL(): Promise<any> {
    try {
      if (this.mensagemRL.id == null) {
        this.mensagemRL.origem = 1;
        this.mensagemRL.origem_id = this.questionarioFormandoId;
        this.mensagemRL.lida = 0;
        let response = await this.mensagemService.cadastrarMensagemRL(
          this.mensagemRL
        );
        this.mensagemRL = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.mensagemService.atualizarMensagemRL(
          this.mensagemRL
        );
        this.mensagemRL = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  salvarQuestionario() {
    this.resposta1.questionario_pergunta_id = 1;
    this.atualizarResposta(this.resposta1);
    this.resposta2.questionario_pergunta_id = 2;
    this.atualizarResposta(this.resposta2);
    this.resposta3.questionario_pergunta_id = 3;
    this.atualizarResposta(this.resposta3);
    this.resposta4.questionario_pergunta_id = 4;
    this.atualizarResposta(this.resposta4);
    this.resposta5.questionario_pergunta_id = 5;
    this.atualizarResposta(this.resposta5);
    this.resposta6.questionario_pergunta_id = 6;
    this.atualizarResposta(this.resposta6);
    this.resposta7.questionario_pergunta_id = 7;
    this.atualizarResposta(this.resposta7);
    this.resposta8.questionario_pergunta_id = 8;
    this.atualizarResposta(this.resposta8);
    this.resposta9.questionario_pergunta_id = 9;
    this.atualizarResposta(this.resposta9);
    this.resposta10.questionario_pergunta_id = 10;
    this.atualizarResposta(this.resposta10);
    this.resposta11.questionario_pergunta_id = 11;
    this.atualizarResposta(this.resposta11);
    this.resposta12.questionario_pergunta_id = 12;
    this.atualizarResposta(this.resposta12);
    this.resposta13.questionario_pergunta_id = 13;
    this.atualizarResposta(this.resposta13);
    this.resposta14.questionario_pergunta_id = 14;
    this.atualizarResposta(this.resposta14);
    this.resposta15.questionario_pergunta_id = 15;
    this.atualizarResposta(this.resposta15);
    this.resposta16.questionario_pergunta_id = 16;
    this.atualizarResposta(this.resposta16);
    this.resposta17.questionario_pergunta_id = 17;
    this.atualizarResposta(this.resposta17);
    this.resposta18.questionario_pergunta_id = 18;
    this.atualizarResposta(this.resposta18);
    this.resposta19.questionario_pergunta_id = 19;
    this.atualizarResposta(this.resposta19);
    this.resposta20.questionario_pergunta_id = 20;
    this.atualizarResposta(this.resposta20);
    if (this.mensagemAF.mensagem != null) this.atualizarMensagemAF();
    if (this.mensagemRL.mensagem != null) this.atualizarMensagemRL();
    this.atualizarQuestionarioFormando("INICIADO");
  }

  enviarQuestionario() {
    this.salvarQuestionario();
    this.atualizarQuestionarioFormando("ENVIADO");
    this.router.navigate(["secured/questionario-agradecimento"]);
  }

  async atualizarQuestionarioFormando(status: string): Promise<any> {
    try {
      if (this.questionarioFormando.id != null) {
        this.questionarioFormando.status = status;
        if (status == "ENVIADO") {
          this.questionarioFormando.data_envio = new Date();
        }
        let response = await this.questionarioFormandoService.atualizar(
          this.questionarioFormando.id,
          this.questionarioFormando
        );
        this.questionarioFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
}
