<body class="hold-transition login-page-wop">
    <div class="container-fluid">
        <div class="row">
          <!-- Metade esquerda da tela (imagem) -->
          <div class="col-xs-12 col-sm-6">
            <img src="/assets/images/imagem_login.png" alt="Imagem" class="img-responsive imagem-login" style="max-width: 100%;">
          </div>
      
          <!-- Metade direita da tela (logo + formulário) -->
          <div class="col-xs-12 col-sm-6" style="margin:auto">
            <!-- Logo -->
            <div class="text-center">
              <img src="/assets/images/logo_login.png" alt="Logo" class="img-responsive" style="max-width: 150px;">
            </div>      
            <!-- Formulário -->
            <div class="login-box" style="margin:auto">
              <div class="login-logo">      
                  &nbsp;
              </div>      
              <!-- /.login-logo -->
              <div class="card">
                  <div class="card-body login-card-body">
                      <p i18n class="login-box-msg">Faça o login para iniciar sua sessão</p>      
                      <form #loginForm="ngForm">      
                        <div class="input-group mb-3">
                            <input type="username" class="form-control" placeholder="Username" i18n-placeholder name="username" [(ngModel)]="login.username">
                            <div class="input-group-append input-group-text">
                                <span class="fas fa-user"></span>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input type="{{passwordFieldType}}" class="form-control" placeholder="Password" i18n-placeholder name="password" [(ngModel)]="login.password">
                            <div class="input-group-append input-group-text" (click)="togglePasswordVisibility()">
                                <span class="fas fa-eye"></span>
                            </div>
                            <div class="input-group-append input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <a class="cursor-pointer" style="line-height: 2rem;" data-toggle="modal" data-target="#modal-esqueceu-senha">Esqueceu a senha?</a>
                            </div>
                            <!-- /.col -->
                            <div class="col-4">
                                <button i18n type="submit" class="btn btn-primary btn-block btn-flat" (click)="fazerLogin()" [disabled]="!loginForm.form.valid">Login</button>
                            </div>
                            <!-- /.col -->
                        </div>
                      </form>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="modal fade" id="modal-esqueceu-senha" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Esqueceu a Senha?</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                        Digite seu e-mail de cadastro abaixo para recuperar sua senha!<br>
                        <small>Um e-mail será enviado com um link de recuperação!</small><br>
                        <small>Caso não esteja na caixa de entrada do seu e-mail, verifique também sua pasta de Spam ou Lixo Eletronico!</small>
                    </p>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="email">E-mail</label>
                                <input class="form-control" [(ngModel)]="emailRecuperacao" name="email" type="text" placeholder="E-mail cadastrado">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                    <button type="button" (click)="recuperarSenha()" class="btn btn-primary">Recuperar senha!</button>
                </div>
            </div>
        </div>
    </div>
</body>