import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { FormadorComunitario } from "src/app/model/formadorcomunitario.model";
import { FormadorAssistente } from "src/app/model/formadorassistente.model";
export class CelulaManageComponent {
    constructor(celulaService, celulaBuilder, membroComunidadeBuilder, membroService, formadorComunitarioService, imagemService, route, router, swtAlert2Service) {
        this.celulaService = celulaService;
        this.celulaBuilder = celulaBuilder;
        this.membroComunidadeBuilder = membroComunidadeBuilder;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.imagemService = imagemService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginasFormadoresComunitarios = 0;
        this.totalDePaginasFormadoresAssistentes = 0;
        this.totalDePaginasMembros = 0;
        this.centrosEvangelizacao = new Array();
        this.fases = new Array();
        this.celula = this.celulaBuilder.getInstance();
        this.membrosCelula = new Array();
        this.formadoresComunitarios = new Array();
    }
    ngOnInit() {
        this.celulaId = Number(this.route.snapshot.paramMap.get("id"));
        this.formadorComunitario = this.novoFormadorComunitarioCelula();
        this.formadorAssistente = this.novoFormadorAssistenteCelula();
        this.carregarCelula();
        this.buscaMembros = {
            celulaId: this.celulaId,
            pageNumber: 0,
            quantityOfElements: 100,
        };
        this.carregarMembrosCelula();
        this.buscaFormadoresComunitarios = {
            celulaId: this.celulaId,
            pageNumber: 0,
            quantityOfElements: 100,
        };
        this.carregarFormadoresComunitarios();
        this.buscaFormadoresAssistentes = {
            celulaId: this.celulaId,
            pageNumber: 0,
            quantityOfElements: 100,
        };
        this.carregarFormadoresAssistentes();
    }
    /**
     * Formador Comunitário
     */
    cliqueNoBotaoFormadoresComunitariosListener(botao) {
        this.buscaFormadoresComunitarios.pageNumber = botao.numero - 1;
        this.buscaFormadoresComunitarios();
    }
    novoFormadorComunitarioCelula() {
        return new FormadorComunitario(null, 2, this.celulaId, null, null, null, null, null, null, null, null, false);
    }
    salvarFormadorComunitario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.formadorComunitario.pessoaId != null) {
                    let response = yield this.celulaService.cadastrarFormadorComunitario(this.formadorComunitario);
                    this.formadorComunitario = this.novoFormadorComunitarioCelula();
                    this.pessoaFormadorComunitarioSelecionada = undefined;
                    this.carregarFormadoresComunitarios();
                    this.swtAlert2Service.successAlert("Formador comunitário cadastrado com sucesso!");
                }
                else {
                    this.swtAlert2Service.warningAlert("Selecione a pessoa para vincular como formador comunitário.");
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarFormadoresComunitarios() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.listarFormadorComunitarioPorCelulaId(this.celulaId);
                this.formadoresComunitarios = response;
                this.totalDePaginasFormadoresComunitarios = 1;
                //this.formadoresComunitarios = response.content;
                //this.totalDePaginasFormadoresComunitarios = response.totalPages;
                if (this.formadoresComunitarios.length > 0) {
                    this.formadoresComunitarios = this.formadoresComunitarios.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.formadoresComunitarios.length; i++) {
                    if (this.formadoresComunitarios[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.formadoresComunitarios[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.formadoresComunitarios[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarFormador(formador) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.atualizarFormadorComunitario(formador);
                this.carregarFormadoresComunitarios();
                this.swtAlert2Service.successAlert("Formador Comunitário atualizado com sucesso!");
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    removerFormador(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (confirm("Deseja deletar esse formador?")) {
                    let response = yield this.celulaService.deletarFormadorComunitario(id);
                    this.carregarFormadoresComunitarios();
                    this.swtAlert2Service.successAlert("Formador Comunitário removido com sucesso!");
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    selecionarFormadorComunitario(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.formadorComunitario.pessoaId = pessoa.id;
            this.pessoaFormadorComunitarioSelecionada = pessoa;
            $("#listagem-pessoa-formador-comunitario").modal("hide");
        });
    }
    /**
     * Formador Assistente
     */
    cliqueNoBotaoFormadoresAssistentesListener(botao) {
        this.buscaFormadoresAssistentes.pageNumber = botao.numero - 1;
        this.buscaFormadoresAssistentes();
    }
    novoFormadorAssistenteCelula() {
        return new FormadorAssistente(null, 2, this.celulaId, null, null, null, null, null, null, null, null, false);
    }
    salvarFormadorAssistente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.formadorAssistente.pessoaId != null) {
                    let response = yield this.celulaService.cadastrarFormadorAssistente(this.formadorAssistente);
                    this.formadorAssistente = this.novoFormadorAssistenteCelula();
                    this.pessoaFormadorAssistenteSelecionada = undefined;
                    this.carregarFormadoresAssistentes();
                    this.swtAlert2Service.successAlert("Formador Assistente cadastrado com sucesso!");
                }
                else {
                    this.swtAlert2Service.warningAlert("Selecione a pessoa para vincular como formador assistente.");
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarFormadoresAssistentes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.listarFormadorAssistentePorCelulaId(this.celulaId);
                this.formadoresAssistentes = response;
                this.totalDePaginasMembros = 1;
                //this.formadoresAssistentes = response.content;
                //this.totalDePaginasFormadoresAssistentes = response.totalPages;
                if (this.formadoresAssistentes.length > 0) {
                    this.formadoresAssistentes = this.formadoresAssistentes.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.formadoresAssistentes.length; i++) {
                    if (this.formadoresAssistentes[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.formadoresAssistentes[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.formadoresAssistentes[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarFormadorAssistente(formador) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.atualizarFormadorAssistente(formador);
                this.carregarFormadoresAssistentes();
                this.swtAlert2Service.successAlert("Formador Assistente atualizado com sucesso!");
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    removerFormadorAssistente(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (confirm("Deseja deletar esse formador?")) {
                    let response = yield this.celulaService.deletarFormadorAssistente(id);
                    this.carregarFormadoresAssistentes();
                    this.swtAlert2Service.successAlert("Formador Assistente removido com sucesso!");
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    selecionarFormadorAssistente(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.formadorAssistente.pessoaId = pessoa.id;
            this.pessoaFormadorAssistenteSelecionada = pessoa;
            $("#listagem-pessoa-formador-assistente").modal("hide");
        });
    }
    /**
     * Formador Célula
     */
    carregarCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.find(this.celulaId);
                this.celula = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    cliqueNoBotaoMembrosListener(botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.buscaMembros();
    }
    carregarMembrosCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.membroService.listarDetalhesPorCelulaIdFromView(this.celulaId);
                this.membrosCelula = response;
                this.totalDePaginasMembros = 1;
                //this.membrosCelula = response.content;
                //this.totalDePaginasMembros = response.totalPages;
                if (this.membrosCelula.length > 0) {
                    this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.membrosCelula.length; i++) {
                    if (this.membrosCelula[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
