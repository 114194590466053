import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { CaminhoFormativoBuilder } from "src/app/builder/caminhoformativo.builder";
import { DadoFormandoCABuilder } from "src/app/builder/dadoformandoca.builder";
import { MensagemBuilder } from "src/app/builder/mensagem.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { PessoaInfoBuilder } from "src/app/builder/pessoainfo.builder";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { RespostaQuestionarioBuilder } from "src/app/builder/respostaquestionario.builder";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { CaminhoFormativo } from "src/app/model/caminhoformativo.model";
import { DadoFormandoCA } from "src/app/model/dadoformandoca.model";
import { DadoVocacional } from "src/app/model/dadovocacional.model";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { Mensagem } from "src/app/model/mensagem.model";
import { Missao } from "src/app/model/missao.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { PessoaInfo } from "src/app/model/pessoainfo.model";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { RespostaQuestionario } from "src/app/model/respostaquestionario.model";
import { Usuario } from "src/app/model/usuario.model";
import { CidadeService } from "src/app/secured/service/cidade.service";
import { EstadoService } from "src/app/secured/service/estado.service";
import { MissaoService } from "src/app/secured/service/missao.service";
import { PaisService } from "src/app/secured/service/pais.service";
import { WopGetAllService } from "src/app/service/getall.service";
import { environment } from "src/environments/environment";
import { ElementoGrupoDominioLocalService } from "../../../service/elementogrupodominio.local.service";
import { ElementoGrupoDominioService } from "../../../service/elementogrupodominio.service";
import { ImagemService } from "../../../service/imagem.service";
import { DadoVocacionalService } from "../../dadosvocacionais/service/dadovocacional.service";
import { DadoFormandoCAService } from "../../parecer/service/dadoformandoca.service";
import { PessoaService } from "../../pessoa/service/pessoa.service";
import { UsuarioService } from "../../usuario/service/usuario.service";
import { CaminhoFormativoService } from "../service/caminhoformativo.service";
import { MensagemService } from "../service/mensagem.service";
import { QuestionarioFormandoService } from "../service/questionarioformando.service";
import { RespostaQuestionarioService } from "../service/respostaquestionario.service";

@Component({
  selector: "app-questionario-cal-view",
  templateUrl: "./questionario-cal-answer.component.html",
  styleUrls: [],
})
export class QuestionarioCALAnswerComponent implements OnInit {
  public isNaN: Function = Number.isNaN;

  public pessoa: Pessoa;
  public missoesVinculo: Array<Missao>;
  public missoes: Array<Missao>;
  public pessoaId: number;
  public tabNumber: number;
  public imageChangedEvent: any;
  public croppedImage: any;
  public nextClicked: boolean;
  public url: string;
  public intlTelInputCalled: boolean = false;
  public intlTellInputInstance: any;
  public formasVida: Array<ElementoGrupoDominio>;
  public formaVidaSelecionada: ElementoGrupoDominio;
  public niveisFormacao: Array<ElementoGrupoDominio>;
  public estadosVida: Array<ElementoGrupoDominio>;
  public pessoaFormadorPessoalSelecionado: PessoaInfo;
  public pessoaFormadorComunitarioSelecionado: PessoaInfo;
  public dadoVocacional: DadoVocacional;
  public dadoFormandoCA: DadoFormandoCA;
  public caminhoFormativo: CaminhoFormativo;
  public urlBaseImagem: string;
  public usuarioAtual: Usuario;
  public mensagemRL: Mensagem;

  public imageUrl: any;

  public tabNames: Array<string>;
  public activeTabNumber: number;

  public questionarioFormandoId: number;
  public questionarioFormando: QuestionarioFormando;

  public respostas: Array<RespostaQuestionario>;

  public resposta39: RespostaQuestionario;
  public resposta40: RespostaQuestionario;
  public resposta41: RespostaQuestionario;
  public resposta42: RespostaQuestionario;
  public resposta43: RespostaQuestionario;
  public resposta44: RespostaQuestionario;
  public resposta45: RespostaQuestionario;
  public resposta46: RespostaQuestionario;
  public resposta47: RespostaQuestionario;
  public resposta48: RespostaQuestionario;
  public resposta49: RespostaQuestionario;

  constructor(
    public wopGetAllPublic: WopGetAllService,
    public pessoaService: PessoaService,
    public imagemService: ImagemService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public cidadeService: CidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public missaoService: MissaoService,
    public swtAlert2Service: SwtAlert2Service,
    public usuarioService: UsuarioService,
    public pessoaBuilder: PessoaBuilder,
    public pessoaInfoBuilder: PessoaInfoBuilder,
    public dadoVocacionalService: DadoVocacionalService,
    public dadoFomandoCAService: DadoFormandoCAService,
    public caminhoFormativoService: CaminhoFormativoService,
    public mensagemService: MensagemService,
    public respostaQuestionarioService: RespostaQuestionarioService,
    public questionarioFormandoService: QuestionarioFormandoService,
    public dadoFomandoCABuilder: DadoFormandoCABuilder,
    public caminhoFormativoBuilder: CaminhoFormativoBuilder,
    public mensagemBuilder: MensagemBuilder,
    public respostaQuestionarioBuilder: RespostaQuestionarioBuilder,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.missoesVinculo = new Array<Missao>();
    this.missoes = new Array<Missao>();
    this.formasVida = new Array<ElementoGrupoDominio>();
    this.niveisFormacao = new Array<ElementoGrupoDominio>();
    this.estadosVida = new Array<ElementoGrupoDominio>();
    this.pessoaFormadorPessoalSelecionado =
      this.pessoaInfoBuilder.getInstance();
    this.pessoaFormadorComunitarioSelecionado =
      this.pessoaInfoBuilder.getInstance();
    this.dadoFormandoCA = this.dadoFomandoCABuilder.getInstance();
    this.caminhoFormativo = this.caminhoFormativoBuilder.getInstance();
    this.mensagemRL = this.mensagemBuilder.getInstance();

    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.nextClicked = false;

    this.pessoa = this.pessoaBuilder.getInstance();

    this.urlBaseImagem = environment.imagem.urlBaseImagem;
    this.url = environment.usuario.selectPorUsername;

    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();

    this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];

    this.activeTabNumber = 0;

    this.resposta39 = respostaQuestionarioBuilder.getInstance();
    this.resposta40 = respostaQuestionarioBuilder.getInstance();
    this.resposta41 = respostaQuestionarioBuilder.getInstance();
    this.resposta42 = respostaQuestionarioBuilder.getInstance();
    this.resposta43 = respostaQuestionarioBuilder.getInstance();
    this.resposta44 = respostaQuestionarioBuilder.getInstance();
    this.resposta45 = respostaQuestionarioBuilder.getInstance();
    this.resposta46 = respostaQuestionarioBuilder.getInstance();
    this.resposta47 = respostaQuestionarioBuilder.getInstance();
    this.resposta48 = respostaQuestionarioBuilder.getInstance();
    this.resposta49 = respostaQuestionarioBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initialization();
  }

  async initialization(): Promise<any> {
    await this.getUsuarioAtual();
    this.carregarFormasVida();
    this.carregarNiveisFormacao();
    this.carregarEstadoVida();

    await this.carregarMissoesVinculo();

    await this.carregarPessoa();

    this.questionarioFormandoId = Number(
      this.route.snapshot.paramMap.get("id")
    );

    await this.carregarDadoVocacional();
    await this.carregarDadoFormandoCA();
    await this.carregarCaminhoFormativo();
    await this.carregarQuestionarioFormando();
    await this.carregarRespostas();
    await this.carregarMensagemRLPorOrigem();
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMissoesVinculo(): Promise<any> {
    try {
      let response = await this.wopGetAllPublic.getAllMissao();
      this.missoesVinculo = response.entity;
      this.missoesVinculo = this.missoesVinculo.sort((a: Missao, b: Missao) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuarioAtual.id
      );

      this.pessoa = response.entity;

      this.pessoaId = this.pessoa.id;

      if (this.pessoa.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

        this.imagemService.createImage(blogImage, this.pessoa);
      }

      if (this.pessoa.formaVidaId != null) {
        this.setarFormaVidaSelecionada();
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  carregarFormasVida(): void {
    let buscaFormaVida = {
      grupoNome: "FORMA_VIDA",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(buscaFormaVida)
      .then((lista: any) => {
        this.formasVida = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarNiveisFormacao(): void {
    let busca = {
      grupoNome: "NIVEL_FORMACAO",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(busca)
      .then((lista: any) => {
        this.niveisFormacao = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarEstadoVida(): void {
    let busca = {
      grupoNome: "ESTADO_VIDA",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(busca)
      .then((lista: any) => {
        this.estadosVida = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  logOnConsole(dadosPessoaisForm: any): void {
    console.log(dadosPessoaisForm);
  }

  activateTab(tab: string): void {
    $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
    $('.nav-tabs a[href="#' + tab + '"]').tab("show");
  }

  activateTab2(action: string): void {
    if (action.localeCompare("previous") == 0) {
      if (this.activeTabNumber > 0) {
        this.activeTabNumber -= 1;
      }
    } else if (action.localeCompare("next") == 0) {
      if (this.activeTabNumber < this.tabNames.length - 1) {
        this.activeTabNumber += 1;
      }
    }
    $(
      '.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]'
    ).removeClass("disabled");
    $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab(
      "show"
    );
  }

  setarElementoSelecionado(valor) {
    this.pessoa.usuarioId = valor;
  }

  async salvar(pessoa: Pessoa): Promise<any> {
    try {
      let response = await this.pessoaService.update(pessoa.id, pessoa);

      this.pessoa = response.entity;

      if (this.pessoa.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

        this.imagemService.createImage(blogImage, this.pessoa);
      }

      this.swtAlert2Service.successAlert(response.message);
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }

    await this.atualizarDadoFormandoCA();
    await this.atualizarCaminhoFormativo();
    await this.atualizarQuestionarioFormando("INICIADO");
  }

  salvarImagem(): void {
    this.imagemService
      .salvarCropped({ imageData: this.croppedImage })
      .then((response: any) => {
        this.swtAlert2Service.successAlert(response.message);
        this.pessoa.imagemId = response.entity.id;
        this.salvar(this.pessoa);
        $("#modal-pessoa-imagem").modal("hide");
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log(event);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log("Imagem carregada");
  }

  cropperReady() {
    console.log("Imagem cortada");
  }

  loadImageFailed() {
    console.log("Carregamento da imagem falhou!");
  }

  setarFormaVidaSelecionada(): void {
    for (let i = 0; i < this.formasVida.length; i++) {
      if (this.formasVida[i].id == this.pessoa.formaVidaId) {
        this.formaVidaSelecionada = this.formasVida[i];
      }
    }
  }

  async carregarDadoVocacional(): Promise<any> {
    try {
      let response = await this.dadoVocacionalService.porPessoaId(
        this.pessoa.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.dadoVocacional = response.entity;
        this.pessoaFormadorPessoalSelecionado =
          this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado.nome =
          this.dadoVocacional.formadorPessoalPessoaNome;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async selecionarFormadorPessoal(pessoa: PessoaInfo): Promise<any> {
    this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    this.pessoaFormadorPessoalSelecionado = pessoa;
    $("#listagem-pessoa-formador-pessoal").modal("hide");
  }

  async selecionarFormadorComunitario(pessoa: PessoaInfo): Promise<any> {
    //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    this.pessoaFormadorComunitarioSelecionado = pessoa;
    $("#listagem-pessoa-formador-comunitario").modal("hide");
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarDadoFormandoCA(): Promise<any> {
    try {
      let response = await this.dadoFomandoCAService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCA = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarCaminhoFormativo(): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.caminhoFormativo = response;
        console.log("Caminho Formativo", this.caminhoFormativo);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarCaminhoFormativo(): Promise<any> {
    try {
      this.caminhoFormativo.missao_atual_id =
        this.pessoa.vinculoMissao.missaoAtualId;
      this.caminhoFormativo.nome_formador_pessoal =
        this.pessoaFormadorPessoalSelecionado.nome;
      if (this.caminhoFormativo.id == null) {
        this.caminhoFormativo.pessoaId = this.pessoa.id;
        let response = await this.caminhoFormativoService.cadastrar(
          this.caminhoFormativo
        );
        this.caminhoFormativo = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.caminhoFormativoService.atualizar(
          this.caminhoFormativo
        );
        this.caminhoFormativo = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarRespostas(): Promise<any> {
    try {
      let response = await this.respostaQuestionarioService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.respostas = response.content;
        this.respostas.forEach((resposta) => {
          switch (resposta.questionario_pergunta_id) {
            case 39:
              this.resposta39 = resposta;
              break;
            case 40:
              this.resposta40 = resposta;
              break;
            case 41:
              this.resposta41 = resposta;
              break;
            case 42:
              this.resposta42 = resposta;
              break;
            case 43:
              this.resposta43 = resposta;
              break;
            case 44:
              this.resposta44 = resposta;
              break;
            case 45:
              this.resposta45 = resposta;
              break;
            case 46:
              this.resposta46 = resposta;
              break;
            case 47:
              this.resposta47 = resposta;
              break;
            case 48:
              this.resposta48 = resposta;
              break;
            case 49:
              this.resposta49 = resposta;
              break;
          }
        });
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarioFormando(): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorId(
        this.questionarioFormandoId
      );
      if (response != null && response != undefined) {
        this.questionarioFormando = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarMensagemAF(): Promise<any> {
    try {
      let response = await this.dadoFomandoCAService.porPessoaId(
        this.pessoa.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCA = response;
        /*this.carregarEstados(this.dadoFormandoCA.pais_origem_id);
                this.carregarCidades(this.dadoFormandoCA.estado_origem_id);*/
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarMensagemRLPorOrigem(): Promise<any> {
    try {
      let response = await this.mensagemService.mensagemRLPorOrigemId(
        this.questionarioFormando.id
      );
      if (response != null && response != undefined) {
        this.mensagemRL = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarDadoFormandoCA(): Promise<any> {
    try {
      if (this.dadoFormandoCA.id == null) {
        this.dadoFormandoCA.pessoaId = this.pessoa.id;
        let response = await this.dadoFomandoCAService.cadastrar(
          this.dadoFormandoCA
        );
        this.dadoFormandoCA = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.dadoFomandoCAService.atualizar(
          this.dadoFormandoCA
        );
        this.dadoFormandoCA = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarResposta(resposta: RespostaQuestionario): Promise<any> {
    try {
      resposta.pessoaId = this.pessoa.id;
      if (resposta.id == null) {
        let response = await this.respostaQuestionarioService.cadastrar(
          resposta
        );
        this.swtAlert2Service.successAlert(response.message);
        return response;
      } else {
        let response = await this.respostaQuestionarioService.atualizar(
          resposta.id,
          resposta
        );
        this.swtAlert2Service.successAlert(response.message);
        return response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarMensagem(): Promise<any> {
    try {
      if (this.mensagemRL.id == null) {
        this.mensagemRL.origem = 1; //1 - Questionario Formando
        this.mensagemRL.origem_id = this.questionarioFormandoId;
        this.mensagemRL.lida = 0;
        let response = await this.mensagemService.cadastrarMensagemRL(
          this.mensagemRL
        );
        this.mensagemRL = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.mensagemService.atualizarMensagemRL(
          this.mensagemRL
        );
        this.mensagemRL = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async salvarQuestionario(): Promise<any> {
    if (!this.respostaVazia(this.resposta39)) {
      this.resposta39.questionario_pergunta_id = 39;
      this.resposta39 = await this.atualizarResposta(this.resposta39);
    }
    if (!this.respostaVazia(this.resposta40)) {
      this.resposta40.questionario_pergunta_id = 40;
      this.resposta40 = await this.atualizarResposta(this.resposta40);
    }
    if (!this.respostaVazia(this.resposta41)) {
      this.resposta41.questionario_pergunta_id = 41;
      this.resposta41 = await this.atualizarResposta(this.resposta41);
    }
    if (!this.respostaVazia(this.resposta42)) {
      this.resposta42.questionario_pergunta_id = 42;
      this.resposta42 = await this.atualizarResposta(this.resposta42);
    }
    if (!this.respostaVazia(this.resposta43)) {
      this.resposta43.questionario_pergunta_id = 43;
      this.resposta43 = await this.atualizarResposta(this.resposta43);
    }
    if (!this.respostaVazia(this.resposta44)) {
      this.resposta44.questionario_pergunta_id = 44;
      this.resposta44 = await this.atualizarResposta(this.resposta44);
    }
    if (!this.respostaVazia(this.resposta45)) {
      this.resposta45.questionario_pergunta_id = 45;
      this.resposta45 = await this.atualizarResposta(this.resposta45);
    }
    if (!this.respostaVazia(this.resposta46)) {
      this.resposta46.questionario_pergunta_id = 46;
      this.resposta46 = await this.atualizarResposta(this.resposta46);
    }
    if (!this.respostaVazia(this.resposta47)) {
      this.resposta47.questionario_pergunta_id = 47;
      this.resposta47 = await this.atualizarResposta(this.resposta47);
    }
    if (!this.respostaVazia(this.resposta48)) {
      this.resposta48.questionario_pergunta_id = 48;
      this.resposta48 = await this.atualizarResposta(this.resposta48);
    }
    if (!this.respostaVazia(this.resposta49)) {
      this.resposta49.questionario_pergunta_id = 49;
      this.resposta49 = await this.atualizarResposta(this.resposta49);
    }
    if (this.mensagemRL.mensagem != null) this.atualizarMensagem();

    // let status = this.definirStatusQuestionario();
    await this.atualizarQuestionarioFormando("INICIADO");
  }

  definirStatusQuestionario(): string {
    let status = "INICIADO";

    let todoRespondido = false;

    if (
      !this.respostaVazia(this.resposta39) &&
      !this.respostaVazia(this.resposta41) &&
      !this.respostaVazia(this.resposta40) &&
      !this.respostaVazia(this.resposta42) &&
      !this.respostaVazia(this.resposta43) &&
      !this.respostaVazia(this.resposta44) &&
      !this.respostaVazia(this.resposta45) &&
      !this.respostaVazia(this.resposta46) &&
      !this.respostaVazia(this.resposta47) &&
      !this.respostaVazia(this.resposta48) &&
      !this.respostaVazia(this.resposta49)
    ) {
      todoRespondido = true;
    }

    if (todoRespondido) {
      status = "ENVIADO";
    }

    return status;
  }

  respostaVazia(resposta: any): boolean {
    return (
      resposta.resposta == null ||
      resposta.resposta == undefined ||
      resposta.resposta == ""
    );
  }

  async enviarQuestionario(): Promise<any> {
    await this.salvarQuestionario();
    await this.atualizarQuestionarioFormando("ENVIADO");
    this.router.navigate(["secured/questionario-agradecimento"]);
  }

  async atualizarQuestionarioFormando(status: string): Promise<any> {
    try {
      if (this.questionarioFormando.id != null) {
        this.questionarioFormando.status = status;
        if (status == "ENVIADO") {
          this.questionarioFormando.data_envio = new Date();
        }
        let response = await this.questionarioFormandoService.atualizar(
          this.questionarioFormando.id,
          this.questionarioFormando
        );
        this.questionarioFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
}
