import { GenericService } from "src/app/core/services/generic.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { CaminhoFormativo } from "src/app/model/caminhoformativo.model";
import { environment } from "src/environments/environment";

@Injectable()
export class CaminhoFormativoService extends GenericService<CaminhoFormativo> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("caminhoformativo");
  }

  porPessoaId(id: number): Promise<any> {
    return this.http
      .get<any>(`${environment.moduloFormacao.buscarCaminhoFormativo}${id}`, {
        headers: this.getHeaders(),
      })
      .toPromise();
  }

  cadastrar(request: any) {
    return this.http
      .post<any>(
        environment.moduloFormacao.cadastrarCaminhoFormativo,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizar(request: any) {
    return this.http
      .put<any>(
        environment.moduloFormacao.atualizarCaminhoFormativo,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }
}
