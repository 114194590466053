import { Injectable } from "@angular/core";
import { AbstractBuilder } from "./abstract.builder";
import { CaminhoFormativo } from "../model/caminhoformativo.model";

@Injectable()
export class CaminhoFormativoBuilder extends AbstractBuilder<CaminhoFormativo> {
  reset(): void {
    this.entity = new CaminhoFormativo(null,null,null,null,null,null,null,null,null,null,null,
      null,null,null,null
    );
  }
}
