export class Mensagem {

    constructor(
        public id: number,
        public perfil_id: number,
        public origem: number,
        public origem_id: number,
        public mensagem: string,
        public lida: number
    ){}
}