import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RemocaoMembroCasaComunitariaBuilder } from 'src/app/builder/remocaomembrocasacomunitaria.builder';
import { SalvarMissionarioMissaoAtualBuilder } from 'src/app/builder/salvarmissionariomissaoatual.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RemocaoMembroCasaComunitaria } from 'src/app/model/remocaomembrocasacomunitaria.model.';
import { ElementoGrupoDominioLocalService } from '../../../../service/elementogrupodominio.local.service';
import { ImagemService } from '../../../../service/imagem.service';
import { MissionarioMissaoAtualService } from '../../../dadosvocacionais/service/missionariomissaoatual.service';
import { PessoaService } from '../../../pessoa/service/pessoa.service';

@Component({
    selector: 'remocao-membro-casa-comunitaria-component',
    templateUrl: './remocao-membro-casa-comunitaria.component.html'
})
export class RemocaoMembroCasaComunitariaComponent implements OnInit {

    @Input() 
    public modalId: string;
    @Input() 
    public modalTitle: string;
    @Input() 
    public buttonText: string;
    @Input() 
    public altButtonText: string;

    @Input() 
    public membroCasaComunitaria: any;

    @Output() 
    public eventEmitter = new EventEmitter();

    public remocaoMembroCasaComunitaria: RemocaoMembroCasaComunitaria;
    public motivosSaida: Array<ElementoGrupoDominio>;
    public exibirCampoDescricaoMotivoSaida: boolean;


    constructor(
        public imagemService: ImagemService,        
        public missionarioMissaoAtualService: MissionarioMissaoAtualService,
        public pessoaService: PessoaService,
        public salvarMissionarioMissaoAtualBuilder: SalvarMissionarioMissaoAtualBuilder,
        public remocaoMembroCasaComunitariaBuilder: RemocaoMembroCasaComunitariaBuilder,
        public elementoGrupoDominioService: ElementoGrupoDominioLocalService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ){
        this.remocaoMembroCasaComunitaria = this.remocaoMembroCasaComunitariaBuilder.getInstance();
        this.motivosSaida = new Array<ElementoGrupoDominio>();
    }

    ngOnInit() {
        this.initializer();
    }

    async initializer() : Promise<any> {
        
        this.carregarMotivoSaidaCasaComunitaria();

        if(this.membroCasaComunitaria != null && this.membroCasaComunitaria.imagemId != null)
        {
            let blogImage = await this.getPessoaImagem(this.membroCasaComunitaria.imagemId);

            this.imagemService.createImage(blogImage, this.membroCasaComunitaria);
        }

    }

   async carregarMotivoSaidaCasaComunitaria() {

        let busca = {
            grupoNome : 'MOTIVO_SAIDA_CASA_COMUNITARIA'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.motivosSaida = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

   }

    async removerMembroCasaComunitaria(membroCasaComunitaria: any) : Promise<any> {

        if(confirm("Confirma a remoção do missionário da casa comunitária?"))
        {
            try
            {
                this.remocaoMembroCasaComunitaria.missionarioMissaoAtualId = membroCasaComunitaria.missionarioMissaoAtualId;

                let response = await this.missionarioMissaoAtualService.removerMembroDaCasaComunitaria(this.remocaoMembroCasaComunitaria);
                this.resetarFormulario();
                this.swtAlert2Service.successAlert("Missionário removido da casa comunitária com sucesso!");
                this.eventEmitter.emit("recarregar_membros_casa_comunitaria");
    
            }
            catch(err)
            {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        }
        
    }

    private resetarFormulario() {
        this.remocaoMembroCasaComunitaria = this.remocaoMembroCasaComunitariaBuilder.getInstance();
        this.exibirCampoDescricaoMotivoSaida = false;
        $('#' + this.modalId).modal('hide');
    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    exibirSeMotivoSaidaForOutro(idMotivoSaida: number) : void {
        
        if(this.motivosSaida.length > 0)
        {

            for(let motivo of this.motivosSaida)
            {
                if(motivo.id == idMotivoSaida)
                {
                    if(motivo.valor == 'O')
                    {
                        this.exibirCampoDescricaoMotivoSaida = true;
                        break;
                    }
                    else
                    {
                        this.exibirCampoDescricaoMotivoSaida = false;
                        break;
                    }                
                }
            }

        }
        else
        {
            this.exibirCampoDescricaoMotivoSaida = false;
        }    
        
    }

}