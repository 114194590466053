import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { CacheService } from './cache.service';


@Injectable()
export class WopGetAllService {
    
    constructor(protected http: HttpClient, protected router:Router, private cacheService: CacheService) {
    }

    public getHeaders() : HttpHeaders {
        return new HttpHeaders({
            'Content-Type' : 'application/json; charset=UTF-8'            
        }); 
    }

    elementosGrupoDominioPorNomeGrupo(obj: any) : Promise<any> {
        const cacheKey = 'elementosGrupoDominioPorNomeGrupo-getAll' + JSON.stringify(obj);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.post<any>(environment.elementoGrupoDominio.porNomeGrupo, JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    getAllPais(): Promise<any> {
        const cacheKey = 'getAllPais';

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get<any>(environment.pais.getAllPublic).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    getAllMissao(): Promise<any> {
        const cacheKey = 'getAllMissao';

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get<any>(environment.missao.getAllPublic).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    getAllDifusao(): Promise<any> {
        const cacheKey = 'getAllDifusao';

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get<any>(environment.difusao.getAllPublic).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

}