<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <div class="text-center">
                            <h3 i18n>Obrigada pelo seu Parecer!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <div class="text-center">“Que o Senhor te abençoe no cumprimento desta sua preciosa missão para a Comunidade!</div>                    
                </div>                
            </div>
        </section>
    </div>    
</secured>