import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class CelulasCoordenadorColegiadoComponent {
    constructor(usuarioService, pessoaService, regionalService, missaoService, paisService, estadoService, centroEvangelizacaoService, celulaService, elementoGrupoDominioService, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.centroEvangelizacaoService = centroEvangelizacaoService;
        this.celulaService = celulaService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.fases = new Array();
        this.centrosEvangelizacao = new Array();
        this.busca = {
            nome: null,
            ano: null,
            faseId: null,
            centroEvangelizacaoId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.initializer();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            this.carregarNiveisDaCelula();
            this.buscarCelulas();
        });
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
                if (this.pessoa.id) {
                    if (this.pessoa.vinculoMissao.missaoAtualId) {
                        this.carregarCevPorMissao(this.pessoa.vinculoMissao.missaoAtualId);
                    }
                    else if (this.pessoa.vinculoDifusao.difusaoAtualId) {
                        this.carregarCevPorDifusao(this.pessoa.vinculoDifusao.difusaoAtualId);
                    }
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarCelulas();
    }
    carregarCevPorMissao(missaoId) {
        this.centroEvangelizacaoService.porMissao(missaoId).then((response) => {
            this.centrosEvangelizacao = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
        ;
    }
    carregarCevPorDifusao(difusaoId) {
        this.centroEvangelizacaoService.porDifusao(difusaoId).then((response) => {
            this.centrosEvangelizacao = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
        ;
    }
    carregarNiveisDaCelula() {
        let buscaFase = {
            grupoNome: 'FASE_CELULA'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then((lista) => {
            this.fases = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    buscarCelulas() {
        this.celulaService.buscarCelulas(this.busca).then((response) => {
            this.celulas = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
}
