import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UsuarioComponent } from './usuario.component';
import { UsuarioRoutingModule } from './usuario-routing.module';
import { UsuarioService } from './service/usuario.service';
import { AutorizacaoService } from '../../service/autorizacao.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { UsuarioEditMembroComponent } from './tela-membro/usuario.edit.membro.component';
import { EsqueceusenhaModule } from './esqueceusenha/esqueceusenha.module';

@NgModule({
    declarations: [ 
        UsuarioComponent,
        UsuarioEditMembroComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        UsuarioRoutingModule,
        FormsModule,
        SecuredModule,
        EsqueceusenhaModule
    ],
    providers: [
        UsuarioService,
        AutorizacaoService
    ]
})
export class UsuarioModule {

}