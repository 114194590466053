import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
export class ElementoGrupoDominioLocalService extends GenericService {
    constructor(http, router, cacheService) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.cacheService = cacheService;
        this.setEntityType("elementogrupodominio");
    }
    buscarElementosGrupoDominio(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cacheKey = 'buscarElementosGrupoDominioLocal-' + JSON.stringify(busca);
            // Verifica se os dados já estão no cache
            if (this.cacheService.has(cacheKey)) {
                return Promise.resolve(this.cacheService.get(cacheKey));
            }
            // Caso não tenha no cache, faz a requisição HTTP    
            return this.http
                .post(this.apiURL + "/buscar", JSON.stringify(busca), {
                headers: this.getHeaders(),
            })
                .toPromise()
                .then(response => {
                this.cacheService.set(cacheKey, response); // Armazena no cache
                return response;
            });
        });
    }
    buscarPorGrupoDominioNome(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cacheKey = 'buscarPorGrupoDominioLocalNome-' + JSON.stringify(busca);
            // Verifica se os dados já estão no cache
            if (this.cacheService.has(cacheKey)) {
                return Promise.resolve(this.cacheService.get(cacheKey));
            }
            // Caso não tenha no cache, faz a requisição HTTP    
            return this.http
                .post(this.apiURL + "/pornomegrupo", JSON.stringify(busca), {
                headers: this.getHeaders(),
            })
                .toPromise()
                .then(response => {
                this.cacheService.set(cacheKey, response); // Armazena no cache
                return response;
            });
        });
    }
    buscarPorGrupoDominioNomeModuloFormacao(nome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cacheKey = 'buscarPorGrupoDominioLocalNomeModuloFormacao-' + JSON.stringify(nome);
            // Verifica se os dados já estão no cache
            if (this.cacheService.has(cacheKey)) {
                return Promise.resolve(this.cacheService.get(cacheKey));
            }
            // Caso não tenha no cache, faz a requisição HTTP    
            let url = `${environment.moduloFormacao.urlModuloFormacao}secured/elementogrupodominio/pornomegrupo?nome=${nome}`;
            return this.http.get(url, { headers: this.getHeaders() }).toPromise().then(response => {
                this.cacheService.set(cacheKey, response); // Armazena no cache
                return response;
            });
        });
    }
}
