import { Component, OnInit } from "@angular/core";
import { ElementoGrupoDominioLocalService } from "../../../service/elementogrupodominio.local.service";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { DadoVocacional } from "src/app/model/dadovocacional.model";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { Usuario } from "src/app/model/usuario.model";
import { QuestionarioFormandoService } from "../service/questionarioformando.service";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { ActivatedRoute, Router } from "@angular/router";
import { PessoaService } from "../../pessoa/service/pessoa.service";
import { ElementoGrupoDominioService } from "../../../service/elementogrupodominio.service";
import { DadoVocacionalService } from "../../dadosvocacionais/service/dadovocacional.service";
import { UsuarioService } from "../../usuario/service/usuario.service";
import {
  MissaoConfiguracaoService,
  QuestionarioConfiguracao,
} from "../service/configuracao-questionario.service";

@Component({
  selector: "app-questionarios",
  templateUrl: "./questionarios.component.html",
  styleUrls: ["./questionarios.component.css"],
})
export class QuestionariosComponent implements OnInit {
  public pessoa: Pessoa;
  public niveisFormacao: Array<ElementoGrupoDominio>;
  public dadoVocacional: DadoVocacional;
  public formasVida: Array<ElementoGrupoDominio>;
  public formaVidaSelecionada: ElementoGrupoDominio;
  public usuarioAtual: Usuario;
  public pessoaId: number;
  public nivelFormacao: string;
  public formaVida: string;
  public verificouDados: boolean;
  public temQuestionarioVinculado: boolean;
  public questionarioFormando: QuestionarioFormando;

  questionarioConfiguracao: QuestionarioConfiguracao;

  constructor(
    private missaoConfiguracaoService: MissaoConfiguracaoService,
    public pessoaService: PessoaService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public pessoaBuilder: PessoaBuilder,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public dadoVocacionalService: DadoVocacionalService,
    public questionarioFormandoService: QuestionarioFormandoService,
    public swtAlert2Service: SwtAlert2Service,
    public usuarioService: UsuarioService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.niveisFormacao = new Array<ElementoGrupoDominio>();
    this.formasVida = new Array<ElementoGrupoDominio>();
    this.pessoa = this.pessoaBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.nivelFormacao = "";
    this.formaVida = "";
    this.verificouDados = false;
    this.temQuestionarioVinculado = false;
  }

  ngOnInit() {
    this.initialization();
  }

  async initialization(): Promise<any> {
    await this.getUsuarioAtual();
    this.carregarNiveisFormacao();
    await this.carregarPessoa();
    await this.carregarDadoVocacional();
    await this.carregarQuestionarios();
    this.carregarQuestionarioConfiguracao();
  }

  carregarQuestionarioConfiguracao() {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.missaoConfiguracaoService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        3
        // this.questionarioFormando.questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  carregarNiveisFormacao(): void {
    let busca = {
      grupoNome: "NIVEL_FORMACAO",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(busca)
      .then((lista: any) => {
        this.niveisFormacao = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  async carregarDadoVocacional(): Promise<any> {
    try {
      let response = await this.dadoVocacionalService.porPessoaId(
        this.pessoa.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.dadoVocacional = response.entity;
        this.nivelFormacao = this.dadoVocacional.nivelFormacaoNome;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarios(): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorPessoaId(
        this.pessoa.id
      );
      if (response != undefined && response != null) {
        this.questionarioFormando = response;
        if (this.questionarioFormando.questionarioId == 3)
          this.temQuestionarioVinculado = true;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoQuestionarioCAL(): Promise<any> {
    try {
      this.questionarioFormando.anoFormativoId = 2;
      this.questionarioFormando.questionarioId = 3;
      this.questionarioFormando.pessoaId = this.pessoa.id;
      let response = await this.questionarioFormandoService.cadastrar(
        this.questionarioFormando
      );
      if (response != undefined && response != null) {
        this.questionarioFormando = response;
        this.router.navigate([
          "/secured/questionariocal-answer/" + this.questionarioFormando.id,
        ]);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoQuestionarioD2(): Promise<any> {
    try {
      this.questionarioFormando.anoFormativoId = 2;
      this.questionarioFormando.questionarioId = 1;
      this.questionarioFormando.pessoaId = this.pessoa.id;
      let response = await this.questionarioFormandoService.cadastrar(
        this.questionarioFormando
      );
      if (response != undefined && response != null) {
        this.questionarioFormando = response;
        this.router.navigate([
          "/secured/questionariod2-answer/" + this.questionarioFormando.id,
        ]);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuarioAtual.id
      );

      this.pessoa = response.entity;

      this.pessoaId = this.pessoa.id;
      if (this.pessoa.formaVidaId != null) {
        this.setarFormaVidaSelecionada();
        this.formaVida = this.pessoa.formaVidaValor;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  setarFormaVidaSelecionada(): void {
    for (let i = 0; i < this.formasVida.length; i++) {
      if (this.formasVida[i].id == this.pessoa.formaVidaId) {
        this.formaVidaSelecionada = this.formasVida[i];
      }
    }
  }
}
