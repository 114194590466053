import { NgModule } from '@angular/core';
import { PessoaEditComponent } from './pessoa.edit.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PessoaRoutingModule } from './pessoa-routing.module';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { PessoaService } from './service/pessoa.service';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagemService } from '../../service/imagem.service';
import { EnderecoService } from '../../service/endereco.service';
import { DocumentoService } from '../../service/documento.service';
import { AptidaoService } from '../../service/aptidao.service';
import { EscolaridadeService } from '../../service/escolaridade.service';
import { TrabalhoService } from '../../service/trabalho.service';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { PessoaInfoBuilder } from 'src/app/builder/pessoainfo.builder';

 @NgModule({
     declarations:[
         PessoaEditComponent
     ],
     imports:[
         CommonModule,
         RouterModule,
         PessoaRoutingModule,
         FormsModule,
         SecuredModule,
         IgxTabsModule,
         ImageCropperModule
     ],
     providers:[
         PessoaService,
         EnderecoService,
         ImagemService,
         DocumentoService,
         AptidaoService,
         EscolaridadeService,
         TrabalhoService,
         PessoaBuilder,
         PessoaInfoBuilder
     ]
 })
 export class PessoaModule{
     
 }