
import { Component, OnInit } from '@angular/core';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { DadosDetalhamentoMissionarioMissaoAtual } from 'src/app/model/dadosdetalhamentomissionariomissaoatual.model';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { QuestionarioFormandoService } from '../questionario/service/questionarioformando.service';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { ParecerResponsavelBuilder } from 'src/app/builder/parecerresponsavel.builder';
import { ParecerResponsavelService } from './service/parecerresponsavel.service';
import { ImagemService } from '../../service/imagem.service';
import { PessoaService } from '../pessoa/service/pessoa.service';
import { CasaComunitariaService } from '../casacomunitaria/service/casacomunitaria.service';
import { MissionarioMissaoAtualService } from '../dadosvocacionais/service/missionariomissaoatual.service';

@Component({
  selector: 'app-parecer',
  templateUrl: './parecer.component.html',
  styleUrls: []
})
export class ParecerComponent implements OnInit {
  public dadosUsuarioLogado: DadosDetalhamentoMissionarioMissaoAtual;
  public casaComunitariaId: number;
  public casaComunitaria: CasaComunitaria;
  public pessoaBuilder : PessoaBuilder;  
  public busca: any;
  public totalDePaginas: number;

  public membrosCasaComunitaria: Array<any>;
  public d2sCasaComunitaria: Array<any>;
  public questionarioFormando:QuestionarioFormando;
  public parecerResponsavel:ParecerResponsavel;  

  constructor(
    public imagemService: ImagemService,     
    public pessoaService: PessoaService,
    public casaComunitariaService: CasaComunitariaService, 
    public missionarioMissaoAtualService: MissionarioMissaoAtualService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,    
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ){
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCasaComunitaria = new Array<any>();  
    this.d2sCasaComunitaria = new Array<any>(); 
    this.busca = {
      casaComunitariaId: null,
      formaVidaId: null,
      pageNumber: 0,
      quantityOfElements : 40
    };
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }  

  ngOnInit(): void {
    this.initializer();
  }

  async initializer() : Promise<any> {
    await this.carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado();
    await this.carregarCasaComunitaria();
    if(this.casaComunitaria.id != undefined)
    {   
      await this.carregarMembrosCasaComunitaria();
    }        
  }

  async carregarMembrosCasaComunitaria() : Promise<any> {
    try
    {
      this.busca.casaComunitariaId = this.casaComunitaria.id;
      // this.busca.formaVidaId = 17; // Comunidade de Vida
      let response = await this.pessoaService.buscarMembrosCasaComunitaria(this.busca);
      this.membrosCasaComunitaria = response.entity;
      this.totalDePaginas = response.quantity;

      for(let i = 0; i < this.membrosCasaComunitaria.length ; i++)
      {
        if(this.membrosCasaComunitaria[i].nivelFormativoNome == 'D2'|| this.membrosCasaComunitaria[i].nivelFormativoNome == 'D2 Ext.'){
          if(this.membrosCasaComunitaria[i].imagemId != null)
          {
            let blogImage = await this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId);

            this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
          }
          let questionarioFormando:QuestionarioFormando = await this.carregarQuestionario(this.membrosCasaComunitaria[i].pessoaId);
          
          if(questionarioFormando != null){
            this.membrosCasaComunitaria[i].statusQuestionario = questionarioFormando.status;
            this.membrosCasaComunitaria[i].idQuestionario = questionarioFormando.id;

            let parecerResponsavelAtual:ParecerResponsavel = await this.carregarParecer(questionarioFormando.id);
            if(parecerResponsavelAtual != null){
              this.membrosCasaComunitaria[i].statusParecer = parecerResponsavelAtual.status;
              this.membrosCasaComunitaria[i].idParecer = parecerResponsavelAtual.id;
            } else {
              this.membrosCasaComunitaria[i].statusParecer = 'Não iniciado';
              this.membrosCasaComunitaria[i].idParecer = null;
            }
          }
          if(questionarioFormando == null){
            this.membrosCasaComunitaria[i].statusQuestionario = 'Não iniciado';
            this.membrosCasaComunitaria[i].idQuestionario = null;
          }
          
          this.d2sCasaComunitaria.push(this.membrosCasaComunitaria[i]);
          console.log(this.d2sCasaComunitaria);          
        }     
      }
    }
    catch(err)
    {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }


  async getPessoaImagem(id:number): Promise<any> {
    try
    {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    }
    catch(err)
    {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado() : Promise<any> {
    try 
    {
      let response = await this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado();
      this.dadosUsuarioLogado = response;

      if(this.casaComunitariaId == undefined || this.casaComunitariaId == 0)
      {
        this.casaComunitariaId = this.dadosUsuarioLogado.casaComunitariaId;
      }            
    }
    catch(e)
    {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarCasaComunitaria() : Promise<any> {
    try 
    {
      if(this.casaComunitariaId != undefined)        
      {
        let response = await this.casaComunitariaService.find(this.casaComunitariaId);
        this.casaComunitaria = response.entity;  
      }
    }
    catch(e)
    {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarQuestionario(pessoaId:number) : Promise<any> 
  {
    try 
    {
      let response = await this.questionarioFormandoService.buscarPorPessoaId(pessoaId);
      if(response != undefined && response != null)
      {
        return response;
      }          
    } 
    catch (err) 
    {
      this.swtAlert2Service.errorAlert(err.error.errors)
    }
  }  

  async carregarParecer(idQuestionario:number) : Promise<any> 
  {
    try 
    {      
      let response = await this.parecerResponsavelService.buscarPorQuestionarioFormandoId(idQuestionario);
      return response;
    } 
    catch (err) 
    {
      this.swtAlert2Service.errorAlert(err.error.errors)
    }
  }  

  async cadastrarVinculoQuestionarioD2(pessoaId:number) : Promise<any>  
  {
    try 
    {
      this.questionarioFormando.anoFormativoId = 2;
      this.questionarioFormando.questionarioId = 1;
      this.questionarioFormando.pessoaId = pessoaId;
      let response = await this.questionarioFormandoService.cadastrar(this.questionarioFormando);
      if(response != undefined && response != null)
      {
        this.questionarioFormando = response;
        this.cadastrarVinculoParecerD2(this.questionarioFormando.id);
      }
    } 
    catch (err) 
    {
      this.swtAlert2Service.errorAlert(err.error.errors)
    }
  }

  async cadastrarVinculoQuestionarioD2Ext(pessoaId:number) : Promise<any>  
  {
    try 
    {
      this.questionarioFormando.anoFormativoId = 2;
      this.questionarioFormando.questionarioId = 2;
      this.questionarioFormando.pessoaId = pessoaId;
      let response = await this.questionarioFormandoService.cadastrar(this.questionarioFormando);
      if(response != undefined && response != null)
      {
        this.questionarioFormando = response;
        this.cadastrarVinculoParecerD2(this.questionarioFormando.id);
      }
    } 
    catch (err) 
    {
      this.swtAlert2Service.errorAlert(err.error.errors)
    }
  }  

  async cadastrarVinculoParecerD2(questionarioFormandoId:number) : Promise<any>  
  {
    try 
    {
      this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
      this.parecerResponsavel.parecer_id = 1;
      this.parecerResponsavel.pessoaResponsavelId = this.dadosUsuarioLogado.pessoaId;
      let response = await this.parecerResponsavelService.cadastrar(this.parecerResponsavel);
      if(response != undefined && response != null)
      {
        this.parecerResponsavel = response;
        this.router.navigate(['/secured/parecerd2-answer/' + this.parecerResponsavel.id]);
      }
    } 
    catch (err) 
    {
      this.swtAlert2Service.errorAlert(err.error.errors)
    }
  }  

  async consultarVinculoQuestionarioD2(formando:any) : Promise<any>  
  {
    try 
    {
      if(formando.idQuestionario != null){
        let response = await this.parecerResponsavelService.buscarPorQuestionarioFormandoId(formando.idQuestionario);
        if(response != undefined && response != null)
        {
          this.parecerResponsavel = response;
          this.router.navigate(['/secured/parecerd2-answer/' + this.parecerResponsavel.id]);
        }  
        else {
          this.cadastrarVinculoParecerD2(formando.idQuestionario);
        }
      }
      if(formando.idQuestionario == null){
        this.cadastrarVinculoQuestionarioD2(formando.pessoaId);              
      }      
    } 
    catch (err) 
    {
      this.swtAlert2Service.errorAlert(err.error.errors)
    }
  }  
  
  cliqueNoBotaoListener(botao) {
    this.busca.pageNumber = botao.numero-1;
    this.carregarMembrosCasaComunitaria();
  }
}