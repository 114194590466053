import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { ParecerResponsavelBuilder } from "src/app/builder/parecerresponsavel.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { Celula } from "src/app/model/celula.model";
import { FormadorComunitario } from "src/app/model/formadorcomunitario.model";
import { MembroComunidade } from "src/app/model/membro.comunidade.model";
import { ParecerResponsavel } from "src/app/model/parecerresponsavel.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { Usuario } from "src/app/model/usuario.model";
import { UsuarioService } from "../../usuario/service/usuario.service";
import { ImagemService } from "src/app/secured/service/imagem.service";
import { PessoaService } from "../../pessoa/service/pessoa.service";
import { CelulaService } from "../../celula/service/celula.service";
import { MembroComunidadeService } from "src/app/secured/service/membro.comunidade.service";
import { FormadorComunitarioService } from "src/app/secured/service/formador.comunitario.service";
import { QuestionarioFormandoService } from "../service/questionarioformando.service";
import { FormadorAssistenteService } from "src/app/secured/service/formador.assist.service";
import { CoordenadorColegiadoService } from "src/app/secured/service/coordenadorcolegiado.service";
import { DiscernimentoService } from "src/app/secured/service/discernimento.service";
import {
  MissaoConfiguracaoService,
  QuestionarioConfiguracao,
} from "../service/configuracao-questionario.service";
import { CaminhoFormativo } from "src/app/model/caminhoformativo.model";
import { CaminhoFormativoService } from "../service/caminhoformativo.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArquivoService } from "src/app/secured/service/arquivo.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ParecerResponsavelService } from "../../parecer/service/parecerresponsavel.service";
import { FormadorAssistente } from "src/app/model/formadorassistente.model";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { ElementoGrupoDominioService } from "src/app/secured/service/elementogrupodominio.service";

@Component({
  selector: "app-parecer-formador",
  templateUrl: "./parecer.formador.celula.component.html",
  styleUrls: [],
})
export class ParecerFormadorCelulaComponent implements OnInit {
  unsubscribe$ = new Subject<null>();

  public usuario: Usuario;
  public pessoa: Pessoa;
  public celula: Celula;
  public formadorComunitario: FormadorComunitario;
  public formadorAssistente: FormadorAssistente;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;
  public questionariosCelula: Array<any>;
  public pessoaBuilder: PessoaBuilder;
  public niveisFormacao: Array<ElementoGrupoDominio>;

  public totalDePaginas: number = 1;

  public questionarioId: number;
  public questionarioFormandoSelectedId: number;
  public questionarioFormandoSelectedNome: number;
  public questionarioSelectedId: number;
  public pedidoFormandoId: number;
  public buscaMembros: any;
  public buscaQuestionarioFormando: any;
  public totalDePaginasMembros: number;
  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;
  public caminhoFormativo: CaminhoFormativo;

  public idsPPs = [25];
  public idsPDs = [30, 31, 108, 204, 205];
  public idsD1s = [24];
  public idsPostulantes = [22, 23];
  public idsRenovacao = [26, 27, 28, 29, 30, 31, 108, 204];

  selectedFileCarta: File | null = null;
  selectedFileArquivo: File | null = null;
  errorMessage: string = "";
  isFormValidUpload: boolean = false;

  public sanitizedPdfUrl: SafeResourceUrl;
  public sanitizedPdfCartaUrl: SafeResourceUrl;
  @ViewChild("pdfViewer", { static: false })
  pdfViewer: ElementRef;  

  constructor(
    private missaoConfiguracaoService: MissaoConfiguracaoService,
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public formadorAssistenteService: FormadorAssistenteService,
    public formadorComunitarioService: FormadorComunitarioService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public caminhoFormativoService: CaminhoFormativoService,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
    this.buscaMembros = {
      celulaId: 0,
      pageNumber: 0,
      quantityOfElements: 0,
    };
    this.buscaQuestionarioFormando = {
      questionarioId: null,
      casaCelulaId: null,
      pessoaId: null,
      pageNumber: 0,
      quantityOfElements: 10,
    };
  }

  async initializer(): Promise<any> {
    this.questionarioId = Number(this.route.snapshot.paramMap.get("id"));
    this.carregarNivelFormacao();
    await this.carregarUsuario();
    await this.carregarPessoa();
    await this.carregarFormadorComunitario();
    if (
      this.formadorComunitario != undefined &&
      this.formadorComunitario.casaCelulaId != null
    ) {
      await this.carregarCelula(this.formadorComunitario.casaCelulaId);
      this.buscaMembros = {
        celulaId: this.celula.id,
        pageNumber: 0,
        quantityOfElements: 4,
      };
      this.buscaQuestionarioFormando = {
        questionarioId: this.questionarioId,
        casaCelulaId: this.celula.id,
        pessoaId: null,
      };
      await this.carregarMembrosCelula();
    } else {
      await this.carregarFormadorAssistente();
      if (
        this.formadorAssistente != undefined &&
        this.formadorAssistente.casaCelulaId != null
      ) {
        await this.carregarCelula(this.formadorAssistente.casaCelulaId);
        this.buscaMembros = {
          celulaId: this.celula.id,
          pageNumber: 0,
          quantityOfElements: 4,
        };
        this.buscaQuestionarioFormando = {
          questionarioId: this.questionarioId,
          casaCelulaId: this.celula.id,
          pessoaId: null,
        };
        await this.carregarMembrosCelula();
      } else this.hasCelulaAssociada = false;
    }
  }

  /*carregarQuestionarioConfiguracao(questionarioId: number) {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.missaoConfiguracaoService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }*/

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarFormadorComunitario(): Promise<any> {
    try {
      let response =
        await this.formadorComunitarioService.buscarCelulaPorPessoaId(
          this.pessoa.id
        );
      this.formadorComunitario = response;

      if (
        this.formadorComunitario &&
        this.formadorComunitario.pessoaImagemId != null
      ) {
        let blogImage = await this.getPessoaImagem(
          this.formadorComunitario.pessoaImagemId
        );

        this.imagemService.createImage(blogImage, this.formadorComunitario);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async carregarFormadorAssistente(): Promise<any> {
    try {
      let response =
        await this.formadorAssistenteService.buscarCelulaPorPessoaId(
          this.pessoa.id
        );
      this.formadorAssistente = response;

      if (
        this.formadorAssistente &&
        this.formadorAssistente.pessoaImagemId != null
      ) {
        let blogImage = await this.getPessoaImagem(
          this.formadorAssistente.pessoaImagemId
        );

        this.imagemService.createImage(blogImage, this.formadorAssistente);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async carregarNivelFormacao(): Promise<any> {
    let request = {
      grupoNome: "NIVEL_FORMACAO",
    };
    try {
      let response =
        await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(
          request
        );
      this.niveisFormacao = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarCelula(celulaId: number): Promise<any> {
    try {
      let response = await this.celulaService.find(celulaId);
      this.celula = response.entity;
    } catch (e) {
      console.log(e);
    }
  }


  async carregarMembrosCelula(): Promise<any> {
    try {
      let response = await this.membroService.listarTodosMembrosCelula(
        this.celula.id,
        this.buscaMembros.pageNumber
      );
      this.membrosCelula = response.content;
      this.totalDePaginasMembros = response.totalPages;
      this.buscaMembros.quantityOfElements = response.quantityOfElements;

      const idMembros = this.membrosCelula.map(membro => membro.id);

      await this.carregarQuestionarioCelula(idMembros);

      for (let i = 0; i < this.membrosCelula.length; i++) {
        let response = await this.pessoaService.buscaPorId(
          this.membrosCelula[i].pessoaId
        );
        let pessoa = response.entity;
        this.membrosCelula[i].pessoaNome = pessoa.nome + " " + pessoa.sobrenome;
        this.membrosCelula[i].pessoaImagemId = pessoa.imagemId;
        this.membrosCelula[i].vinculoMissao = pessoa.vinculoMissao;
        if (this.membrosCelula[i].pessoaImagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.membrosCelula[i].pessoaImagemId
          );
          this.imagemService.createImage(blogImage, this.membrosCelula[i]);
        }

        let nivelFormativoMembro = this.niveisFormacao.find(
          (obj) => obj.id === this.membrosCelula[i].nivelFormativoId
        );
        this.membrosCelula[i].nivelFormativoNome = nivelFormativoMembro
          ? nivelFormativoMembro.nome
          : null;

        const questionario = this.questionariosCelula.filter(
          (questionarioFormando) =>
            questionarioFormando.id === this.membrosCelula[i].id 
        );

        if (questionario.length > 0) {
          this.membrosCelula[i].statusQuestionario =
            questionario[0].status_questionario;
          this.membrosCelula[i].questionarioFormandoId =
            questionario[0].questionarioFormandoId;
          this.membrosCelula[i].questionarioId = questionario[0].questionarioId;
          this.membrosCelula[i].questionarioNome = this.buscarNomeQuestionario(this.membrosCelula[i].questionarioId);
          this.membrosCelula[i].statusParecerFC =
            questionario[0].status_parecer_fc;
          this.membrosCelula[i].idParecerFC = questionario[0].parecerIdFC;
          this.membrosCelula[i].acompanhadoPor = questionario[0].acompanhadoPor;
          this.membrosCelula[i].acompanhadoPorNome =
            questionario[0].acompanhadoPorNome;
          this.membrosCelula[i].arquivo = questionario[0].arquivo;
          this.membrosCelula[i].dataEnvioParecerFC =
            questionario[0].dataEnvioParecerFC;
          this.membrosCelula[i].dataEnvioQuestionario =
            questionario[0].dataEnvioQuestionario;
          this.membrosCelula[i].missaoAtualId = questionario[0].missaoAtualId;
          this.membrosCelula[i].carta = questionario[0].carta;
        }
      }

      if (this.membrosCelula.length > 0) {
        this.membrosCelula = this.membrosCelula.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }
      console.log(this.membrosCelula);
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarioCelula(idMembros:any): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorMembrosId(idMembros);
      this.questionariosCelula = response;
    } catch (e) {
      console.log(e);
    }
  }

  async consultarCaminhoFormativo(formando: any): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        formando.pessoaId
      );
      if (response != null && response != undefined) {
        this.caminhoFormativo = response;
      } else {
        this.caminhoFormativo = {} as CaminhoFormativo;
        this.caminhoFormativo.pessoaId = formando.pessoaId;
        this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
        this.caminhoFormativo.ano_formativo_id = 2;
        this.caminhoFormativo.nome_formador_comunitario =
        this.pessoa.nome + ' ' + this.pessoa.sobrenome;
        let response = await this.caminhoFormativoService.cadastrar(
          this.caminhoFormativo
        );

        this.caminhoFormativo = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoQuestionarioCAL(formando: any): Promise<any> {
    try {
      await this.consultarCaminhoFormativo(formando);
      let responsePorPessoa =
        await this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(
          formando.pessoaId,
          formando.questionarioId
        );
      if (
        responsePorPessoa != undefined &&
        responsePorPessoa != null &&
        responsePorPessoa.questionarioId == formando.questionarioId
      ) {
        this.questionarioFormando = responsePorPessoa;
        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
        //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
      } else {
        this.questionarioFormando.anoFormativoId = 2;
        this.questionarioFormando.questionarioId = formando.questionarioId;
        this.questionarioFormando.pessoaId = formando.pessoaId;
        let response = await this.questionarioFormandoService.cadastrar(
          this.questionarioFormando
        );
        if (response != undefined && response != null) {
          this.questionarioFormando = response;
          this.questionarioFormandoSelectedId = this.questionarioFormando.id;
          formando.questionarioFormandoId = this.questionarioFormando.id;
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoParecerCAL(
    questionarioFormandoId: number
  ): Promise<any> {
    try {
      this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
      this.parecerResponsavel.parecer_id = 3;
      this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
      this.parecerResponsavel.tipo = "FC";
      let response = await this.parecerResponsavelService.cadastrar(
        this.parecerResponsavel
      );
      if (response != undefined && response != null) {
        this.parecerResponsavel = response;
        this.router.navigate([
          "/secured/parecer-cal-answer-default" +
            "/" +
            this.parecerResponsavel.id,
        ]);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  
  buscarNomeQuestionario(idQuestionario: number): string {
    let nomeQuestionario = '';
    if(idQuestionario)
    {
      switch (idQuestionario) {
        case 4:
          nomeQuestionario = 'Pedido para as Primeiras Promessas - CA';
          break;      
        case 5:
          nomeQuestionario = 'Pedido para as Promessas Definitivas - CA';
          break;  
        case 6:
          nomeQuestionario = 'Postulantes/Ingresso no Discipulado - CA';
          break;      
        case 7:
          nomeQuestionario = 'Discipulos de Primeiro Ano - CA';
          break;
        case 8:
          nomeQuestionario = 'Renovação das Promessas - CA';
          break;                        
        default:
          nomeQuestionario = null;
          break;
      }
    }
    return nomeQuestionario;
  }
  
  async consultarVinculoQuestionarioCAL(formando: any): Promise<any> {
    console.log(formando);
    try {
      if (
        formando.questionarioFormandoId != undefined &&
        formando.questionarioFormandoId != null
      ) {
        if (formando.idParecerFC != undefined && formando.idParecerFC != null) {
          this.router.navigate([
            "/secured/parecer-cal-answer-default" + "/" + formando.idParecerFC,
          ]);
        } else {
          this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId);
        }
      } else if (
        formando.questionarioFormandoId == undefined ||
        formando.questionarioFormandoId == null
      ) {
        await this.cadastrarVinculoQuestionarioCAL(formando);
        await this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarArquivoPDF(arquivoPDFNome: string): Promise<any> {
    const blobResponse = await this.arquivoService
      .obterDetalhesArquivoPDF(arquivoPDFNome)
      .toPromise();

    const url = URL.createObjectURL(blobResponse);
    this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
 
  async configModalUploadQuestionario(formando: any): Promise<any> {    
    this.questionarioFormandoSelectedNome = formando.pessoaNome;
    this.questionarioSelectedId = formando.questionarioId;
    if (
      formando.questionarioFormandoId != undefined &&
      formando.questionarioFormandoId != null
    ) {
      this.questionarioFormandoSelectedId = formando.questionarioFormandoId;      
    } else {
      await this.cadastrarVinculoQuestionarioCAL(formando);
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
    if (
      file &&
      file.type === "application/pdf" &&
      file.size <= 3 * 1024 * 1024
    ) {
      this.selectedFileArquivo = file;
      this.errorMessage = "";
    } else {
      this.selectedFileArquivo = null;
      this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
    }
    this.checkFormUploadValidity();
  }

  onUpload() {
    if (this.selectedFileArquivo && this.pedidoFormandoId) {
      this.questionarioFormandoService
        .uploadQuestionario(
          this.questionarioFormandoSelectedId,
          this.pedidoFormandoId,
          this.selectedFileArquivo
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => {
            this.swtAlert2Service.successAlert(data);
            this.carregarMembrosCelula();
            $("#modal-upload-questionario").modal("hide");
          },
          error: (error) => {
            this.swtAlert2Service.errorAlert(
              "Erro ao realizar o upload do arquivo."
            );
          },
          complete: () => {
            this.unsubscribe$.next();
          },
        });
      this.pedidoFormandoId = null;
      this.questionarioFormandoSelectedId = null;
      this.questionarioFormandoSelectedNome = null;
    } else {
      if (!this.pedidoFormandoId) {
        this.errorMessage =
          "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
      } else {
        this.errorMessage =
          "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
      }
    }
  }

  onTipoPedidoChange() {
    this.checkFormUploadValidity();
  }

  // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
  checkFormUploadValidity() {
    this.isFormValidUpload = !!this.pedidoFormandoId && !!this.selectedFileArquivo;
  }

  /**
   * CARTA
   */

  async configModalUploadCarta(formando: any): Promise<any> {
    this.questionarioFormandoSelectedNome = formando.pessoaNome;    
    if (
      formando.questionarioFormandoId != undefined &&
      formando.questionarioFormandoId != null
    ) {
      this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
    } else {
      await this.cadastrarVinculoQuestionarioCAL(formando);
    }
  }

  async carregarCartaPDF(arquivoPDFNome: string): Promise<any> {
    const blobResponse = await this.arquivoService
      .obterDetalhesArquivoPDF(arquivoPDFNome)
      .toPromise();

    const url = URL.createObjectURL(blobResponse);
    this.sanitizedPdfCartaUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onCartaFileSelected(event: any) {
    const file: File = event.target.files[0];
    // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
    if (
      file &&
      file.type === "application/pdf" &&
      file.size <= 3 * 1024 * 1024
    )  {
      this.selectedFileCarta = file;
      this.errorMessage = "";
    } else {
      this.selectedFileCarta = null;
      this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
    }
  }

  onCartaUpload() {
    if (this.selectedFileCarta) {
      this.questionarioFormandoService
        .uploadCarta(this.questionarioFormandoSelectedId, this.selectedFileCarta)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => {
            this.swtAlert2Service.successAlert(data);
            this.carregarMembrosCelula();
            $("#modal-upload-carta").modal("hide");
          },
          error: (error) => {
            this.swtAlert2Service.errorAlert(
              "Erro ao realizar o upload do arquivo."
            );
          },
          complete: () => {
            this.unsubscribe$.next();
          },
        });
        this.questionarioFormandoSelectedId = null;
        this.questionarioFormandoSelectedNome = null;
      } else {
        this.errorMessage =
          "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
      }
  }

  cliqueNoBotaoListener(botao) {
    this.buscaMembros.pageNumber = botao.numero - 1;
    this.carregarMembrosCelula();
  }
}
