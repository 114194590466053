<body class="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed" style="height:auto;">
    <div class="wrapper">
        <header-comp></header-comp>
        <div #missionario>
            <left-sidebar-missionario-comp></left-sidebar-missionario-comp>
        </div>
        <div #formadorAssistente>
            <left-sidebar-formador-assistente-comp></left-sidebar-formador-assistente-comp>
        </div>
        <div #formadorComunitario>
            <left-sidebar-formador-comunitario-comp></left-sidebar-formador-comunitario-comp>
        </div>
        <div #formadorComunitarioCasa>
            <left-sidebar-formador-comunitario-casa-comp></left-sidebar-formador-comunitario-casa-comp>
        </div>
        <div #coordenadorColegiado>
            <left-sidebar-coord-colegiado-comp></left-sidebar-coord-colegiado-comp>
        </div>
        <div #conselho>
            <left-sidebar-conselho-comp></left-sidebar-conselho-comp>
        </div>
        <div #comissaoDiscernimento>
            <left-sidebar-comissao-discernimento-comp></left-sidebar-comissao-discernimento-comp>
        </div>
        <div #acessoNegado>
            <left-sidebar-acesso-negado-comp></left-sidebar-acesso-negado-comp>
        </div>
        <span #ref>
            <ng-content></ng-content>
        </span>
        <span *ngIf="!ref.innerHTML.trim()">
            <div class="content-wrapper">
            </div>
        </span>
        <footer-comp></footer-comp>
    </div>
</body>