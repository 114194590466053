import { NgModule } from '@angular/core';
import { UsuarioComponent } from './usuario.component';
import { Routes, RouterModule } from '@angular/router';
import { UsuarioEditMembroComponent } from './tela-membro/usuario.edit.membro.component';

const usuarioRoutes: Routes = [
    {
        path: 'secured/usuario',
        component: UsuarioComponent
    },
    {
        path: 'secured/meuusuario',
        component: UsuarioEditMembroComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(usuarioRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class UsuarioRoutingModule {
    
}