import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { HistoricoMissionarioMissaoBuilder } from 'src/app/builder/historicomissionariomissao.builder';
import { Usuario } from 'src/app/model/usuario.model';
import { DadosDetalhamentoMissionarioMissaoAtual } from 'src/app/model/dadosdetalhamentomissionariomissaoatual.model';
import { UsuarioService } from '../usuario/service/usuario.service';
import { PessoaService } from '../pessoa/service/pessoa.service';
import { CasaComunitariaService } from './service/casacomunitaria.service';
import { ImagemService } from '../../service/imagem.service';
import { MissionarioMissaoAtualService } from '../dadosvocacionais/service/missionariomissaoatual.service';
import { HistoricoMissionarioMissaoService } from '../dadosvocacionais/service/historicomissionariomissao.service';


@Component({
    selector: 'casacomunitaria-local-manage',
    templateUrl: './casacomunitaria.manager.component.html'
})

export class CasaComunitariaLocalManageComponent implements OnInit {    
    
    public isLoaded: boolean;
    public componenteAdicaoMembros: boolean;

    public usuario: Usuario;
    public pessoa: Pessoa;
    public dadosDetalhamentoMissaoAtualUsuarioLogado: DadosDetalhamentoMissionarioMissaoAtual;
    public casaComunitaria: CasaComunitaria;
    public pessoaMembroSelecionada: Pessoa;
    public pessoaBuilder : PessoaBuilder;
    public casaComunitariaId: number;

    constructor(
        public usuarioService: UsuarioService,
        public pessoaService: PessoaService,
        public casaComunitariaService: CasaComunitariaService,
        public imagemService: ImagemService, 
        public missionarioMissaoAtualService: MissionarioMissaoAtualService,    
        public historicoMissionarioMissaoBuilder: HistoricoMissionarioMissaoBuilder,
        public historicoMissionarioMissaoService: HistoricoMissionarioMissaoService,   
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) {
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();    
    }

    ngOnInit() {
        this.casaComunitariaId = Number(this.route.snapshot.paramMap.get('id'));
        this.isLoaded = false;
        this.initializer();
    }

    async initializer() : Promise<any> {
        await this.carregarUsuario();
        await this.carregarPessoa();
        await this.carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado();
        if(this.dadosDetalhamentoMissaoAtualUsuarioLogado != undefined && this.dadosDetalhamentoMissaoAtualUsuarioLogado.id != undefined)
        {
            await this.carregarCasaComunitaria();
        }
        else if(this.casaComunitariaId != undefined && this.casaComunitariaId != 0)
        {
            await this.carregarCasaComunitaria();
        }
        else
        {
            this.swtAlert2Service.errorAlert("Não foi possível obter as informações da casa comunitária");
        }
        this.isLoaded = true;
    }

    async carregarUsuario() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuario = response.entity;  
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarPessoa() : Promise<any> {

        try
        {
            let response = await this.pessoaService.buscaPorUsuarioId(this.usuario.id);
            this.pessoa = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado() : Promise<any> {

        try 
        {
            let response = await this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado();
            this.dadosDetalhamentoMissaoAtualUsuarioLogado = response;

            if(this.casaComunitariaId == undefined || this.casaComunitariaId == 0)
            {
                this.casaComunitariaId = this.dadosDetalhamentoMissaoAtualUsuarioLogado.casaComunitariaId;
            }            
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarCasaComunitaria() : Promise<any> {
        try 
        {
            if(this.casaComunitariaId != undefined)        
            {
                let response = await this.casaComunitariaService.find(this.casaComunitariaId);
                this.casaComunitaria = response.entity;  
            }
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    carregamentoConcluido($event): void{
        this.isLoaded = $event;
    }

}