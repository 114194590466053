export class CriarLinkCadastroRequest {

    constructor(
        /*public tipo: number,        
        public casaCelulaId: number,*/
        public celulaId: number,
        public dataValidadeInicioCadastro: Date,
        public dataValidadeFimCadastro: Date
    )
    {}

}