export const environment = {
  production: true,
  authorization : 'Basic V09QX0ZST05URU5EX0FQUDp3b3BjYW1pbmhvZGFwYXo=',

  usuario : {
    selectPorUsername: "https://t3.shalom.tec.br/missionaria/api/secured/r/usuario/selectporusername",
    cadastroPublico: "https://t3.shalom.tec.br/missionaria/api/public/usuario/cadastrar",
    cadastroPublicoEmCelula: "https://t3.shalom.tec.br/missionaria/api/public/usuario/cadastrar/celula",
    importarDadosWop: "https://t3.shalom.tec.br/missionaria/api/public/usuario/importardadoswop"
  },

  tokengenerico : {
    findByToken: "https://t3.shalom.tec.br/formacao/tokengenerico/portoken/"
  },

  link : {
    findByToken: "https://t3.shalom.tec.br/formacao/celula/celulainfoportoken/"
  },

  grupooracao : {
    findByToken: "https://t3.shalom.tec.br/missionaria/api/public/grupooracao/portoken/"
  },

  elementoGrupoDominio : {

    porNomeGrupo: "https://t3.shalom.tec.br/missionaria/api/public/r/elementogrupodominio/pornomegrupo"

  },

  svesPageConfig: {
    getByCustomURL: "https://t3.shalom.tec.br/missionaria/api/public/svespageconfig/porurlpersonalizada",
    getImageURL: "https://t3.shalom.tec.br/missionaria/api/public/arquivoimagem/pornome/"
  },

  pessoa : {
    pessoaPorNomeUrl: "https://t3.shalom.tec.br/missionaria/api/secured/r/pessoa/pornome",
    usuarioIdPorPessoaNomeURL: "https://t3.shalom.tec.br/missionaria/api/secured/r/pessoa/usuarioidpornome"
  },

  imagem : {
    urlBaseImagem: "https://t3.shalom.tec.br/missionaria/api/secured/imagem/arquivo/",
    urlBaseImagemRemote: "https://t3.shalom.tec.br/wop/api/secured/imagem/arquivo/"
  },

  irradiacao : { 
    irradiacaoPorNomeUrl : "https://t3.shalom.tec.br/missionaria/api/secured/r/irradiacao/pornome"
  },

  missao : {
    getAllPublic : "https://t3.shalom.tec.br/missionaria/api/public/missao"
  },

  difusao : {
    getAllPublic : "https://t3.shalom.tec.br/missionaria/api/public/difusao"
  },
  
  pais : {
    getAllPublic : "https://t3.shalom.tec.br/missionaria/api/public/pais"
  },

  termoUso : {
    getTermoVigentePublic : "https://t3.shalom.tec.br/missionaria/api/public/termouso/termovigente"
  },

  termoTratamentoDados : {
    getTermoVigentePublic : "https://t3.shalom.tec.br/missionaria/api/public/termotratamentodados/termovigente"
  },

  confirmacaoCadastro: {
    confirmacaoURL: "https://t3.shalom.tec.br/wop/api/public/usuario/confirmacao?token="
  },

  esqueceuSenha: {
    recuperar: "https://t3.shalom.tec.br/wop/api/public/usuario/esqueceusenha/",
    atualizarNovaSenha: "https://t3.shalom.tec.br/wop/api/public/usuario/esqueceusenha/atualizar"
  },

  configServidor : {
    apiUrl : "https://t3.shalom.tec.br/missionaria/api/secured/",
    apiUrlNotSecured: "https://t3.shalom.tec.br/missionaria/api/",
    tokenUrl : "https://t3.shalom.tec.br/authserver/oauth/token",
    wopApiUrl : "https://t3.shalom.tec.br/wop/api/secured/"
  },

  moduloFormacao : {
    buscarCelula: "https://t3.shalom.tec.br/formacao/celula/buscar",
    atualizarCaminhoFormativo : "https://t3.shalom.tec.br/formacao/caminhoformativo",
    atualizarDadosFormandoCV : "https://t3.shalom.tec.br/formacao/dadosFormandoCV",
    atualizarStatusRespostaQuestionario: "https://t3.shalom.tec.br/formacao/",
    atualizarStatusRespostaParecer : "https://t3.shalom.tec.br/formacao/",
    cadastrarRespostaQuestionario : "https://t3.shalom.tec.br/formacao/questionarioRespostaFormando",
    cadastrarRespostaParecer : "https://t3.shalom.tec.br/formacao/parecer_resposta_responsavel",
    buscarCaminhoFormativo : "https://t3.shalom.tec.br/formacao/caminhoformativo/porpessoaid/",
    buscarDadosFormandoCV : "https://t3.shalom.tec.br/formacao/dadosFormandoCV/porpessoaid/",
    buscarRespostaQuestionario : "https://t3.shalom.tec.br/formacao/questionarioRespostaFormando/porpessoaid/",
    buscarRespostaParecer : "https://t3.shalom.tec.br/formacao/parecer_resposta_responsavel/porpessoaid/",
    cadastrarCaminhoFormativo : "https://t3.shalom.tec.br/formacao/caminhoformativo",
    cadastrarDadosFormandoCV : "https://t3.shalom.tec.br/formacao/dadosFormandoCV",
    urlModuloFormacao : "https://t3.shalom.tec.br/formacao/",
  }
  
};