import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TermoUsoBuilder } from 'src/app/builder/termouso.builder';
import { CadastroPublicoBuilder } from 'src/app/builder/cadastropublico.builder';
import { CadastroPublicoCelulaComponent } from './cadastro.publico.celula.component';
import { CadastroPublicoCelulaRoutingModule } from './cadastro-publico-celula-routing.module';
import { CadastroPublicoCelulaService } from './service/cadastro.publico.celula.service';

@NgModule({
    declarations :[
        CadastroPublicoCelulaComponent
    ],
    imports: [
        CadastroPublicoCelulaRoutingModule,
        FormsModule,        
        CommonModule,
        NgxSummernoteModule
    ],
    providers: [
        TermoUsoBuilder,
        CadastroPublicoBuilder,
        CadastroPublicoCelulaService,
        SwtAlert2Service
    ]
})
export class CadastroPublicoCelulaModule {

}