import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { DadoFormandoCA } from '../model/dadoformandoca.model';

@Injectable()
export class DadoFormandoCABuilder extends AbstractBuilder<DadoFormandoCA> {

    reset() : void {
        this.entity = new DadoFormandoCA(null,null,null,null,null, null,null,null);
    }

}