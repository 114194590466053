import { GenericService } from "src/app/core/services/generic.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class CoordenadorColegiadoService extends GenericService<any> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("coordenadorcolegiado");
  }

  obterFormadoresPorMissaoDoCoordenadorColegiado(
    page: number,
    size: number
  ): Promise<any> {
    let url = `${environment.moduloFormacao.urlModuloFormacao}coordenadorcolegiado/formadorespormissao?page=${page}&size=${size}`;
    return this.http.get<any>(url, { headers: this.getHeaders() }).toPromise();
  }
}
