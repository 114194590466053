/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./confirmacao.cadastro.component";
import * as i2 from "@angular/router";
import * as i3 from "../../../core/swtalert2/swtalert2.service";
var styles_ConfirmacaoCadastroComponent = [];
var RenderType_ConfirmacaoCadastroComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmacaoCadastroComponent, data: {} });
export { RenderType_ConfirmacaoCadastroComponent as RenderType_ConfirmacaoCadastroComponent };
export function View_ConfirmacaoCadastroComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "body", [["class", "hold-transition login-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "login-page-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "login-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "a", [["href", "../../login"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["WoP - "])), (_l()(), i0.ɵted(-1, null, ["MISSION\u00E1RIO"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "b", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["class", "login-page-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 5, "div", [["class", "callout callout-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "i", [["class", "fas fa-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0\u00A0\u00A0ATEN\u00C7\u00C3O! Para concluir o seu cadastro \u00E9 necess\u00E1rio acessar o link de confirma\u00E7\u00E3o que foi enviado para o e-mail cadastrado. "])), (_l()(), i0.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Obs.: Caso n\u00E3o encontre o e-mail na sua \"Caixa de Entrada\", verifique sua caixa de \"Spam/Lixo Eletr\u00F4nico\"!"]))], null, null); }
export function View_ConfirmacaoCadastroComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirmacaoCadastro", [], null, null, null, View_ConfirmacaoCadastroComponent_0, RenderType_ConfirmacaoCadastroComponent)), i0.ɵdid(1, 114688, null, 0, i1.ConfirmacaoCadastroComponent, [i2.Router, i3.SwtAlert2Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmacaoCadastroComponentNgFactory = i0.ɵccf("confirmacaoCadastro", i1.ConfirmacaoCadastroComponent, View_ConfirmacaoCadastroComponent_Host_0, {}, {}, []);
export { ConfirmacaoCadastroComponentNgFactory as ConfirmacaoCadastroComponentNgFactory };
