import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
export class ElementoGrupoDominioService extends GenericService {
    constructor(http, router, cacheService) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.cacheService = cacheService;
        this.setEntityType('r/elementogrupodominio');
    }
    buscarElementosGrupoDominio(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cacheKey = 'buscarElementosGrupoDominio-' + JSON.stringify(busca);
            // Verifica se os dados já estão no cache
            if (this.cacheService.has(cacheKey)) {
                return Promise.resolve(this.cacheService.get(cacheKey));
            }
            // Caso não tenha no cache, faz a requisição HTTP        
            return this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise().then(response => {
                this.cacheService.set(cacheKey, response); // Armazena no cache
                return response;
            });
        });
    }
    buscarPorGrupoDominioNome(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cacheKey = 'buscarPorGrupoDominioNome-' + JSON.stringify(busca);
            // Verifica se os dados já estão no cache
            if (this.cacheService.has(cacheKey)) {
                return Promise.resolve(this.cacheService.get(cacheKey));
            }
            // Caso não tenha no cache, faz a requisição HTTP        
            return this.http.post(this.apiURL + '/pornomegrupo', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise().then(response => {
                this.cacheService.set(cacheKey, response); // Armazena no cache
                return response;
            });
        });
    }
    getAllNotInAcessoUsuarioEstrutura(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cacheKey = 'getAllNotInAcessoUsuarioEstrutura-' + JSON.stringify(id);
            // Verifica se os dados já estão no cache
            if (this.cacheService.has(cacheKey)) {
                return Promise.resolve(this.cacheService.get(cacheKey));
            }
            // Caso não tenha no cache, faz a requisição HTTP        
            return this.http.get(`${this.apiURL}/getallnotinacessousuarioestrutura/${id}`, { 'headers': this.getHeaders() }).toPromise().then(response => {
                this.cacheService.set(cacheKey, response); // Armazena no cache
                return response;
            });
        });
    }
    getAllFromAcessoUsuarioEstrutura(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cacheKey = 'getAllFromAcessoUsuarioEstrutura-' + JSON.stringify(id);
            // Verifica se os dados já estão no cache
            if (this.cacheService.has(cacheKey)) {
                return Promise.resolve(this.cacheService.get(cacheKey));
            }
            // Caso não tenha no cache, faz a requisição HTTP        
            return this.http.get(`${this.apiURL}/getallfromacessousuarioestrutura/${id}`, { 'headers': this.getHeaders() }).toPromise().then(response => {
                this.cacheService.set(cacheKey, response); // Armazena no cache
                return response;
            });
        });
    }
}
