import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Usuario } from 'src/app/model/usuario.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { Celula } from 'src/app/model/celula.model';
import { FormadorComunitario } from 'src/app/model/formadorcomunitario.model';
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { FormadorAssistente } from 'src/app/model/formadorassistente.model';
import { CriarLinkCadastroRequest } from 'src/app/request/criarlinkcadastro.request';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { CelulaService } from './service/celula.service';
import { MembroComunidadeService } from 'src/app/secured/service/membro.comunidade.service';
import { FormadorComunitarioService } from 'src/app/secured/service/formador.comunitario.service';
import { FormadorAssistenteService } from 'src/app/secured/service/formador.assist.service';
import {CriarLinkCadastroRequestBuilder } from 'src/app/builder/criarlinkcadastro.request.builder';
import { UsuarioService } from '../usuario/service/usuario.service';
import { PessoaService } from '../pessoa/service/pessoa.service';
import { ImagemService } from 'src/app/secured/service/imagem.service';

@Component({
    selector: 'gerenciamento-celula',
    templateUrl: './gerenciamento.celula.component.html'
})
export class GerenciamentoCelulaComponent implements OnInit {

    public usuario: Usuario;
    public pessoa: Pessoa;    
    public celula: Celula;
    public formadorComunitario: FormadorComunitario;
    public membroComunidadeBuilder : MembroComunidadeBuilder;
    public membroComunidade : MembroComunidade;
    // public formadoresComunitario:  Array<FormadorComunitario>;
    public formadoresAssistente: Array<FormadorAssistente>;
    public membrosCelula: Array<MembroComunidade>;
    public buscaFCs: any;
    public buscaFAs: any;
    public buscaMembros: any;
    public totalDePaginasMembros: number;
    public listaAniversariantesMes: Array<any>;
    public isLoaded: boolean;
    public hasCelulaAssociada: boolean;
    public pessoaMembroSelecionada: Pessoa;
    public pessoaBuilder : PessoaBuilder;
    public celulaCriarLinkCadastroRequest : CriarLinkCadastroRequest;
    public tokenLinkCelulaResponse : any;  
    public celulaId:number;

    constructor(
        public usuarioService: UsuarioService,
        public pessoaService: PessoaService,
        public celulaService: CelulaService,
        public membroService: MembroComunidadeService,
        public formadorComunitarioService: FormadorComunitarioService,
        public formadorAssistenteService: FormadorAssistenteService,
        public imagemService: ImagemService,
        public celulaCriarLinkCadastroRequestBuilder: CriarLinkCadastroRequestBuilder,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) {
        // this.formadoresComunitario = new Array<FormadorComunitario>();
        this.formadoresAssistente = new Array<FormadorAssistente>();
        this.membrosCelula = new Array<MembroComunidade>();
        this.listaAniversariantesMes = new Array<any>();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
        this.celulaCriarLinkCadastroRequest = this.celulaCriarLinkCadastroRequestBuilder.getInstance();
    }

    ngOnInit() {
        this.isLoaded = false;
        this.hasCelulaAssociada = true;
        this.initializer();
    }

    async initializer() : Promise<any> {
        await this.carregarUsuario();
        await this.carregarPessoa();

        this.celulaId = Number(this.route.snapshot.paramMap.get('id'));
        console.log("Celula ID: " + this.celulaId);        
        await this.carregarFormadorComunitario()
        // if(this.formadorComunitario != undefined && this.formadorComunitario.casaCelulaId != null){
        //     await this.carregarCelula();
        //     await this.obterLinkCadastroCelula();
        //     this.buscaFCs = {
        //         celulaId : this.celula.id,
        //         pageNumber : 0, 
        //         quantityOfElements : 10
        //     };
        //     this.carregarFormadoresComunitario();
        //     this.buscaFAs = {
        //         celulaId : this.celula.id,
        //         pageNumber : 0, 
        //         quantityOfElements : 10
        //     };
        //     this.carregarFormadoresAssistente();
        //     this.buscaMembros = {
        //         celulaId : this.celula.id,
        //         pageNumber : 0, 
        //         quantityOfElements : 4
        //     };
        //     await this.carregarMembrosCelula();
        //     //await this.carregarAniversariantes();
        //     this.isLoaded = true;
        // } else this.hasCelulaAssociada=false;
    }

    async carregarUsuario() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuario = response.entity;  
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async carregarPessoa() : Promise<any> {

        try
        {
            let response = await this.pessoaService.buscaPorUsuarioId(this.usuario.id);
            this.pessoa = response.entity;

            if(this.pessoa.imagemId != null)
            {
                let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

                this.imagemService.createImage(blogImage, this.pessoa);
            }   

        }
        catch(e)
        {
            console.log(e);
        }

    }

    async carregarFormadorComunitario() : Promise<any> {

        try
        {
            let response = await this.formadorComunitarioService.buscarCelulaPorPessoaId(this.pessoa.id);
            this.formadorComunitario = response;

            if(this.formadorComunitario.pessoaImagemId != null)
            {
                let blogImage = await this.getPessoaImagem(this.formadorComunitario.pessoaImagemId);

                this.imagemService.createImage(blogImage, this.formadorComunitario);
            }   

        }
        catch(e)
        {
            console.log(e);
        }

    }

    async carregarCelula() : Promise<any> {

        try
        {
            let response = await this.celulaService.find(this.celulaId);
            this.celula = response.entity;
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async carregarFormadoresComunitario() : Promise<any> {

        try
        {
            this.formadorComunitario = await this.formadorComunitarioService.buscarCelulaPorId(this.celula.id);
            
            if(this.formadorComunitario.pessoaImagemId != null)
            {
                let blogImage = await this.getPessoaImagem(this.formadorComunitario.pessoaImagemId);
                this.imagemService.createImage(blogImage, this.formadorComunitario);
            }
            
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async carregarFormadoresAssistente() : Promise<any> {

        try
        {
            let response = await this.formadorAssistenteService.buscarCelulaPorId(this.celula.id);
            this.formadoresAssistente = response;

            for(let i = 0; i < this.formadoresAssistente.length; i++)
            {
                if(this.formadoresAssistente[i].pessoaImagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.formadoresAssistente[i].pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadoresAssistente[i]);
                }
            }

        }
        catch(e)
        {
            console.log(e);
        }

    }

    async carregarAniversariantes() : Promise<any> {

        try
        {
            let response = await this.membroService.aniversariantesCelula(this.celula.id);
            this.listaAniversariantesMes = response.entity;

            for(let i = 0; i < this.listaAniversariantesMes.length; i++)
            {
                if(this.listaAniversariantesMes[i].pessoaImagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.listaAniversariantesMes[i].pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.listaAniversariantesMes[i]);
                }
            }

        }
        catch(e)
        {
            console.log(e);
        }

    }

    /*** MEMBROS ***/

    async selecionarMembro(pessoa): Promise<any> {
        this.membroComunidade.pessoaId = pessoa.id;
        this.pessoaMembroSelecionada = pessoa;
        $('#listagem-pessoa-membro').modal('hide');
    }

    async salvarMembro() : Promise<any> {
        try
        {
            this.membroComunidade.casaCelulaId = this.celula.id;

            if(this.membroComunidade.dataEntrada != null && this.membroComunidade.pessoaId != null)
            {
                let response = await this.membroService.create(this.membroComunidade);
                this.swtAlert2Service.successAlert(response.message);
                this.membroComunidade = this.membroComunidadeBuilder.getInstance();
                this.limparSelect2('nmembro_pessoa');
                this.carregarMembrosCelula();
            }
            else
            {
                if(this.membroComunidade.pessoaId == null)
                {
                    this.swtAlert2Service.errorAlert("O campo pessoa é obrigatório.");
                }
                else if(this.membroComunidade.dataEntrada == null)
                {
                    this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                }
                
            }       
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async atualizarMembro(membro) : Promise<any> {
        try
        {

            let response = await this.membroService.update(membro.id,membro);
            this.swtAlert2Service.successAlert(response.message);
            this.carregarMembrosCelula();
                  
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async removerMembro(membroId) : Promise<any> {

        try
        {

            if(confirm("Deseja remover esse membro?"))
            {
                let response = await this.membroService.delete(membroId);
                this.swtAlert2Service.successAlert(response.message);
                this.carregarMembrosCelula();
            }
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarMembrosCelula() : Promise<any> {

        try
        {

            let response = await this.membroService.listarMembrosCelula(this.celula.id);
            this.membrosCelula = response;
            this.totalDePaginasMembros = 1;
            //this.membrosCelula = response.content;
            //this.totalDePaginasMembros = response.totalPages;

            if(this.membrosCelula.length > 0)
            {
                this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
            }

            for(let i = 0; i < this.membrosCelula.length; i++)
            {
                if(this.membrosCelula[i].pessoaImagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                }
            }

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    cliqueNoBotaoMembrosListener(botao) {
        this.buscaMembros.pageNumber = botao.numero-1;
        this.carregarMembrosCelula();
    }


    limparSelect2(nome) : void {
        $("select[name='"+nome+"']").empty();
    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async criarLinkCadastroCelula() : Promise<any> {

        try
        {
            if(this.celulaCriarLinkCadastroRequest.dataValidadeInicioCadastro > this.celulaCriarLinkCadastroRequest.dataValidadeFimCadastro)
            {
                this.swtAlert2Service.warningAlert("A data inicial de validade do link deve ser menor ou igual a data final de validade do link");
            }
            else
            if(this.celulaCriarLinkCadastroRequest.dataValidadeInicioCadastro != null && this.celulaCriarLinkCadastroRequest.dataValidadeFimCadastro != null)
            {
                this.celulaCriarLinkCadastroRequest.celulaId = this.celula.id;
                let response = await this.celulaService.criarLinkCadastroCelula(this.celulaCriarLinkCadastroRequest);
                this.tokenLinkCelulaResponse = response;
                this.celulaCriarLinkCadastroRequest = this.celulaCriarLinkCadastroRequestBuilder.getInstance();
            }
            else
            {
                this.swtAlert2Service.warningAlert("É preciso informar as datas de validade, de início e fim, antes de criar o link de registro na célula.");
            }
            
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async obterLinkCadastroCelula() : Promise<any> {

        try
        {
            let response = await this.celulaService.obterLinkCadastroCelula(this.celula.id);
            this.tokenLinkCelulaResponse = response;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async copyToClipboard() : Promise<any> {
        if(
            this.tokenLinkCelulaResponse != null 
            &&
            this.tokenLinkCelulaResponse.urlCadastro != undefined 
            && 
            this.tokenLinkCelulaResponse.urlCadastro != null 
            && 
            this.tokenLinkCelulaResponse.urlCadastro != ""
        )
        {
            navigator.clipboard.writeText(this.tokenLinkCelulaResponse.urlCadastro);
            this.swtAlert2Service.successAlert("Link copiado");
        }
        else
        {
            this.swtAlert2Service.warningAlert("Não há link de cadastro para copia. Crie um link e tente novamente.");
        }        
    }

}