/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layout/secured.component.ngfactory";
import * as i2 from "../../layout/secured.component";
import * as i3 from "../../../login/service/login.service";
import * as i4 from "../usuario/service/usuario.service";
import * as i5 from "../pessoa/service/pessoa.service";
import * as i6 from "../../../service/logged-in-user-shared-data.service";
import * as i7 from "../../layout/routermanager.service";
import * as i8 from "../../../core/services/app-route-change.service";
import * as i9 from "../aceitetermouso/service/aceitetermouso.service";
import * as i10 from "../aceitetermotratamentodados/service/aceitetermotratamentodados.service";
import * as i11 from "../cadastropublico/service/cadastro.publico.local.service";
import * as i12 from "../../service/formador.comunitario.service";
import * as i13 from "../../../core/swtalert2/swtalert2.service";
import * as i14 from "@angular/router";
import * as i15 from "./bemvindo.component";
var styles_BemVindoComponent = [];
var RenderType_BemVindoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BemVindoComponent, data: {} });
export { RenderType_BemVindoComponent as RenderType_BemVindoComponent };
export function View_BemVindoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "secured", [], null, null, null, i1.View_SecuredComponent_0, i1.RenderType_SecuredComponent)), i0.ɵdid(1, 4308992, null, 0, i2.SecuredComponent, [i3.LoginService, i4.UsuarioService, i5.PessoaService, i6.LoggedInUserSharedDataService, i7.RouterManagerService, i8.AppRouteChangeService, i9.AceiteTermoService, i10.AceiteTermoTratamentoDadosService, i11.CadastroPublicoLocalService, i12.FormadorComunitarioService, i13.SwtAlert2Service, i14.Router, i0.Renderer2], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 9, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 8, "section", [["class", "content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "bemvindotext"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h1", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Seja Bem Vindo ao Sistema Mission\u00E1rio - Shalom!"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Aqui voc\u00EA poder\u00E1 acessar os seus dados vocacionais."])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Basta voc\u00EA acessar o menu a sua esquerda! \uD83D\uDE01"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_BemVindoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "bemvindo", [], null, null, null, View_BemVindoComponent_0, RenderType_BemVindoComponent)), i0.ɵdid(1, 114688, null, 0, i15.BemVindoComponent, [i14.Router, i13.SwtAlert2Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BemVindoComponentNgFactory = i0.ɵccf("bemvindo", i15.BemVindoComponent, View_BemVindoComponent_Host_0, {}, {}, []);
export { BemVindoComponentNgFactory as BemVindoComponentNgFactory };
