import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SecuredModule } from 'src/app/secured/layout/secured.module';
import { AdicaoMembroCasaComunitariaComponent } from './adicao-membro-casa-comunitaria.component';
import { RemocaoMembroCasaComunitariaModule } from '../remocao-membro-casa-comunitaria/remocao-membro-casa-comunitaria.module';
import { ListagemPessoaInfoModalModule } from '../../../pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module';

 @NgModule({
     declarations:[
        AdicaoMembroCasaComunitariaComponent
     ],
     imports:[
        CommonModule,
        RouterModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule,
        ListagemPessoaInfoModalModule,
        RemocaoMembroCasaComunitariaModule
    ],
     exports:[
        AdicaoMembroCasaComunitariaComponent
     ]
 })
 export class AdicaoMembroCasaComunitariaModule {
     
 }