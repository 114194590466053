import { Routes } from "@angular/router";
import { SecuredComponent } from "./secured.component";
const ɵ0 = () => import("./../component/colegiado/colegiado.module.ngfactory").then((module) => module.ColegiadoModuleNgFactory);
const securedRoutes = [
    {
        path: "secured",
        component: SecuredComponent,
    },
    {
        path: "secured/celula/cc/meucolegiado",
        loadChildren: ɵ0,
    },
];
export class SecuredRoutingModule {
}
export { ɵ0 };
