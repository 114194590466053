import { OnInit, Component } from '@angular/core';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Missao } from 'src/app/model/missao.model';
import { Pais } from 'src/app/model/pais.model';
import { Estado } from 'src/app/model/estado.model';
import { Cidade } from 'src/app/model/cidade.model';
import { PessoaService } from './service/pessoa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Endereco } from 'src/app/model/endereco.model';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImagemService } from '../../service/imagem.service';
import 'select2';
import { EnderecoService } from '../../service/endereco.service';
import intlTelInput from 'intl-tel-input';
import utilJs from 'intl-tel-input/build/js/utils.js';
import { Documento } from 'src/app/model/documento.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../../service/elementogrupodominio.service';
import { DocumentoService } from '../../service/documento.service';
import * as bootastrap from 'bootstrap';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { EnderecoBuilder } from 'src/app/builder/endereco.builder';
import { DocumentoBuilder } from 'src/app/builder/documento.builder';
import { Regional } from 'src/app/model/regional.model';
import { Difusao } from 'src/app/model/difusao.model';
import { Aptidao } from 'src/app/model/aptidao.model';
import { Escolaridade } from 'src/app/model/escolaridade.model';
import { Trabalho } from 'src/app/model/trabalho.model';
import { AptidaoBuilder } from 'src/app/builder/aptidao.builder';
import { EscolaridadeBuilder } from 'src/app/builder/escolaridade.builder';
import { TrabalhoBuilder } from 'src/app/builder/trabalho.builder';
import { AptidaoService } from '../../service/aptidao.service';
import { EscolaridadeService } from '../../service/escolaridade.service';
import { TrabalhoService } from '../../service/trabalho.service';
import { Usuario } from 'src/app/model/usuario.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { UsuarioService } from '../usuario/service/usuario.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { PaisService } from '../../service/pais.service';
import { EstadoService } from '../../service/estado.service';
import { CidadeService } from '../../service/cidade.service';
import { RegionalService } from '../../service/regional.service';
import { MissaoService } from '../../service/missao.service';
import { DifusaoService } from '../../service/difusao.service';

@Component({
    selector: 'pessoaedit',
    templateUrl: './pessoa.edit.component.html',
    styleUrls: ['../../style/style.css', '../../style/normalize.css']
})

export class PessoaEditComponent implements OnInit {

    public pessoa: Pessoa;
    public aptidao: Aptidao;
    public escolaridade: Escolaridade;
    public trabalho: Trabalho;
    public missoesVinculo: Array<Missao>;
    public difusoesVinculo: Array<Difusao>;
    public missoes: Array<Missao>;
    public difusoes: Array<Difusao>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public escolaridades: Array<Escolaridade>;
    public trabalhos: Array<Trabalho>;
    public pessoaId: number;
    public tabNumber: number;
    public imageChangedEvent: any;
    public croppedImage: any;
    public nextClicked: boolean;
    public url: string;
    public endereco: Endereco;
    public telefone: string;
    public intlTelInputCalled: boolean = false;
    public intlTellInputInstance : any;
    public tipoDocumentoSelecionado: ElementoGrupoDominio;
    public documento: Documento;
    public documentos: Array<Documento>;
    public tiposDocumento: Array<ElementoGrupoDominio>;
    public estadosCivil: Array<ElementoGrupoDominio>;
    public formasVida: Array<ElementoGrupoDominio>;
    public formaVidaSelecionada: ElementoGrupoDominio;
    public habilidades: Array<ElementoGrupoDominio>;
    public linguas: Array<ElementoGrupoDominio>;
    public niveisEscolaridade: Array<ElementoGrupoDominio>;
    public pessoaBuilder: PessoaBuilder;
    public enderecoBuilder: EnderecoBuilder;
    public documentoBuilder: DocumentoBuilder;
    public aptidaoBuilder: AptidaoBuilder;
    public escolaridadeBuilder: EscolaridadeBuilder;
    public trabalhoBuilder: TrabalhoBuilder;    
    public niveisFormacao: Array<ElementoGrupoDominio>;
    public estadosVida: Array<ElementoGrupoDominio>;
    public tipoOrigem: string;
    public regionais: Array<Regional>;
    public urlBaseImagem: string;
    public usuarioAtual: Usuario;
    
    public regionalSelecionadoId: number;
    public nivelEscolaridadeSelecionado: ElementoGrupoDominio;

    public imageUrl : any;
    public pessoaStatus: any;

    constructor(
        public pessoaService: PessoaService,
        public enderecoService: EnderecoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public imagemService: ImagemService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public documentoService: DocumentoService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public escolaridadeService: EscolaridadeService,
        public trabalhoService: TrabalhoService,
        public aptidaoService: AptidaoService,
        public swtAlert2Service: SwtAlert2Service,
        public usuarioService: UsuarioService,
        public route: ActivatedRoute,
        public router: Router        
    ) {        
        
        this.regionais = new Array<Regional>();
        this.missoesVinculo = new Array<Missao>();
        this.difusoesVinculo = new Array<Difusao>();
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.escolaridades = new Array<Escolaridade>();
        this.trabalhos = new Array<Trabalho>();
        this.estadosCivil = new Array<ElementoGrupoDominio>();
        this.tiposDocumento = new Array<ElementoGrupoDominio>();
        this.formasVida = new Array<ElementoGrupoDominio>();
        this.niveisFormacao = new Array<ElementoGrupoDominio>();
        this.estadosVida = new Array<ElementoGrupoDominio>();       
        this.habilidades = new Array<ElementoGrupoDominio>();       
        this.linguas = new Array<ElementoGrupoDominio>();
        this.niveisEscolaridade = new Array<ElementoGrupoDominio>();       
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;
        
        this.pessoaBuilder = new PessoaBuilder();
        this.enderecoBuilder = new EnderecoBuilder();
        this.documentoBuilder = new DocumentoBuilder();
        this.aptidaoBuilder = new AptidaoBuilder();
        this.escolaridadeBuilder = new EscolaridadeBuilder();
        this.trabalhoBuilder = new TrabalhoBuilder();

        this.pessoa = this.pessoaBuilder.getInstance();
        this.endereco = this.enderecoBuilder.getInstance();
        this.documento = this.documentoBuilder.getInstance();
        this.aptidao = this.aptidaoBuilder.getInstance();
        this.escolaridade = this.escolaridadeBuilder.getInstance();
        this.trabalho = this.trabalhoBuilder.getInstance();

        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
    }

    ngOnInit() {        
        this.initializacao();
    }

    async initializacao() : Promise<any> {

        this.pessoaId = Number(this.route.snapshot.paramMap.get('id')); 
        await this.getUsuarioAtual();
        await this.carregarPaises();
        await this.carregarFormasVida();
        await this.carregarTipoDocumento();
        await this.carregarEstadosCivil();
        await this.carregarNiveisFormacao();
        await this.carregarEstadoVida();
        await this.carregarRegionais();
        await this.carregarLinguas();
        await this.carregarHabilidades();
        await this.carregarNiveisEscolaridade();
        await this.carregarMissoesVinculo();
        await this.carregarDifusoesVinculo();
        await this.carregarPessoaStatus();

        $("select[name='linguas-select']").select2({
            theme: "bootstrap4"            
        });

        $("select[name='habilidades-select']").select2({
            theme: "bootstrap4"
        });

        await this.carregarPessoa(this.pessoaId);
        await this.encontrarDocumentosPorPessoaId(this.pessoaId);
        await this.encontrarTrabalhoPorPessoaId(this.pessoaId);        

    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;      
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async carregarRegionais() : Promise<any> {

        try 
        {
            let response = await this.regionalService.findAll();   
            this.regionais = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    carregarOrigem() 
    {
        if(this.regionalSelecionadoId != undefined)
        {
            if(this.tipoOrigem == "M")
            {
                this.carregarMissoes();                
            }
            else if(this.tipoOrigem == "D")
            {
                this.carregarDifusoes();                
            }
        }
    }

    async carregarMissoesVinculo(): Promise<any> {

        try 
        {
            let response = await this.missaoService.findAll();   
            this.missoesVinculo = response.entity;     
            this.missoesVinculo = this.missoesVinculo.sort( (a:Missao, b:Missao) => a.nome.localeCompare(b.nome)); 
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarDifusoesVinculo(): Promise<any> {

        try 
        {
            let response = await this.difusaoService.findAll();   
            this.difusoesVinculo = response.entity;     
            this.difusoesVinculo = this.difusoesVinculo.sort( (a:Difusao, b:Difusao) => a.nome.localeCompare(b.nome)); 
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    carregarMissoes(): void {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;   
            this.difusoes = null;       
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarDifusoes(): void {
        this.difusaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.difusoes = response.entity;  
            this.missoes = null;        
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    ngAfterViewChecked() {

        if(!this.intlTelInputCalled && $('#pessoa-telefone').length == 1)
        {
            var input = document.querySelector("#pessoa-telefone");
            this.intlTellInputInstance = intlTelInput(input, {
                initialCountry: "auto",
                geoIpLookup: callback => {
                    fetch("https://ipapi.co/json")
                      .then(res => res.json())
                      .then(data => callback(data.country_code))
                      .catch(() => callback("us"));
                },
                utilsScript : 'assets/scripts/utils.js'
            });
            this.intlTelInputCalled = true;
        }
        
    }

    async carregarPaises(): Promise<any> {

        try
        {
            let response = await this.paisService.findAll();
            this.paises = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    carregarEstados(paisId: number): void {

        let busca = {
            paisId: paisId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };

        this.estadoService.buscarEstados(busca).then((lista: any) => {
            this.estados = lista.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarCidades(estadoId: number): void {

        let busca = {
            estadoId: estadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };

        this.cidadeService.buscarCidades(busca).then((lista: any) => {
            this.cidades = lista.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    async carregarPessoa(Id: number) : Promise<any> {

        try
        {
            let response = await this.pessoaService.find(Id);

            this.pessoa = response.entity;

            if(this.pessoa.imagemId != null)
            {
                let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

                this.imagemService.createImage(blogImage, this.pessoa);
            }            

            if (this.pessoa.enderecoId != null) {
                this.carregarEndereco(this.pessoa.enderecoId);                
            }
            if(this.pessoa.usuarioId != null)
            {
                $("select[name='usuario']").append(new Option(this.pessoa.usuarioUsername, this.pessoa.usuarioId.toString(), false, true));
            }
            if(this.pessoa.formaVidaId != null)
            {
                this.setarFormaVidaSelecionada();
            }
            /* if(this.pessoa.obraId != null)
            {
                this.carregarObra(this.pessoa.obraId);
            } */
            /* if(this.pessoa.membroId != null)
            {
                if(this.pessoa.formaVidaValor == 'CA')
                {
                    this.carregarMembroComunidadeAlianca(this.pessoa.membroId);                    
                }
                else
                if(this.pessoa.formaVidaValor == 'CV')
                {
                    this.carregarMembroComunidadeVida(this.pessoa.membroId);                    
                }                
            } */
            if(this.pessoa.aptidaoId != null)
            {
                this.carregarAptidao(this.pessoa.aptidaoId);
            }
            if(this.pessoa.trabalhoIds.length > 0)
            {
                this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
            }

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    carregarEndereco(enderecoId: number): void {
        this.enderecoService.find(enderecoId).then((response: any) => {
            this.endereco = response.entity;
            this.carregarEstados(this.endereco.paisId);
            this.carregarCidades(this.endereco.estadoId);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    /* carregarObra(obraId: number): void {
        this.obraService.find(obraId).then((response: any) => {
            this.obra = response.entity;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeAlianca(membroComunidadeAliancaId: number): void {
        this.membroComunidadeAliancaService.find(membroComunidadeAliancaId).then((response: any) => {
            this.membroComunidadeAlianca = response.entity;      
            this.regionalSelecionadoId = this.membroComunidadeAlianca.regionalId;   
            if(this.membroComunidadeAlianca.missaoOrigemId != null) 
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeAlianca.difusaoOrigemId != null) 
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeVida(membroComunidadeVidaId: number): void {
        this.membroComunidadeVidaService.find(membroComunidadeVidaId).then((response: any) => {
            this.membroComunidadeVida = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeVida.regionalId;            
            if(this.membroComunidadeVida.missaoOrigemId != null) 
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeVida.difusaoOrigemId != null) 
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    } */

    async carregarAptidao(aptidaoId: number): Promise<null> {
        
        try
        {
            let response = await this.aptidaoService.find(aptidaoId);
            this.aptidao = response.entity;    
            this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
            $("select[name='linguas-select']").val(this.aptidao.linguaIds).trigger('change');
            $("select[name='habilidades-select']").val(this.aptidao.habilidadeIds).trigger('change');
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);            
        }

        return null;
    }

    encontrarDocumentosPorPessoaId(pessoaId: number): void {
        this.documentoService.buscarPorPessoaId(pessoaId).then((response: any) => {
            this.documentos = response.entity;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    async encontrarEscolaridadesPorAptidaoId(aptidaoId: number): Promise<null> {
        
        try
        {        
            let response = await this.escolaridadeService.findByAptidaoId(aptidaoId);
            this.escolaridades = response.entity;       
        }
        catch(err)
        {
            console.log(err);            
        }

        return null;
    }

    async encontrarTrabalhoPorPessoaId(pessoaId: number): Promise<null> {
        
        try
        {        
            let response = await this.trabalhoService.findByPessoaId(pessoaId);
            this.trabalhos = response.entity;       
        }
        catch(err)
        {
            console.log(err);            
        }

        return null;
    }

    async carregarTipoDocumento() : Promise<any> {

        let buscaDocumentos = {
            grupoNome : 'TIPOS_DOCUMENTOS_PESSOA'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaDocumentos);
            this.tiposDocumento = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarEstadosCivil() : Promise<any> 
    {
        let buscaEstadosCivil = {
            grupoNome : 'ESTADO_CIVIL'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEstadosCivil);
            this.estadosCivil = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarFormasVida() : Promise<any> 
    {
        let buscaFormaVida = {
            grupoNome : 'FORMA_VIDA'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFormaVida);
            this.formasVida = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarNiveisFormacao() : Promise<any> 
    {
        let busca = {
            grupoNome : 'NIVEL_FORMACAO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.niveisFormacao = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }
    
    async carregarEstadoVida() : Promise<any> 
    {
        let busca = {
            grupoNome : 'ESTADO_VIDA'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.estadosVida = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarHabilidades() : Promise<null> 
    {
        let busca = {
            grupoNome : 'HABILIDADE'
        }

        try
        {            
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);       
            this.habilidades = response.entity;
        }
        catch(e)
        {
            console.log(e);
        };

        return null;
    }

    async carregarLinguas() : Promise<null> 
    {
        let busca = {
            grupoNome : 'LINGUA'
        }

        try
        {            
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);       
            this.linguas = response.entity;
        }
        catch(e)
        {
            console.log(e);
        };

        return null;
    }

    async carregarNiveisEscolaridade() : Promise<null> 
    {
        let busca = {
            grupoNome : 'NIVEL_ESCOLARIDADE'
        }

        try
        {            
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);       
            this.niveisEscolaridade = response.entity;
        }
        catch(e)
        {
            console.log(e);
        };

        return null;
    }

    async carregarPessoaStatus() : Promise<null> 
    {
        let busca = {
            grupoNome : 'PESSOA_STATUS'
        }

        try
        {            
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);       
            this.pessoaStatus = response.entity;
        }
        catch(e)
        {
            console.log(e);
        };

        return null;
    }

    get valorTipoDocumento() {
        let tipoSelecionado : Array<ElementoGrupoDominio>  = this.tiposDocumento.filter((tipo) => {
            return (tipo.id == this.documento.elementoGrupoDominioTipoId);
        });

        return tipoSelecionado.length > 0 ? tipoSelecionado[0].valor : '';
    }

    logOnConsole(dadosPessoaisForm:any):void {
        console.log(dadosPessoaisForm);       
    }

    activateTab(tab:string) : void {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled')
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');        
    }

    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }

    async salvar(pessoa: Pessoa): Promise<any> {

        try 
        {
            let response = await this.pessoaService.update(pessoa.id, pessoa);

            this.pessoa = response.entity;

            if(this.pessoa.imagemId != null)
            {
                let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

                this.imagemService.createImage(blogImage, this.pessoa);
            } 

            this.swtAlert2Service.successAlert(response.message);           
            
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    salvarEndereco(endereco: Endereco): void {

        if (this.endereco.id == null) {

            this.enderecoService.create(endereco).then((response: any) => {
                this.endereco = response.entity;
                this.pessoa.enderecoId = response.entity.id;
                this.salvar(this.pessoa);
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });

        }
        else {
            this.enderecoService.update(endereco.id, endereco).then((response: any) => {
                this.endereco = response.entity;
                this.swtAlert2Service.successAlert(response.message);
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });
        }

    }

    salvarImagem(): void {

        this.imagemService.salvarCropped({ 'imageData': this.croppedImage }).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            this.pessoa.imagemId = response.entity.id;
            this.salvar(this.pessoa);
            $('#modal-pessoa-imagem').modal('hide');
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    salvarDocumento(documento: Documento): void {

        documento.pessoaId = this.pessoa.id;

        this.documentoService.create(documento).then((response: any) => {
            this.documento = this.documentoBuilder.getInstance();
            this.pessoa.documentosId.push(response.entity.id);
            this.encontrarDocumentosPorPessoaId(this.pessoaId);
            this.swtAlert2Service.successAlert(response.message);
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    async salvarAptidao(aptidao: Aptidao): Promise<null> {

        this.aptidao.pessoaId = this.pessoa.id;
        this.aptidao.linguaIds = $("select[name='linguas-select']").val();
        this.aptidao.habilidadeIds = $("select[name='habilidades-select']").val();

        try
        {            

            if(this.aptidao.id == null)
            {
                let response = await this.aptidaoService.createAsync(aptidao);
                this.aptidao = response.entity;        
                this.pessoa.aptidaoId = this.aptidao.id;  
                this.swtAlert2Service.successAlert(response.message);
            }
            else
            {
                let response = await this.aptidaoService.updateAsync(aptidao.id,aptidao);
                this.aptidao = response.entity;        
                this.swtAlert2Service.successAlert(response.message);
            }
             
        }
        catch (err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }        

        return null;
    }

    async salvarEscolaridade(escolaridade: Escolaridade): Promise<null> {

        if(this.aptidao.id == null)
        {
            await this.salvarAptidao(this.aptidao);
        }

        this.escolaridade.aptidaoId = this.aptidao.id;

        try
        {        
            let response = await this.escolaridadeService.createAsync(escolaridade);
            this.escolaridade = response.entity;        
            this.aptidao.escolaridadeIds.push(this.escolaridade.id);  
            this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
            this.escolaridade = this.escolaridadeBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);     
        }
        catch (err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }        

        return null;
    }

    async salvarTrabalho(trabalho: Trabalho): Promise<null> {

        this.trabalho.pessoaId = this.pessoa.id;

        try
        {        
            let response = await this.trabalhoService.createAsync(trabalho);            
            this.pessoa.trabalhoIds.push(response.entity.id);  
            this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
            this.trabalho = this.trabalhoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);     
        }
        catch (err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }        

        return null;
    }

    adicionarTelefone() {
        if (this.intlTellInputInstance.getNumber().localeCompare("") != 0) {
            this.pessoa.telefones.push(this.intlTellInputInstance.getNumber());
            this.salvar(this.pessoa);
            $("#pessoa-telefone").val("");
        }
    }

    removerTelefone(tel: string) {
        if(confirm('Deseja deletar este telefone?'))
        {
            let index = this.pessoa.telefones.indexOf(tel);
            if (index > -1) {
                this.pessoa.telefones.splice(index, 1);
                this.salvar(this.pessoa);
            }
        }   
    }

    removerDocumento(id: number) {
        if(confirm('Deseja deletar este documento?'))
        {
            this.documentoService.delete(id).then((response:any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.encontrarDocumentosPorPessoaId(this.pessoaId);
            }).catch((err:any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    async removerEscolaridade(id:number): Promise<null> {

        if(confirm('Deseja deletar esta escolaridade?'))
        {

            try
            {
                let response = await this.escolaridadeService.deleteAsync(id);
                this.swtAlert2Service.successAlert(response.message);
                this.carregarAptidao(this.pessoa.aptidaoId);
            }
            catch(err)
            {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }

        }

        return null;
    }

    async removerTrabalho(id:number): Promise<null> {

        if(confirm('Deseja deletar este trabalho?'))
        {

            try
            {
                let response = await this.trabalhoService.deleteAsync(id);
                this.swtAlert2Service.successAlert(response.message);
                this.carregarPessoa(this.pessoa.id);
                this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
            }
            catch(err)
            {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }

        }

        return null;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        console.log(event);
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        console.log('Imagem carregada');        
    }

    cropperReady() {
        console.log('Imagem cortada');
        
    }

    loadImageFailed() {
        console.log('Carregamento da imagem falhou!');        
    }

    setarFormaVidaSelecionada() : void {
        for(let i = 0; i < this.formasVida.length; i++)
        {
            if(this.formasVida[i].id == this.pessoa.formaVidaId)
            {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    }

    setarNivelEscolaridadeSelecionado() : void {
        for(let i = 0; i < this.niveisEscolaridade.length; i++)
        {
            if(this.niveisEscolaridade[i].id == this.escolaridade.nivelEscolaridadeId)
            {
                this.nivelEscolaridadeSelecionado = this.niveisEscolaridade[i];
            }
        }
    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    } 

}