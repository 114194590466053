import { Component, OnInit } from "@angular/core";
import { UsuarioService } from "../usuario/service/usuario.service";
import { ImagemService } from "../../service/imagem.service";
import { PessoaService } from "../pessoa/service/pessoa.service";
import { Usuario } from "src/app/model/usuario.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { CoordenadorColegiadoService } from "../../service/coordenadorcolegiado.service";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";

@Component({
  selector: "app-colegiado",
  templateUrl: "./colegiado.component.html",
  styleUrls: ["./colegiado.component.css"],
})
export class ColegiadoComponent implements OnInit {
  usuario: Usuario;
  pessoa: Pessoa;
  formadoresDaMissao: any;
  paginacaoConfig: any;

  constructor(
    private usuarioService: UsuarioService,
    private imagemService: ImagemService,
    private pessoaService: PessoaService,
    private coordenadorColegiadoService: CoordenadorColegiadoService,
    private swtAlert2Service: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.initializer();
    this.paginacaoConfig = {
      page: 0,
      size: 10,
      totalPages: 0,
      totalElements: 0,
    };
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async initializer(): Promise<any> {
    await this.carregarUsuario();
    await this.carregarPessoa();
    await this.carregarFormadoresCelula();
  }

  async carregarFormadoresCelula(): Promise<any> {
    try {
      let response =
        await this.coordenadorColegiadoService.obterFormadoresPorMissaoDoCoordenadorColegiado(
          this.paginacaoConfig.page,
          this.paginacaoConfig.size
        );
      this.formadoresDaMissao = response.content;
      this.paginacaoConfig.totalPages = response.totalPages;
      this.paginacaoConfig.totalElements = response.totalElements;

      if (this.formadoresDaMissao.length > 0) {
        this.formadoresDaMissao = this.formadoresDaMissao.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      for (let i = 0; i < this.formadoresDaMissao.length; i++) {
        if (this.formadoresDaMissao[i].imagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.formadoresDaMissao[i].imagemId
          );
          this.imagemService.createImage(blogImage, this.formadoresDaMissao[i]);
        }

        if (
          this.formadoresDaMissao[i].questionarioFormandoStatus == "NAOINICIADO"
        ) {
          this.formadoresDaMissao[i].questionarioFormandoStatus =
            "Não Iniciado";
        }
        if (this.formadoresDaMissao[i].parecerStatus == "NAOINICIADO") {
          this.formadoresDaMissao[i].parecerStatus = "Não Iniciado";
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.paginacaoConfig.page = botao.numero - 1;
    this.carregarFormadoresCelula();
  }
}
