import { Component, OnInit } from "@angular/core";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { DadoVocacional } from "src/app/model/dadovocacional.model";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { Usuario } from "src/app/model/usuario.model";
import { RespostaParecer } from "src/app/model/respostaparecer.model";
import { ParecerResponsavel } from "src/app/model/parecerresponsavel.model";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { Estado } from "src/app/model/estado.model";
import { Pais } from "src/app/model/pais.model";
import { Cidade } from "src/app/model/cidade.model";
import { CaminhoFormativo } from "src/app/model/caminhoformativo.model";
import { DadoFormandoCV } from "src/app/model/dadoformandocv.model";
import { PessoaInfo } from "src/app/model/pessoainfo.model";
import { Missao } from "src/app/model/missao.model";
import { DadoFormandoCVBuilder } from "src/app/builder/dadoformandocv.builder";
import { CaminhoFormativoBuilder } from "src/app/builder/caminhoformativo.builder";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { RespostaParecerBuilder } from "src/app/builder/respostaparecer.builder";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ElementoGrupoDominioLocalService } from "../../../service/elementogrupodominio.local.service";
import { PessoaInfoBuilder } from "src/app/builder/pessoainfo.builder";
import { DadoFormandoCVService } from "../service/dadoformandocv.service";
import { CaminhoFormativoService } from "../service/caminhoformativo.service";
import { QuestionarioFormandoService } from "../../questionario/service/questionarioformando.service";
import { ParecerResponsavelService } from "../service/parecerresponsavel.service";
import { RespostaParecerService } from "../service/respostaparecer.service";
import { PessoaService } from "../../pessoa/service/pessoa.service";
import { ImagemService } from "../../../service/imagem.service";
import { PaisService } from "src/app/secured/service/pais.service";
import { EstadoService } from "src/app/secured/service/estado.service";
import { CidadeService } from "src/app/secured/service/cidade.service";
import { ElementoGrupoDominioService } from "src/app/secured/service/elementogrupodominio.service";
import { MissaoService } from "src/app/secured/service/missao.service";
import { UsuarioService } from "../../usuario/service/usuario.service";
import { DadoVocacionalService } from "../../dadosvocacionais/service/dadovocacional.service";
import { WopGetAllService } from "src/app/service/getall.service";

@Component({
  selector: "app-parecerd2-fc-answer",
  templateUrl: "./parecerd2-fc-answer.component.html",
  styleUrls: [],
})
export class ParecerD2FCAnswerComponent implements OnInit {
  public pessoaResponsavel: Pessoa;
  public missoesVinculo: Array<Missao>;
  public missoes: Array<Missao>;
  public pessoaFormandoId: number;
  public tabNumber: number;
  public imageChangedEvent: any;
  public croppedImage: any;
  public nextClicked: boolean;
  public url: string;
  public intlTelInputCalled: boolean = false;
  public intlTellInputInstance: any;
  public formasVida: Array<ElementoGrupoDominio>;
  public niveisFormacao: Array<ElementoGrupoDominio>;
  public pessoaFormadorPessoalSelecionado: PessoaInfo;
  public dadoVocacional: DadoVocacional;
  public dadoFormandoCV: DadoFormandoCV;
  public caminhoFormativoFormando: CaminhoFormativo;
  public urlBaseImagem: string;
  public usuarioAtual: Usuario;
  public formando: Pessoa;

  public imageUrl: any;

  public tabNames: Array<string>;
  public activeTabNumber: number;

  public paises: Array<Pais>;
  public estados: Array<Estado>;
  public cidades: Array<Cidade>;

  public parecerResponsavelId: number;
  public parecerResponsavel: ParecerResponsavel;
  public questionarioFormando: QuestionarioFormando;

  public respostas: Array<RespostaParecer>;

  public resposta1: RespostaParecer;
  public resposta2: RespostaParecer;
  public resposta3: RespostaParecer;
  public resposta4: RespostaParecer;
  public resposta5: RespostaParecer;
  public resposta6: RespostaParecer;
  public resposta7: RespostaParecer;
  public resposta8: RespostaParecer;
  public resposta9: RespostaParecer;
  public resposta10: RespostaParecer;
  public resposta11: RespostaParecer;
  public resposta12: RespostaParecer;
  public resposta13: RespostaParecer;
  public resposta14: RespostaParecer;
  public resposta15: RespostaParecer;
  public resposta16: RespostaParecer;
  public resposta17: RespostaParecer;
  public resposta18: RespostaParecer;

  constructor(
    public wopGetAllPublic: WopGetAllService,
    public pessoaService: PessoaService,
    public imagemService: ImagemService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public cidadeService: CidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public missaoService: MissaoService,
    public swtAlert2Service: SwtAlert2Service,
    public usuarioService: UsuarioService,
    public pessoaBuilder: PessoaBuilder,
    public pessoaInfoBuilder: PessoaInfoBuilder,
    public dadoVocacionalService: DadoVocacionalService,
    public dadoFormandoCVService: DadoFormandoCVService,
    public caminhoFormativoService: CaminhoFormativoService,
    public respostaParecerService: RespostaParecerService,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelService: ParecerResponsavelService,
    public dadoFormandoCVBuilder: DadoFormandoCVBuilder,
    public caminhoFormativoFormandoBuilder: CaminhoFormativoBuilder,
    public respostaParecerBuilder: RespostaParecerBuilder,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.missoesVinculo = new Array<Missao>();
    this.missoes = new Array<Missao>();
    this.formasVida = new Array<ElementoGrupoDominio>();
    this.niveisFormacao = new Array<ElementoGrupoDominio>();
    this.dadoFormandoCV = this.dadoFormandoCVBuilder.getInstance();
    this.caminhoFormativoFormando =
      this.caminhoFormativoFormandoBuilder.getInstance();
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.nextClicked = false;

    this.pessoaResponsavel = this.pessoaBuilder.getInstance();
    this.formando = this.pessoaBuilder.getInstance();

    this.urlBaseImagem = environment.imagem.urlBaseImagem;
    this.url = environment.usuario.selectPorUsername;

    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();

    this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];

    this.activeTabNumber = 0;

    this.resposta1 = respostaParecerBuilder.getInstance();
    this.resposta2 = respostaParecerBuilder.getInstance();
    this.resposta3 = respostaParecerBuilder.getInstance();
    this.resposta4 = respostaParecerBuilder.getInstance();
    this.resposta5 = respostaParecerBuilder.getInstance();
    this.resposta6 = respostaParecerBuilder.getInstance();
    this.resposta7 = respostaParecerBuilder.getInstance();
    this.resposta8 = respostaParecerBuilder.getInstance();
    this.resposta9 = respostaParecerBuilder.getInstance();
    this.resposta10 = respostaParecerBuilder.getInstance();
    this.resposta11 = respostaParecerBuilder.getInstance();
    this.resposta12 = respostaParecerBuilder.getInstance();
    this.resposta13 = respostaParecerBuilder.getInstance();
    this.resposta14 = respostaParecerBuilder.getInstance();
    this.resposta15 = respostaParecerBuilder.getInstance();
    this.resposta16 = respostaParecerBuilder.getInstance();
    this.resposta17 = respostaParecerBuilder.getInstance();
    this.resposta18 = respostaParecerBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initialization();
  }

  async initialization(): Promise<any> {
    await this.getUsuarioAtual();
    this.carregarPaises();
    this.carregarFormasVida();
    this.carregarNiveisFormacao();

    await this.carregarMissoesVinculo();
    this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
    await this.carregarParecerResponsavel();
    await this.carregarQuestionarioFormando();
    await this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
    this.pessoaResponsavel = await this.carregarPessoaResponsavel(
      this.usuarioAtual.id
    );
    await this.carregarDadoVocacional();
    await this.carregarDadoFormandoCV();
    await this.carregarCaminhoFormativo();
    await this.carregarRespostas();
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMissoesVinculo(): Promise<any> {
    try {
      let response = await this.wopGetAllPublic.getAllMissao();
      this.missoesVinculo = response.entity;
      this.missoesVinculo = this.missoesVinculo.sort((a: Missao, b: Missao) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarPessoaFormando(pessoaId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorId(pessoaId);

      this.formando = response.entity;

      this.pessoaFormandoId = this.formando.id;

      if (this.formando.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.formando.imagemId);

        this.imagemService.createImage(blogImage, this.formando);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarPessoaResponsavel(pessoaId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorId(pessoaId);

      return response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  carregarFormasVida(): void {
    let buscaFormaVida = {
      grupoNome: "FORMA_VIDA",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(buscaFormaVida)
      .then((lista: any) => {
        this.formasVida = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarNiveisFormacao(): void {
    let busca = {
      grupoNome: "NIVEL_FORMACAO",
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(busca)
      .then((lista: any) => {
        this.niveisFormacao = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  async carregarPaises(): Promise<any> {
    try {
      let response = await this.paisService.findAll();
      this.paises = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarEstados(paisId: number): Promise<any> {
    try {
      let request = {
        paisId: paisId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.estadoService.buscarEstados(request);
      this.estados = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }
  async carregarCidades(estadoId: number): Promise<any> {
    try {
      let request = {
        estadoId: estadoId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.cidadeService.buscarCidades(request);
      this.cidades = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  logOnConsole(dadosPessoaisForm: any): void {
    console.log(dadosPessoaisForm);
  }

  activateTab(tab: string): void {
    $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
    $('.nav-tabs a[href="#' + tab + '"]').tab("show");
  }

  activateTab2(action: string): void {
    if (action.localeCompare("previous") == 0) {
      if (this.activeTabNumber > 0) {
        this.activeTabNumber -= 1;
      }
    } else if (action.localeCompare("next") == 0) {
      if (this.activeTabNumber < this.tabNames.length - 1) {
        this.activeTabNumber += 1;
      }
    }
    $(
      '.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]'
    ).removeClass("disabled");
    $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab(
      "show"
    );
  }

  async salvar(): Promise<any> {
    //this.atualizarCaminhoFormativo();
    this.atualizarParecerResponsavel("INICIADO");
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log(event);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log("Imagem carregada");
  }

  cropperReady() {
    console.log("Imagem cortada");
  }

  loadImageFailed() {
    console.log("Carregamento da imagem falhou!");
  }

  async carregarDadoVocacional(): Promise<any> {
    try {
      let response = await this.dadoVocacionalService.porPessoaId(
        this.formando.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.dadoVocacional = response.entity;
        this.pessoaFormadorPessoalSelecionado =
          this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado.nome =
          this.dadoVocacional.formadorPessoalPessoaNome;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async selecionarFormadorComunitario(pessoa: PessoaInfo): Promise<any> {
    //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    //this.pessoaFormadorComunitarioSelecionado = pessoa;
    //$('#listagem-pessoa-formador-comunitario').modal('hide');
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarDadoFormandoCV(): Promise<any> {
    try {
      let response = await this.dadoFormandoCVService.porPessoaId(
        this.formando.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCV = response;
        this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
        this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarCaminhoFormativo(): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        this.formando.id
      );
      if (response != null && response != undefined) {
        this.caminhoFormativoFormando = response;
        console.log(this.caminhoFormativoFormando);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarCaminhoFormativo(): Promise<any> {
    try {
      if (this.caminhoFormativoFormando.id != null) {
        let response = await this.caminhoFormativoService.atualizar(
          this.caminhoFormativoFormando
        );
        this.caminhoFormativoFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarRespostas(): Promise<any> {
    try {
      let response = await this.respostaParecerService.porPessoaId(
        this.pessoaResponsavel.id
      );
      if (response != null && response != undefined) {
        this.respostas = response.content;
        console.log(this.respostas);
        this.respostas.forEach((resposta) => {
          switch (resposta.parecer_pergunta_id) {
            case 1:
              this.resposta1 = resposta;
            case 2:
              this.resposta2 = resposta;
            case 3:
              this.resposta3 = resposta;
            case 4:
              this.resposta4 = resposta;
            case 5:
              this.resposta5 = resposta;
            case 6:
              this.resposta6 = resposta;
            case 7:
              this.resposta7 = resposta;
            case 8:
              this.resposta8 = resposta;
            case 9:
              this.resposta9 = resposta;
            case 10:
              this.resposta10 = resposta;
            case 11:
              this.resposta11 = resposta;
            case 12:
              this.resposta12 = resposta;
            case 13:
              this.resposta13 = resposta;
            case 14:
              this.resposta14 = resposta;
            case 15:
              this.resposta15 = resposta;
            case 16:
              this.resposta16 = resposta;
            case 17:
              this.resposta17 = resposta;
            case 18:
              this.resposta18 = resposta;
          }
        });
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarParecerResponsavel(): Promise<any> {
    try {
      let response = await this.parecerResponsavelService.buscarPorId(
        this.parecerResponsavelId
      );
      if (response != null && response != undefined) {
        this.parecerResponsavel = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarioFormando(): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorId(
        this.parecerResponsavel.questionarioFormandoId
      );
      if (response != null && response != undefined) {
        this.questionarioFormando = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarResposta(resposta: RespostaParecer): Promise<any> {
    try {
      resposta.pessoaId = this.pessoaResponsavel.id;
      if (resposta.id == null) {
        console.log(resposta);
        let response = await this.respostaParecerService.cadastrar(resposta);
        resposta = response;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.respostaParecerService.atualizar(
          resposta.id,
          resposta
        );
        resposta = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  salvarQuestionario() {
    this.resposta1.parecer_pergunta_id = 1;
    this.atualizarResposta(this.resposta1);
    this.resposta2.parecer_pergunta_id = 2;
    this.atualizarResposta(this.resposta2);
    this.resposta3.parecer_pergunta_id = 3;
    this.atualizarResposta(this.resposta3);
    this.resposta4.parecer_pergunta_id = 4;
    this.atualizarResposta(this.resposta4);
    this.resposta5.parecer_pergunta_id = 5;
    this.atualizarResposta(this.resposta5);
    this.resposta6.parecer_pergunta_id = 6;
    this.atualizarResposta(this.resposta6);
    this.resposta7.parecer_pergunta_id = 7;
    this.atualizarResposta(this.resposta7);
    this.resposta8.parecer_pergunta_id = 8;
    this.atualizarResposta(this.resposta8);
    this.resposta9.parecer_pergunta_id = 9;
    this.atualizarResposta(this.resposta9);
    this.resposta10.parecer_pergunta_id = 10;
    this.atualizarResposta(this.resposta10);
    this.resposta11.parecer_pergunta_id = 11;
    this.atualizarResposta(this.resposta11);
    this.resposta12.parecer_pergunta_id = 12;
    this.atualizarResposta(this.resposta12);
    this.resposta13.parecer_pergunta_id = 13;
    this.atualizarResposta(this.resposta13);
    this.resposta14.parecer_pergunta_id = 14;
    this.atualizarResposta(this.resposta14);
    this.resposta15.parecer_pergunta_id = 15;
    this.atualizarResposta(this.resposta15);
    this.resposta16.parecer_pergunta_id = 16;
    this.atualizarResposta(this.resposta16);
    this.resposta17.parecer_pergunta_id = 17;
    this.atualizarResposta(this.resposta17);
    this.resposta18.parecer_pergunta_id = 18;
    this.atualizarResposta(this.resposta18);
    this.atualizarParecerResponsavel("INICIADO");
  }

  enviarQuestionario() {
    this.salvarQuestionario();
    this.atualizarParecerResponsavel("ENVIADO");
    this.router.navigate(["secured/parecer-agradecimento"]);
  }

  async atualizarParecerResponsavel(status: string): Promise<any> {
    try {
      if (this.parecerResponsavel.id != null) {
        this.parecerResponsavel.status = status;
        if (status == "ENVIADO") {
          this.parecerResponsavel.data_envio = new Date();
        }
        let response = await this.parecerResponsavelService.atualizar(
          this.parecerResponsavel.id,
          this.parecerResponsavel
        );
        this.parecerResponsavel = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
}
