import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Injectable } from "@angular/core";
import { CacheService } from 'src/app/service/cache.service';


@Injectable()
export class ElementoGrupoDominioService extends GenericService<ElementoGrupoDominio> {
    
    constructor(protected http: HttpClient, protected router:Router, private cacheService: CacheService) {
        super(http,router);
        this.setEntityType('r/elementogrupodominio');
    }

    async buscarElementosGrupoDominio(busca: any) : Promise<any> {
        const cacheKey = 'buscarElementosGrupoDominio-' + JSON.stringify(busca);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP        
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    async buscarPorGrupoDominioNome(busca: any) : Promise<any> {
        const cacheKey = 'buscarPorGrupoDominioNome-' + JSON.stringify(busca);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP        
        return this.http.post<any>(this.apiURL+'/pornomegrupo', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    async getAllNotInAcessoUsuarioEstrutura(id: number): Promise<any> {
        const cacheKey = 'getAllNotInAcessoUsuarioEstrutura-' + JSON.stringify(id);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP        
        return this.http.get<any>(`${this.apiURL}/getallnotinacessousuarioestrutura/${id}`, { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    async getAllFromAcessoUsuarioEstrutura(id: number): Promise<any> {
        const cacheKey = 'getAllFromAcessoUsuarioEstrutura-' + JSON.stringify(id);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP        
        return this.http.get<any>(`${this.apiURL}/getallfromacessousuarioestrutura/${id}`, { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

}