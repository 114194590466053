import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
export class QuestionarioCALViewAnswerComponent {
    constructor(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFomandoCAService, caminhoFormativoService, mensagemService, respostaQuestionarioService, questionarioFormandoService, dadoFomandoCABuilder, caminhoFormativoBuilder, 
    // public mensagemBuilder: MensagemBuilder,
    respostaQuestionarioBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFomandoCAService = dadoFomandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.mensagemService = mensagemService;
        this.respostaQuestionarioService = respostaQuestionarioService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.dadoFomandoCABuilder = dadoFomandoCABuilder;
        this.caminhoFormativoBuilder = caminhoFormativoBuilder;
        this.respostaQuestionarioBuilder = respostaQuestionarioBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        // this.formasVida = new Array<ElementoGrupoDominio>();
        this.niveisFormacao = new Array();
        // this.estadosVida = new Array<ElementoGrupoDominio>();
        this.pessoaFormadorPessoalSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorComunitarioSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.dadoFormandoCA = this.dadoFomandoCABuilder.getInstance();
        this.caminhoFormativo = this.caminhoFormativoBuilder.getInstance();
        // this.mensagemRL = this.mensagemBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta39 = respostaQuestionarioBuilder.getInstance();
        this.resposta40 = respostaQuestionarioBuilder.getInstance();
        this.resposta41 = respostaQuestionarioBuilder.getInstance();
        this.resposta42 = respostaQuestionarioBuilder.getInstance();
        this.resposta43 = respostaQuestionarioBuilder.getInstance();
        this.resposta44 = respostaQuestionarioBuilder.getInstance();
        this.resposta45 = respostaQuestionarioBuilder.getInstance();
        this.resposta46 = respostaQuestionarioBuilder.getInstance();
        this.resposta47 = respostaQuestionarioBuilder.getInstance();
        this.resposta48 = respostaQuestionarioBuilder.getInstance();
        this.resposta49 = respostaQuestionarioBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.questionarioFormandoId = Number(this.route.snapshot.paramMap.get("id"));
            yield this.carregarQuestionarioFormando();
            // await this.getUsuarioAtual();
            // this.carregarFormasVida();
            this.carregarNiveisFormacao();
            // this.carregarEstadoVida();
            yield this.carregarMissoesVinculo();
            yield this.carregarPessoa();
            yield this.carregarDadoVocacional();
            yield this.carregarDadoFormandoCA();
            yield this.carregarCaminhoFormativo();
            yield this.carregarRespostas();
            // await this.carregarMensagemRLPorOrigem();
        });
    }
    // async getUsuarioAtual(): Promise<any> {
    //   try {
    //     let response = await this.usuarioService.getCurrentUser();
    //     this.usuarioAtual = response.entity;
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllPublic.getAllMissao();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorId(this.questionarioFormando.pessoaId);
                this.pessoa = response.entity;
                this.pessoaId = this.pessoa.id;
                if (this.pessoa.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoa);
                }
                // if (this.pessoa.formaVidaId != null) {
                //   this.setarFormaVidaSelecionada();
                // }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    // carregarFormasVida(): void {
    //   let buscaFormaVida = {
    //     grupoNome: "FORMA_VIDA",
    //   };
    //   this.elementoGrupoDominioService
    //     .buscarPorGrupoDominioNome(buscaFormaVida)
    //     .then((lista: any) => {
    //       this.formasVida = lista.entity;
    //     })
    //     .catch((err: any) => {
    //       this.swtAlert2Service.errorAlert(err.error.errors);
    //     });
    // }
    carregarNiveisFormacao() {
        let busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then((lista) => {
            this.niveisFormacao = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    // carregarEstadoVida(): void {
    //   let busca = {
    //     grupoNome: "ESTADO_VIDA",
    //   };
    //   this.elementoGrupoDominioService
    //     .buscarPorGrupoDominioNome(busca)
    //     .then((lista: any) => {
    //       this.estadosVida = lista.entity;
    //     })
    //     .catch((err: any) => {
    //       this.swtAlert2Service.errorAlert(err.error.errors);
    //     });
    // }
    logOnConsole(dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    }
    activateTab(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    }
    activateTab2(action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    }
    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }
    salvar(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.update(pessoa.id, pessoa);
                this.pessoa = response.entity;
                if (this.pessoa.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoa);
                }
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            this.atualizarDadoFormandoCA();
            this.atualizarCaminhoFormativo();
            this.atualizarQuestionarioFormando("INICIADO");
        });
    }
    salvarImagem() {
        this.imagemService
            .salvarCropped({ imageData: this.croppedImage })
            .then((response) => {
            this.swtAlert2Service.successAlert(response.message);
            this.pessoa.imagemId = response.entity.id;
            this.salvar(this.pessoa);
            $("#modal-pessoa-imagem").modal("hide");
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log("Imagem carregada");
    }
    cropperReady() {
        console.log("Imagem cortada");
    }
    loadImageFailed() {
        console.log("Carregamento da imagem falhou!");
    }
    // setarFormaVidaSelecionada(): void {
    //   for (let i = 0; i < this.formasVida.length; i++) {
    //     if (this.formasVida[i].id == this.pessoa.formaVidaId) {
    //       this.formaVidaSelecionada = this.formasVida[i];
    //     }
    //   }
    // }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.pessoaFormadorPessoalSelecionado =
                        this.pessoaInfoBuilder.getInstance();
                    this.pessoaFormadorPessoalSelecionado.nome =
                        this.dadoVocacional.formadorPessoalPessoaNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    selecionarFormadorPessoal(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            this.pessoaFormadorPessoalSelecionado = pessoa;
            $("#listagem-pessoa-formador-pessoal").modal("hide");
        });
    }
    selecionarFormadorComunitario(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            this.pessoaFormadorComunitarioSelecionado = pessoa;
            $("#listagem-pessoa-formador-comunitario").modal("hide");
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadoFormandoCA() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFomandoCAService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCA = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.caminhoFormativo = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.caminhoFormativo.missao_atual_id =
                    this.pessoa.vinculoMissao.missaoAtualId;
                this.caminhoFormativo.nome_formador_pessoal =
                    this.pessoaFormadorPessoalSelecionado.nome;
                if (this.caminhoFormativo.id == null) {
                    this.caminhoFormativo.pessoaId = this.pessoa.id;
                    let response = yield this.caminhoFormativoService.cadastrar(this.caminhoFormativo);
                    this.caminhoFormativo = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.caminhoFormativoService.atualizar(this.caminhoFormativo);
                    this.caminhoFormativo = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRespostas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.respostaQuestionarioService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.respostas = response.content;
                    this.respostas.forEach((resposta) => {
                        switch (resposta.questionario_pergunta_id) {
                            case 39:
                                this.resposta39 = resposta;
                                break;
                            case 40:
                                this.resposta40 = resposta;
                                break;
                            case 41:
                                this.resposta41 = resposta;
                                break;
                            case 42:
                                this.resposta42 = resposta;
                                break;
                            case 43:
                                this.resposta43 = resposta;
                                break;
                            case 44:
                                this.resposta44 = resposta;
                                break;
                            case 45:
                                this.resposta45 = resposta;
                                break;
                            case 46:
                                this.resposta46 = resposta;
                                break;
                            case 47:
                                this.resposta47 = resposta;
                                break;
                            case 48:
                                this.resposta48 = resposta;
                                break;
                            case 49:
                                this.resposta49 = resposta;
                                break;
                        }
                    });
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarioFormando() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorId(this.questionarioFormandoId);
                if (response != null && response != undefined) {
                    this.questionarioFormando = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMensagemAF() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFomandoCAService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCA = response;
                    /*this.carregarEstados(this.dadoFormandoCA.pais_origem_id);
                            this.carregarCidades(this.dadoFormandoCA.estado_origem_id);*/
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    // async carregarMensagemRLPorOrigem(): Promise<any> {
    //   try {
    //     let response = await this.mensagemService.mensagemRLPorOrigemId(
    //       this.questionarioFormando.id
    //     );
    //     if (response != null && response != undefined) {
    //       this.mensagemRL = response;
    //     }
    //   } catch (err) {
    //     this.swtAlert2Service.errorAlert(err.error.errors);
    //   }
    // }
    atualizarDadoFormandoCA() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.dadoFormandoCA.id == null) {
                    this.dadoFormandoCA.pessoaId = this.pessoa.id;
                    let response = yield this.dadoFomandoCAService.cadastrar(this.dadoFormandoCA);
                    this.dadoFormandoCA = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.dadoFomandoCAService.atualizar(this.dadoFormandoCA);
                    this.dadoFormandoCA = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarResposta(resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                resposta.pessoaId = this.pessoa.id;
                if (resposta.id == null) {
                    let response = yield this.respostaQuestionarioService.cadastrar(resposta);
                    this.swtAlert2Service.successAlert(response.message);
                    return response;
                }
                else {
                    let response = yield this.respostaQuestionarioService.atualizar(resposta.id, resposta);
                    this.swtAlert2Service.successAlert(response.message);
                    return response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    // async atualizarMensagem(): Promise<any> {
    //   try {
    //     if (this.mensagemRL.id == null) {
    //       this.mensagemRL.origem = 1; //1 - Questionario Formando
    //       this.mensagemRL.origem_id = this.questionarioFormandoId;
    //       this.mensagemRL.lida = 0;
    //       let response = await this.mensagemService.cadastrarMensagemRL(
    //         this.mensagemRL
    //       );
    //       this.mensagemRL = response;
    //       this.swtAlert2Service.successAlert(response.message);
    //     } else {
    //       let response = await this.mensagemService.atualizarMensagemRL(
    //         this.mensagemRL
    //       );
    //       this.mensagemRL = response;
    //       this.swtAlert2Service.successAlert(response.message);
    //     }
    //   } catch (err) {
    //     this.swtAlert2Service.errorAlert(err.error.errors);
    //   }
    // }
    // async salvarQuestionario(): Promise<any> {
    //   if (!this.respostaVazia(this.resposta39)) {
    //     this.resposta39.questionario_pergunta_id = 39;
    //     this.resposta39 = await this.atualizarResposta(this.resposta39);
    //   }
    //   if (!this.respostaVazia(this.resposta40)) {
    //     this.resposta40.questionario_pergunta_id = 40;
    //     this.resposta40 = await this.atualizarResposta(this.resposta40);
    //   }
    //   if (!this.respostaVazia(this.resposta41)) {
    //     this.resposta41.questionario_pergunta_id = 41;
    //     this.resposta41 = await this.atualizarResposta(this.resposta41);
    //   }
    //   if (!this.respostaVazia(this.resposta42)) {
    //     this.resposta42.questionario_pergunta_id = 42;
    //     this.resposta42 = await this.atualizarResposta(this.resposta42);
    //   }
    //   if (!this.respostaVazia(this.resposta43)) {
    //     this.resposta43.questionario_pergunta_id = 43;
    //     this.resposta43 = await this.atualizarResposta(this.resposta43);
    //   }
    //   if (!this.respostaVazia(this.resposta44)) {
    //     this.resposta44.questionario_pergunta_id = 44;
    //     this.resposta44 = await this.atualizarResposta(this.resposta44);
    //   }
    //   if (!this.respostaVazia(this.resposta45)) {
    //     this.resposta45.questionario_pergunta_id = 45;
    //     this.resposta45 = await this.atualizarResposta(this.resposta45);
    //   }
    //   if (!this.respostaVazia(this.resposta46)) {
    //     this.resposta46.questionario_pergunta_id = 46;
    //     this.resposta46 = await this.atualizarResposta(this.resposta46);
    //   }
    //   if (!this.respostaVazia(this.resposta47)) {
    //     this.resposta47.questionario_pergunta_id = 47;
    //     this.resposta47 = await this.atualizarResposta(this.resposta47);
    //   }
    //   if (!this.respostaVazia(this.resposta48)) {
    //     this.resposta48.questionario_pergunta_id = 48;
    //     this.resposta48 = await this.atualizarResposta(this.resposta48);
    //   }
    //   if (!this.respostaVazia(this.resposta49)) {
    //     this.resposta49.questionario_pergunta_id = 49;
    //     this.resposta49 = await this.atualizarResposta(this.resposta49);
    //   }
    //   // if (this.mensagemRL.mensagem != null) this.atualizarMensagem();
    //   // let status = this.definirStatusQuestionario();
    //   this.atualizarQuestionarioFormando("INICIADO");
    // }
    definirStatusQuestionario() {
        let status = "INICIADO";
        let todoRespondido = false;
        if (!this.respostaVazia(this.resposta39) &&
            !this.respostaVazia(this.resposta41) &&
            !this.respostaVazia(this.resposta40) &&
            !this.respostaVazia(this.resposta42) &&
            !this.respostaVazia(this.resposta43) &&
            !this.respostaVazia(this.resposta44) &&
            !this.respostaVazia(this.resposta45) &&
            !this.respostaVazia(this.resposta46) &&
            !this.respostaVazia(this.resposta47) &&
            !this.respostaVazia(this.resposta48) &&
            !this.respostaVazia(this.resposta49)) {
            todoRespondido = true;
        }
        if (todoRespondido) {
            status = "ENVIADO";
        }
        return status;
    }
    respostaVazia(resposta) {
        return (resposta.resposta == null ||
            resposta.resposta == undefined ||
            resposta.resposta == "");
    }
    // async enviarQuestionario(): Promise<any> {
    //   await this.salvarQuestionario();
    //   await this.atualizarQuestionarioFormando("ENVIADO");
    //   this.router.navigate(["secured/questionario-agradecimento"]);
    // }
    atualizarQuestionarioFormando(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.questionarioFormando.id != null) {
                    this.questionarioFormando.status = status;
                    if (status == "ENVIADO") {
                        this.questionarioFormando.data_envio = new Date();
                    }
                    let response = yield this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando);
                    this.questionarioFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
