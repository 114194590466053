import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';

@NgModule({
    declarations: [ 
        HeaderComponent 
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule {

}