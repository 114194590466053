import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ParecerComponent } from "./parecer.component";
import { ParecerD2FCAnswerComponent } from "./formador_comunitario/parecerd2-fc-answer.component";
import { ParecerAgradecimentoComponent } from "./formador_comunitario/parecer-agradecimento.component";
import { ParecerCelulaComponent } from "./parecer.celula.component";
import { ParecerCelulaAutoridadeComponent } from "./parecer.celula.autoridade.component";
import { ParecerCALFCAnswerComponent } from "./formador_comunitario/parecer-cal-fc-answer.component";
import { ParecerCoordenadorColegiadoCelulaComponent } from "./coordenador_colegiado/parecer.cc.celula.component";
import { ParecerCoordenadorColegiadoViewComponent } from "./coordenador_colegiado/parecer-cc-view.component";
import { ParecerCALFCAnswerDefaultComponent } from "./formador_comunitario/parecer-cal-fc-answer-default.component";
import { ParecerCALAnswerDefaultViewComponent } from "./parecer-cal-answer-default-view.component";
import { ParecerCelulaComissaoComponent } from "./parecer.celula.comissao.component";

const pessoaRoutes: Routes = [
  {
    path: "secured/parecerd2-answer/:id",
    component: ParecerD2FCAnswerComponent,
  },
  {
    path: "secured/parecer-cal-answer/:id",
    component: ParecerCALFCAnswerComponent,
  },
  {
    path: "secured/parecer-cal-answer-default/:id",
    component: ParecerCALFCAnswerDefaultComponent,
  },
  {
    path: "secured/parecer-cal-answer-default-view/:id",
    component: ParecerCALAnswerDefaultViewComponent,
  },
  {
    path: "secured/parecer-cc-view/:id",
    component: ParecerCoordenadorColegiadoViewComponent,
  },
  {
    path: "secured/parecer",
    component: ParecerComponent,
  },
  {
    path: "secured/celula/discernimentos",
    component: ParecerCelulaComponent,
  },
  {
    path: "secured/celula/cc/discernimentos",
    component: ParecerCoordenadorColegiadoCelulaComponent,
  },
  {
    path: "secured/parecer-agradecimento",
    component: ParecerAgradecimentoComponent,
  },
  {
    path: "secured/celula/discernimento/:id",
    component: ParecerCelulaAutoridadeComponent,
  },
  {
    path: "secured/celula/cd/discernimento/:id",
    component: ParecerCelulaComissaoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(pessoaRoutes)],
  exports: [RouterModule],
})
export class ParecerRoutingModule {}
