import { Injectable } from "@angular/core";
import { Relacionamento } from "../model/relacionamento.model";
import { AbstractBuilder } from "./abstract.builder";

@Injectable()
export class RelacionamentoBuilder extends AbstractBuilder<Relacionamento> {
  reset(): void {
    this.entity = new Relacionamento(
      null,
      null,
      null,
      null,
      null,
      true,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
}
