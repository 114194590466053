import { GenericService } from "src/app/core/services/generic.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ParecerResponsavel } from "src/app/model/parecerresponsavel.model";

@Injectable()
export class ParecerResponsavelService extends GenericService<ParecerResponsavel> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("parecerresponsavel");
  }

  buscarPorPessoaId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_responsavel/porpessoaid/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorQuestionarioFormandoId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_responsavel/porquestionarioid/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_responsavel/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  cadastrar(request: any) {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_responsavel`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizar(id: number, request: any) {
    return this.http
      .put<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_responsavel/${id}`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }
}
