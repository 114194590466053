import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
export class QuestionarioComponent {
    constructor(usuarioService, pessoaService, 
    //public questionarioBuilder: QuestionarioBuilder,
    questionarioService, route, router, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.questionarioService = questionarioService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        //    this.questionarios = new Array<any>();
        //    this.questionario = this.questionarioBuilder.getInstance();
    }
    ngOnInit() {
        const url = this.route.snapshot.url.map(segment => segment.path).join('/');
        // Verifica se o caminho contém celula ou casacomunitaria
        if (url.includes('celula')) {
            this.origem = 'celula';
        }
        else if (url.includes('casacomunitaria')) {
            this.origem = 'casacomunitaria';
        }
        this.initializer();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            yield this.buscarQuestionarios();
        });
    }
    buscarQuestionarios() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioService.buscarAbertos();
                this.questionarios = response.content;
                this.totalDePaginas = response.totalPages;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    /*  carregarQuestionarioConfiguracao(questionarioId: number) {
        const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
          .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
            missaoId,
            questionarioId
          )
          .subscribe(
            (questionarioConfiguracao) =>
              (this.questionarioConfiguracao = questionarioConfiguracao)
          );
      }
    */
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
}
