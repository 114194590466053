import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { FormadorComunitario } from 'src/app/model/formadorcomunitario.model';
import { environment } from 'src/environments/environment';
import { CacheService } from 'src/app/service/cache.service';


@Injectable()
export class FormadorComunitarioService extends GenericService<FormadorComunitario> {
    
    constructor(protected http: HttpClient, protected router:Router, private cacheService: CacheService) {
        super(http,router);
        this.setEntityType('formadorcomunitario');
    }

    buscarPorCelula(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/porcelula', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarPorPessoaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porpessoa/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarCelulaPorPessoaId(id:number):Promise<any>{
        const cacheKey = 'buscarCelulaPorPessoaId-' + JSON.stringify(id);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}api/secured/formadorcomunitario/celula/porpessoaid/${id}`, { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });        
    }

    buscarCasaComunitariaPorPessoaId(id:number):Promise<any>{
        const cacheKey = 'buscarCasaComunitariaPorPessoaId-' + JSON.stringify(id);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}api/secured/formadorcomunitario/casacomunitaria/porpessoaid/${id}`, { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });        
    }

    buscarCelulaPorId(id:number):Promise<any>{
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}api/secured/formadorcomunitario/celula/porid/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }    
}